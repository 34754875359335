export function isAdminRoute(path: string) {
  return path.includes('/admin')
}

export function getFormattedLatestPathSegment(path: string) {
  const pathSegments = path.split('/')
  const latestPathSegment = pathSegments.pop().split('?')[0]
  const formattedPath = latestPathSegment
    .replace(/-/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase())

  return formattedPath
}

export function replacePathParams(path: string, params: Record<string, string>) {
  return Object.entries(params).reduce(
    (newPath, [key, value]) => newPath.replace(`[${key}]`, value),
    path,
  )
}
