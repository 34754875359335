import ReactDOM from 'react-dom'

import { ToastContainer } from 'react-toastify'

function ToastPortal() {
  return ReactDOM.createPortal(
    <ToastContainer hideProgressBar theme="colored" limit={3} newestOnTop />,
    document.getElementById('toast-portal'),
  )
}

export default ToastPortal
