/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NextRouter } from 'next/router'
import { Dispatch, RefObject, SetStateAction } from 'react'
import { MutateOptions } from 'react-query'
import { StaticImageData } from 'next/image'
import { Quest } from '@type/schemas/quest'
import { MajorArea } from '@type/major-area'

export type QuestPhaseWithProgressType = QuestProgressType & QuestPhaseType
export enum QuestPhaseEnum {
  INTRO_PHASE = 'Intro_Phase',
  OVERVIEW_PHASE = 'Overview_Phase',
  EXPLORE_PHASE = 'Explore_Phase',
  FRIEND_PHASE = 'Friend_Phase',
  TRIAL_INTRO_ARGUEMENT_PHASE = 'Trial_Intro_Arguments_Phase',
  TRIAL_JURY_DECIDES_PHASE = 'Trial_Jury_Decides_Phase',
  TRAIL_TESTIMONY_PHASE = 'Trial_Testimony_Phase',
  CONGRATULATIONS = 'Congratulation_Phase',
  CREATE_LAWS_PHASE = 'Create_Laws_Phase',
  BUILD_VALUES_ENGINE_PHASE = 'Build_Values_Engine_Phase',
  LEARN_BIGGER_GAME_PHASE = 'Learn_Bigger_Game_Phase',
  GO_TO_BIGGER_GAME_PHASE = 'Go_To_Bigger_Game_Phase',
}

export enum GameMenuState {
  QUEST = 'QUEST',
  HOME = 'HOME',
  BUILDING_TOUR = 'BUILDING_TOUR',
  HUB = 'HUB',
  RANCH = 'RANCH',
  CONSTRUCTION = 'CONSTRUCTION',
  UNDERGROUND_GROUP = 'UNDERGROUND_GROUP',
  VALUE_ENGINE = 'VALUE_ENGINE',
  SCENE_PLAY = 'SCENE_PLAY',
}

export type CurrentMajorAreaQuests = {
  title: string
  id: string
  key: number
  path: string
}

export type ActionKeys = {
  name: string
  key: string
  isVideo?: boolean
  isLocked?: boolean
}

export type GameMenuButtonType = {
  label: string
  screen: GameMenuState
  key: string
  backgroundImage?: StaticImageData
}

export type TransitionState = {
  videoUrl: string
}

export enum GameState {
  NONE = 'NONE',
  BUILDING_TOUR_PLAYING = 'BUILDING_TOUR_PLAYING',
  HUB_ADVISOR_PLAYING = 'HUB_ADVISOR_PLAYING',
  RANCH_PLAYING = 'RANCH_PLAYING',
  VALUES_FACTORY = 'VALUE_FACTORY',
  SETUP_VALUE_ENGINE = 'SETUP_VALUE_ENGINE',
  UPGRADE_VALUE_ENGINE = 'UPGRADE_VALUE_ENGINE',
  UNDERGROUND_GROUP = 'UNDERGROUND_GROUP',
  EXPLORE_RANCH = 'EXPLORE_RANCH',
  QUEST_LIST = 'QUEST_LIST',
  QUEST_LANDING = 'QUEST_LANDING',
  QUEST_PHASE = 'QUEST_PHASE',
  QUEST_CHILD_PHASE = 'QUEST_CHILD_PHASE',
  QUEST_PLAYER_OPTION = 'QUEST_PLAYER_OPTION',
  MAJOR_AREA_INTRO = 'MAJOR_AREA_INTRO',
}

export enum GameHotKeys {
  W = 'W',
  E = 'E',
  R = 'R',
  T = 'T',
  U = 'U',
  I = 'I',
  O = 'O',
  A = 'A',
  D = 'D',
  F = 'F',
  G = 'G',
  Space = ' ',
}

export enum GameHotKeysCode {
  Space = 32,
}

export enum GameModeHotKey {
  READING = 'R',
  AUTO_DAILOGUE = 'A',
}

export enum TransitionVideosType {
  NONE = 'NONE',
  INTRO_VIDEO = 'Intro Video',
  HUB_TO_QUEST = 'Hub To Quest',
  QUEST_TO_HUB = 'Quest To Hub',
  SOLVE_QUEST = 'Solve Quest',
  SETUP_VALUE_ENGINE = 'Setup Value Engine',
  HUB_TO_RANCH = 'Hub To Ranch',
  RANCH_TO_HUB = 'Ranch To Hub',
  EXPLORE_RANCH = 'Explore Ranch',
  VALUES_FACTORY = 'Values Factory',
  QUEST_TO_VALUE_ENGINE = 'Quest to Value Engine',
  RANCH_TO_QUEST = 'Ranch To Quest',
  ACTIVATE_VALUE_FACTORY = 'Activate Value Factory',
  BUILDING_CONSTRUCTION = 'Building Construction',
  HUB_TO_UNDERGROUND = 'Hub to Underground',
  EXPLORE_UNDERGROUNDS = 'Explore Undergrounds',
  HOME = 'Home',
  HUB = 'Hub',
}

export enum PhaseTypeEnum {
  PHASE = 'phase',
  GENERIC = 'generic',
  MAJOR_AREA_INTRO = 'major_area_intro',
}

export enum ButtonTypeEnum {
  NEXT = 'Next',
  BACK = 'Back',
}

export type IntroPhaseType = {
  CharacterLabel: string
  Discussions: DiscussionType[]
  YAML_TYPE: string
  Person?: string
}

export type QuestPhaseType = {
  Name: string
  Child_Phases?: QuestPhaseType[]
  type: PhaseTypeEnum
}

export type QuestProgressType = {
  total: number
  progress: number
}

export type MainQuestType = {
  Area: string
  Title: string
  Background_URL: string
  Starting_Phase: string
  Defaults: string[]
  Name: string
  Phases: QuestPhaseType[]
  Clues?: ClueType[]
}

export type PhaseType = {
  Title: string
  YAML_Type: string
  CharacterLabel: string
  Quest: string
  Background_URL: string
  Discussions: DiscussionType[]
  Commands?: CommandType[]
  Person: string
}

export type ChildPhaseType = {
  Discussions: DiscussionType[]
  YAML_Type: string
  Story_Type: string
  Character_URL: string
  CharacterLabel: string
  Background_URL: string
  Name: string
  Person: string
  Place: string
  Wrap_Up: any[]
  Title: string
  Commands?: CommandType[]
  Question?: QuestionType[]
}

export type QuestionType = {
  title: string
  name: string
  goToDiscussion: number
  dependentOn: string[]
  completed?: boolean
}

export type DiscussionType = {
  Background_URL: string
  Scripts: ScriptType[]
  Commands?: CommandType[]
  Clue?: DiscussionClueType
  Media_Image_URL?: string
  Prompt?: ScriptPromptType[]
}

export type DiscussionClueType = {
  Input: string[]
  Output: string
  InputDetail?: ClueType[]
  Scripts?: ScriptType[]
}
export type ScriptPromptType = {
  question?: string
  answer?: string
  responseMessage?: {
    correct: string
    incorrect: string
  }
}

export type ScriptType = {
  audio: string
  video: string
  subtitle: string
  highlight: boolean
}

export type CommandType = {
  Type: string
  Options: CommandOption[]
}

export type CommandOption = {
  key: string
  value: string
}

export type ClueType = {
  name: string
  value: string
  actor: string
  isLocked?: boolean
}

export type QuestProgressUpdateData = {
  questId: string
  clues?: string[]
  phase: QuestPlayerProgressPhase
  status?: QuestPlayerProgressStatus
  currentPhase?: string
  active: boolean
}

export type UserQuestProgressType = {
  createdAt: string
  progressInfo: {
    clues: string[]
    currentPhase: string
    phases: QuestPlayerProgressPhase[]
  }
  questId: string
  status: QuestPlayerProgressStatus
  updatedAt: string
  overviewPlayed: boolean
  learnBiggerGamePlayed: boolean
}

export type QuestPlayerProgressPhase = {
  name: string
  type: string
  currentDiscussionNumber?: number
  currentScriptNumber?: number
  status: QuestPlayerProgressStatus
  phaseTypeSpecificData?: PhaseTypeSpecificData
}

type PhaseTypeSpecificData = {
  opinions?: Opinion[]
  questions?: string[] // name of the question that is already asked
}

export type Opinion = {
  discussionNumber: number
  key: string
}

export enum QuestPlayerProgressStatus {
  IN_PROGRESS = 0,
  COMPLETED = 1,
}

export enum GameScreenDesignLayoutType {
  CENTER_TWO_COLUMN_WITH_NO_CHARACTER = 'CenterTwoColumnsWithNoCharacter',
  CENTER_TWO_COLUMN_WITH_CHARACTER = 'CenterTwoColumnsWithCharacter',
  BOTTOM_CORNER_TWO_COLUMN_PERSISTENT = 'BottomCornerTwoColumnPersistent',
  BOTTOM_ONE_ROW_WITH_CHARACTER = 'BottomOneRowWithCharacter',
  BOTTOM_ONE_ROW_WITH_NO_CHARACTER = 'BottomOneRowWithNoCharacter',
}
export enum ContentType {
  UNDERGROUND = 'Undergrounds',
  MAJOR_AREA = 'Major Area',
  AREA = 'Areas',
  GEM_PARK = 'Gem Park',
  SURFING_Next_C = 'Surfing (Travel City) (Company Authored)',
  SURFING_Next_U = 'Surfing (Travel City) (User Authored)',
  MAP_LOCATION = 'Map Locations',
  CHALLENGE_GAME = 'Dream Run (Company Authorized)',
  VALUE_FACTORY = 'Value Factory',
  PHILOSOPHICPACK = 'Philosophy Pack',
  NORTH_CORNER = 'North Corner',
  EAST_CORNER = 'East Corner',
  WEST_CORNER = 'West Corner',
  SOUTH_CORNER = 'South Corner',
  THE_UNDERGROUND = 'The Underground',
}

export enum ContentName {
  THE_UNDERGROUND = 'The Underground',
  HUB = 'Hub',
}
export const cornerContentTypes = [
  ContentType.NORTH_CORNER.toString(),
  ContentType.SOUTH_CORNER.toString(),
  ContentType.EAST_CORNER.toString(),
  ContentType.WEST_CORNER.toString(),
]

export type ScriptImageParam = {
  index: number
  path: string
  file: File
}

export enum GameModeType {
  READING = 'Reading',
  AUDIO_DIALOGUE = 'Audio Dialogue',
}

export type DataDetails = {
  page: number
  count: number
  limit: number
}
export enum QuestScriptType {
  PROMPT = 'Prompt',
}

export enum QuestPlayLanding {
  SCRIPT_NUMBER = 0,
  COMPLETED = 1,
}

export enum PaginationChildPhase {
  ITEM_PER_PAGE = 4,
}
export enum PaginationActionControlSceneControlList {
  ITEM_PER_PAGE = 8,
  ITEM_PER_PAGE_PhilosophyCornetPack = 5,
}

export enum Rotation {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum ScenesPriority {
  FIRST = 1,
}
export enum SortDialoguePriority {
  FIRST = 0,
}
export enum PageType {
  TRICODER = 'TRICODER',
  SURFING = 'SURFING',
  PHILOSOPHYPACK = 'PHILOSOPHYPACK',
  PHILOSOPHYPACKTRICORDER = 'PHILOSOPHYPACKTRICORDER',
}
export enum RedirectTo {
  HUB = 'Hub',
  GEMPARK = 'Gem Park',
  THEUNDERGROUND = 'The Underground',
}

export enum StaticMessages {
  NO_CLUES_COLLECTED = "You haven't collected clues yet. Please collect the clues first.",
}
// quest phase function types

export type TestimonyNextScriptClickType = {
  isLoading: boolean
  isVideoLoaded: boolean
  videoRef: RefObject<HTMLVideoElement>
  userGameProgress: UserQuestProgressType
  currentChildPhase: string
  questPhase: string
  currentScript: DiscussionType
  currentScriptIndex: number
  questions: QuestionType[]
  showQuestions: boolean
  currentQuestion: QuestionType
  phases: QuestPhaseType[]
  setCurrentChildPhase: Dispatch<SetStateAction<string>>
  updateTestimonyProgress: (question: QuestionType) => void
  checkIfChildPhaseIncomplete: (
    gameUserProgress: UserQuestProgressType,
    phases: QuestPhaseType[],
    questPhase: string,
  ) => boolean
  setCurrentScript: (value: SetStateAction<DiscussionType>) => void
  setCurrentScriptIndex: (value: SetStateAction<number>) => void
  setShowQuestions: (value: SetStateAction<boolean>) => void
  onNextScript: () => Promise<void>
}

export type onPlayAnotherPressedType = {
  setQuestPhase: (value: SetStateAction<string>) => void
  setCurrentQuest: (value: SetStateAction<Quest>) => void
  setCurrentBackground: (value: SetStateAction<string>) => void
  setCurrentGameState: (value: SetStateAction<GameState>) => void
  majorAreas: MajorArea[]
  currentMajorAreaId: string
  router: NextRouter
}

export type OnReplayPressedType = {
  currentQuest: Quest
  currentChildPhase: string
  questPhase: string
  discussions: DiscussionType[]
  discussionIndex: number
  updateQuestProgress: (
    variables: QuestProgressUpdateData,
    options?: MutateOptions<UserQuestProgressType, unknown, QuestProgressUpdateData, unknown>,
  ) => void
  setUserGameProgress: (value: SetStateAction<UserQuestProgressType>) => void
  setCurrentGameState: (value: SetStateAction<GameState>) => void
}

export type InitializeNewQuestType = {
  data: PhaseType | ChildPhaseType
  setDiscussions: (value: SetStateAction<DiscussionType[]>) => void
  setPersonName: (value: SetStateAction<string>) => void
  setCurrentBackground: (value: SetStateAction<string>) => void
  setCommands: (value: SetStateAction<CommandType[]>) => void
  setCurrentScript: (value: SetStateAction<DiscussionType>) => void
}
export type InitializeQuestLandingPlayType = {
  data: PhaseType | ChildPhaseType
  setDiscussions: (value: SetStateAction<DiscussionType[]>) => void
  setPersonName: (value: SetStateAction<string>) => void
  setCurrentScript: (value: SetStateAction<DiscussionType>) => void
}

export type UpdateProgressType = {
  checkIfCurrentPhaseCompleted: (currentPh: string, childPhase?: string) => boolean
  discussions: DiscussionType[]
  questPhase: string
  currentChildPhase: string
  currentQuest: Quest
  discussionIndex: number
  updateQuestProgress: (
    variables: QuestProgressUpdateData,
    options?: MutateOptions<UserQuestProgressType, unknown, QuestProgressUpdateData, unknown>,
  ) => void
  setUserGameProgress: (value: SetStateAction<UserQuestProgressType>) => void
  childPhases: QuestPhaseWithProgressType[]
  updateChildPhasesProgress: (name: string, progress: number) => void
  onNextDiscussion: (gameUserProgress: UserQuestProgressType) => void
  userGameProgress: UserQuestProgressType
  createLawPhaseNext: (playerProg: UserQuestProgressType) => boolean
  phases: QuestPhaseType[]
  checkIfCurrentChildPhaseCompleted: (
    currPh: string,
    currChildPhase: string,
    gameUserProgress: UserQuestProgressType,
    phases: QuestPhaseType[],
  ) => boolean
}

export type NextChildDiscussionType = {
  discussion: ChildPhaseType
  setDiscussionIndex: (value: SetStateAction<number>) => void
  setCurrentScript: (value: SetStateAction<DiscussionType>) => void
  setDiscussions: (value: SetStateAction<DiscussionType[]>) => void
  userGameProgress: UserQuestProgressType
  phases: QuestPhaseType[]
  questPhase: string
  setCurrentScriptIndex: (value: SetStateAction<number>) => void
  currentChildPhase: string
  setShowQuestions: (value: SetStateAction<boolean>) => void
}

export type MajorAreaProgress = { userId: string; ids: string[] }

export enum ComponentToShow {
  CHALLENGE_GAME_POPUP_LIST = 'ChallengGamePopUpList',
}

export enum TicketType {
  CONTACT_US = 'Contact Us',
  SUPPORT_REQUEST = 'Support Request',
}
