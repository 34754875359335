import { AWS_KEY_ID, AWS_SECRET_KEY } from '@constant'
import AWS from 'aws-sdk'
import { createContext, useEffect, useMemo, useState } from 'react'
import { TypeAWSContext } from 'types/aws'

type Props = {
  children: React.ReactNode
}

const AWSContext = createContext<TypeAWSContext>(null)

const AWSProvider = ({ children }: Props) => {
  const [s3, setAWSInstance] = useState<AWS.S3>()
  useEffect(() => {
    AWS.config.update({
      accessKeyId: AWS_KEY_ID,
      secretAccessKey: AWS_SECRET_KEY,
    })
    setAWSInstance(new AWS.S3())
  }, [])

  const contextValues: TypeAWSContext = useMemo(
    () => ({
      s3,
    }),
    [s3],
  )

  return <AWSContext.Provider value={contextValues}>{children}</AWSContext.Provider>
}

export { AWSContext, AWSProvider }
