export const earth9Config = {
  name: 'EARTH9',
  logo: 'https://d37tchdccajyhz.cloudfront.net/earth9.svg',
  gaCode: process.env.NEXT_PUBLIC_GTM_CODE,
  privacyPolicy: 'https://earth9.net/privacy-policy/',
  marketingVideoId: 'KKTU6zDYrBw',
  marketingIntroVideoId: process.env.NEXT_PUBLIC_MARKETING_INTRO_VIDEO_ID,
  favicon: {
    32: 'E9-favicon.png',
    16: 'E9-favicon.png',
    apple: 'E9-favicon.png',
  },
  theme: {
    primary: {
      main: '#3394ff',
      dark: '#2b78ce',
    },
    buttonGradients: {
      gradientStart: '#4AA0FF',
      gradientEnd: '#025CC0',
    },
  },
}
