import React, { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { useRouter } from 'next/router'
import cloneDeep from 'lodash/cloneDeep'
import Head from 'next/head'
import Drawer from '@commons/modules/Drawer'
import { MenuItem } from '@commons/modules/Drawer/types/types'
import { AUTH_ROUTES, PROTECTED_ROUTES, RoleType } from '@constant'
import useLogout from '@hook/user/useLogout'
import useProtectedRoute from '@hook/common/useProtectedRoute'
import { PROJECT, Project } from 'configs'
import ROUTES from '@constant/Routes'
import useCurrentUser from '@hook/user/useCurrentUser'
import theme from '@theme'
import { getFormattedLatestPathSegment, isAdminRoute } from '@util/route'
import useIdValueMap from '@hook/useIdValueMap'

type Props = {
  children: React.ReactNode
  menuItems: MenuItem[]
  userMenuItems: MenuItem[]
}

const NO_SIDE_BAR = [ROUTES.Home, ROUTES.PlayCenter, ROUTES.MarketingHome]

const BoxSx = {
  bgcolor: 'background.page',
  width: '100%',
  minHeight: '100vh',
  p: 2.5,
  [theme.breakpoints.down('sm')]: {
    mt: '15%',
  },
  overflowX: 'hidden',
}

const menuFilter = (base: MenuItem[], filterFunc: (entry: MenuItem) => boolean): MenuItem[] => {
  if (!base) return []

  base = base.filter(filterFunc)
  base.forEach((item) => {
    if (item.subItems) {
      item.subItems = menuFilter(item.subItems, filterFunc)
    }
  })

  return base
}

const DefaultLayout = ({ children, menuItems, userMenuItems }: Props) => {
  const [isPageInEditMode, setIsPageInEditMode] = useState(false)
  const { logoutUser } = useLogout()
  const router = useRouter()
  const { isProtectedRoute, currentUser, showPayment } = useProtectedRoute()
  const { sessionUser, isLoading, refetch } = useCurrentUser()
  const { idValueMap } = useIdValueMap()

  const currentUserHasOnlyUserRole = sessionUser?.hasOnlyUserRole
  // Deep copy menuItems
  const menuItemsCopy = cloneDeep(menuItems)
  const assemblyTitle = useMemo(() => {
    const { id } = router.query
    if (id) {
      return idValueMap[id as string]
    }

    return getFormattedLatestPathSegment(router.pathname)
  }, [router.pathname, idValueMap, router.query])

  useEffect(() => {
    if (!showPayment || isLoading) return () => {}

    const handleWindowFocus = () => {
      if (!sessionUser?.roles?.includes(RoleType.CREATOR)) refetch()
    }
    window.addEventListener('focus', handleWindowFocus)

    return () => {
      window.removeEventListener('focus', handleWindowFocus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, showPayment])

  const filteredMenuItems = useMemo(() => {
    // Hide Quests and Buildings menu for USER only role
    let menuItemsResult = menuItemsCopy
    if (currentUserHasOnlyUserRole) {
      menuItemsResult = menuItemsCopy?.map((menuItem) => {
        if (menuItem?.path === ROUTES.StoryEngine) {
          return {
            ...menuItem,
            subItems: menuItem?.subItems?.filter(
              (subItem) =>
                !(subItem?.path === ROUTES.AdminAiQuest || subItem?.path === ROUTES.BuildingsPlay),
            ),
          }
        }
        return menuItem
      })
    }

    // Hide menuItems for admin that are intended for User Role only
    menuItemsResult = menuFilter(
      menuItemsResult,
      (menuItem) => !sessionUser?.isAdmin || !menuItem?.showOnlyUserRole,
    )

    // Hide menuItems for users that are intended for Creators
    menuItemsResult = menuFilter(
      menuItemsResult,
      (menuItem) => sessionUser?.hasCreatorAccess || !menuItem?.isCreatorOnly,
    )

    return menuItemsResult
  }, [currentUserHasOnlyUserRole, menuItemsCopy, sessionUser])

  useEffect(() => {
    setIsPageInEditMode(router.pathname.includes('/edit'))
  }, [router.pathname])

  if (NO_SIDE_BAR.includes(router.pathname) && PROJECT?.mainDomain === Project.END_THE_RIG) {
    return <>{children}</>
  }

  if (AUTH_ROUTES.includes(router.pathname)) {
    return <>{children}</>
  }

  return (
    <>
      {isAdminRoute(router.pathname) && assemblyTitle !== 'Admin' && (
        <Head>
          <title>
            {assemblyTitle} {isPageInEditMode ? '- Edit' : ''} - Admin - End the Rig
          </title>
        </Head>
      )}
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Drawer
          userMenuItems={userMenuItems}
          menuItems={filteredMenuItems}
          currentUser={currentUser}
          logoutUser={logoutUser}
          protectedRoutes={PROTECTED_ROUTES}
          router={router}
        />
        <Box className="page-container" component="main" sx={BoxSx}>
          {isProtectedRoute && !currentUser ? null : children}
        </Box>
      </Box>
    </>
  )
}
export default DefaultLayout
