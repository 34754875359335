export enum HeroSubscriptionStatus {
  Delete = 'delete',
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
  Inactive = 'inactive',
  NoSub = 'no-sub',
}

export enum StripePaymentMode {
  PAYMENT = 'payment',
  SUBSCRIPTION = 'subscription',
}

export interface Plan {
  id: string
  name: string
  label: string
  description: string
  mode: StripePaymentMode
  frequency?: string
  priceAmount: string
  priceId: string
  order: number
  imageUrl?: string
  active: boolean
  createdAt: string
  paid: boolean
  status: HeroSubscriptionStatus
  subscriptionId: null | string
}

export enum WebPackPlan {
  COINS = 'Coins',
  RANCH_IN_GAME = 'RANCH IN GAME',
  HOME_IN_GAME = 'HOME IN GAME',
}
