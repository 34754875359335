import { useState, createContext, useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'
import { GamePlayState } from '@template/GameDashboard/context/GameContext'
import useCurrentUser from '@hook/user/useCurrentUser'
import ROUTES from '@constant/Routes'

export enum DialogState {
  LOADING = 'LOADING',
  SHOW_DOWNLOAD = 'SHOW_DOWNLOAD',
}
const GamePlayContext = createContext(null)
function GamePlayProvider({ children }) {
  const { currentUser } = useCurrentUser()
  const router = useRouter()
  const [gamePlayState, setGamePlayState] = useState<GamePlayState>(null)
  const [state, setState] = useState<DialogState>(DialogState.LOADING)

  const openUnrealGame = useCallback(
    (playState: GamePlayState) => {
      if (!currentUser) {
        const hasVisited = localStorage.getItem('has-visited')
        const redirectUrl = hasVisited ? ROUTES.SignIn : ROUTES.SignUp
        router.push(redirectUrl)
        return
      }
      setState(DialogState.LOADING)
      setGamePlayState(playState)
    },
    [setGamePlayState, setState, currentUser, router],
  )

  const value = useMemo(
    () => ({ gamePlayState, setGamePlayState, state, setState, openUnrealGame }),
    [gamePlayState, setGamePlayState, state, setState, openUnrealGame],
  )
  return <GamePlayContext.Provider value={value}>{children}</GamePlayContext.Provider>
}

export { GamePlayContext, GamePlayProvider }
