import { useRouter } from 'next/router'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { getAccessTokenViaTempToken } from '@api/api'
import ROUTES from '@constant/Routes'
import { UserContext } from '@context/UserContext'
import useLoadingOverlay from '@hook/loadingOverlay/useLoadingOverlay'

function useAccessToken() {
  const router = useRouter()
  const { changeHasUserStatus } = useContext(UserContext)
  const { setLoadingOverlay } = useLoadingOverlay()

  const loginUser = async () => {
    setLoadingOverlay(true)

    try {
      const { data } = await getAccessTokenViaTempToken(router.query.tempToken)
      const jwt = data.access_token

      localStorage.setItem('jwt', jwt)

      const redirectTo = (router.query.redirectTo as string) || ROUTES.Download
      router.push(redirectTo)
      changeHasUserStatus(true)

      if (window.location.origin === process.env.NEXT_PUBLIC_HERO_APP_URL) {
        toast.success('User successfully logged in')
      }
    } catch (error) {
      handleLoginError(error)
    } finally {
      setLoadingOverlay(false)
    }
  }

  const handleLoginError = (error) => {
    const responseStatus = parseInt(error?.response?.status, 10)

    if (responseStatus === 401) {
      toast.error('Invalid user credentials.', { position: 'top-center' })
    } else {
      const errorMessage = error?.response?.data?.message || 'Failed to login user.'
      toast.error(errorMessage, { position: 'top-center' })
    }

    router.push(ROUTES.Home)
  }

  return {
    loginUser,
  }
}

export default useAccessToken
