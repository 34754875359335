/* eslint-disable react/jsx-props-no-spreading */
import NextLink, { LinkProps } from 'next/link'
import React from 'react'

interface Props extends LinkProps {
  children: React.ReactElement
}

const Link = (props: Props) => {
  const { children, href } = props
  if (!href) {
    return children
  }
  return (
    <NextLink {...props} style={{ textDecoration: 'none', color: 'inherit' }}>
      {children}
    </NextLink>
  )
}

export default Link
