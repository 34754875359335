import {
  WidgetsOutlined,
  Apps,
  AssignmentTurnedInOutlined,
  TrendingUpOutlined,
  FamilyRestroomOutlined,
  DashboardOutlined,
  ReportOutlined,
  AccountCircleOutlined,
  MonetizationOnOutlined,
} from '@mui/icons-material'
import ROUTES from '@constant/Routes'
import { MainMenuItem } from '@type/common'

export const WORK_DEFENSE_MENU_ITEMS: MainMenuItem = {
  name: 'Worker Defense Dept',
  path: ROUTES.WorkerDefenseDashboard,
  icon: <WidgetsOutlined />,
  showSubItems: false,
  newPageMenu: true,
  subItems: [
    {
      name: 'Worker Defense Dept',
    },

    {
      icon: <DashboardOutlined />,
      name: 'Dashboard',
      path: ROUTES.WorkerDefenseDashboard,
    },
    {
      icon: <ReportOutlined />,
      name: 'My Damage Report',
      path: ROUTES.WorkerDefenseMyDamageReport,
    },
    {
      icon: <MonetizationOnOutlined />,
      name: 'My Salary Increase',
      path: ROUTES.WorkerDefenseMySalaryIncrease,
    },
    {
      icon: <AccountCircleOutlined />,
      name: 'About Me',
      path: ROUTES.WorkerDefenseAboutMe,
    },
    {
      icon: <AccountCircleOutlined />,
      name: 'Enter More About Me',
      path: ROUTES.WorkerDefenseEnterMoreAboutMe,
    },
    {
      icon: <FamilyRestroomOutlined />,
      name: 'My Family',
      path: ROUTES.RiggedEconomyApps,
    },
    {
      icon: <TrendingUpOutlined />,
      name: 'True Inflation',
      path: ROUTES.WorkerDefenseTrueInflation,
      showSubItems: true,
      subItems: [
        {
          name: 'Add Data Source',
          path: ROUTES.WorkerDefenseTrueInflationAddDataSource,
        },
        {
          name: 'Data Admin',
          path: ROUTES.WorkerDefenseTrueInflationDataDashboard,
        },
      ],
    },
    {
      icon: <AssignmentTurnedInOutlined />,
      name: 'My Decision',
      path: ROUTES.WorkerDefenseMyDecision,
    },
    {
      icon: <Apps />,
      name: 'Rigged Apps',
      path: ROUTES.RiggedEconomyApps,
    },
  ],
}

// TODO: UPDATED LOGO AND FAVICONS
export const workerDefenseDepartmentConfig = {
  name: 'Worker Defense Department',
  logo: 'https://d37tchdccajyhz.cloudfront.net/static/WorkerDefenseLogo.png',
  gaCode: process.env.NEXT_PUBLIC_GTM_CODE,
  privacyPolicy: 'https://earth9.net/privacy-policy/',
  marketingVideoId: 'KKTU6zDYrBw',
  marketingIntroVideoId: process.env.NEXT_PUBLIC_MARKETING_INTRO_VIDEO_ID,
  favicon: {
    32: 'HoE-favicon.png',
    16: 'HoE-favicon.png',
    apple: 'HoE-favicon.png',
  },
  theme: {
    primary: {
      main: '#7F00FF',
      dark: '#4C096F',
    },
    buttonGradients: {
      gradientStart: '#4AA0FF',
      gradientEnd: '#025CC0',
    },
  },
  fixesMenuItems: {
    ...WORK_DEFENSE_MENU_ITEMS,
    subItems: WORK_DEFENSE_MENU_ITEMS.subItems.slice(1),
  },
  homePage: 'TRUE_INFLATION',
  paymentMenu: false,
}
