import { S3_BASE_URI } from '@constant/index'
import { ActionKeys, GameHotKeys, GameMenuButtonType, GameMenuState } from '../types'

export const questTransitionUrl =
  'https://d37tchdccajyhz.cloudfront.net/testing/web-game-videos/1690361945926_OO_UY0HnIH.webp'

export const gameHotKeys = [
  GameHotKeys.W,
  GameHotKeys.E,
  GameHotKeys.T,
  GameHotKeys.U,
  GameHotKeys.I,
  GameHotKeys.O,
  GameHotKeys.A,
  GameHotKeys.D,
  GameHotKeys.F,
  GameHotKeys.G,
]

export const totalPhases: string[] = [
  'Intro_Phase',
  'Overview_Phase',
  'Explore_Phase',
  'Friend_Phase',
  'Trial_Intro_Arguments_Phase',
  'Trial_Jury_Decides_Phase',
  'Trial_Testimony_Phase',
  'Congratulation_Phase',
  'Create_Laws_Phase',
  'Build_Values_Engine_Phase',
  'Learn_Bigger_Game_Phase',
  'Go_To_Bigger_Game_Phase',
]

export const menuButtons: GameMenuButtonType[] = [
  {
    label: 'Quests',
    screen: GameMenuState.QUEST,
    key: '1',
  },
  // { label: 'Hub', screen: GameMenuState.HUB, key: '2' },
  // { label: 'Ranch', screen: GameMenuState.RANCH, key: '3' },
  // {
  //   label: 'Value Engines',
  //   screen: GameMenuState.VALUE_ENGINE,
  //   key: '4',
  // },
  // {
  //   label: 'Home',
  //   screen: GameMenuState.HOME,
  //   key: '5',
  // },
  // {
  //   label: 'Underground Groups',
  //   screen: GameMenuState.UNDERGROUND_GROUP,
  //   key: '6',
  // },
]

export const buildingTourActionButton: ActionKeys[] = [
  { name: 'Play/Pause', key: 'P' },
  { name: 'Full Screen', key: 'F' },
  { name: 'Mute', key: 'M' },
  { name: 'Play Quest', key: 'F1' },
]

export const hubRanchPlayingActionButton: ActionKeys[] = [
  { name: 'Next Video', key: '→' },
  { name: 'Previous Video', key: '←' },
  { name: 'Play/Pause', key: 'P', isVideo: true },
  { name: 'Mute / Unmute', key: 'M', isVideo: true },
  { name: 'Home', key: 'H' },
]

export const qbuildingTourPlayingActionButton: ActionKeys[] = [
  { name: 'Next Media', key: '→' },
  { name: 'Previous Media', key: '←' },
  { name: 'Play/Pause', key: 'P', isVideo: true },
  { name: 'Full Screen / Exit Full Screen', key: 'F', isVideo: true },
  { name: 'Mute / Unmute', key: 'M', isVideo: true },
  { name: 'Home', key: 'H' },
]

export const underGroundActionButton: ActionKeys[] = [
  { name: 'Next Statue', key: '→' },
  { name: 'Previous Statue', key: '←' },
  { name: 'Home', key: 'H' },
]

export const valueFactoryActionButton: ActionKeys[] = [
  { name: 'Turn on Value Factory', key: 'O' },
  { name: 'Turn off Value Factory', key: 'O' },
]

export const TEMP_CATEGORIES_FOR_QUEST = [
  {
    label: 'Stock Exchange',
    key: '1',
  },
  {
    label: 'High Freq Firm',
    key: '2',
  },
  {
    label: 'Worker',
    key: '3',
  },
  {
    label: 'Author',
    key: '4',
  },
]

export const videoTransitionButton: ActionKeys[] = [{ name: 'Skip', key: 'S' }]
export const majorAreaplaceholderImage = `${S3_BASE_URI}/static/game-images/quest-background.webp`
export const questPlaceholderImage = `${S3_BASE_URI}/static/game-images/quest-background.webp`
export const networkFailureWifiImage = `${S3_BASE_URI}/static/network_issue_icon.svg`
export const placeholderVideo =
  'https://earth9hero.s3.amazonaws.com/production/ai/script/484b6561-fa75-4f2c-8e2d-fb70fdd92080.webm'
export const questLandingPageActionButton: ActionKeys[] = [{ name: 'Skip Intro', key: 'S' }]

export const genericQuestLink = 'Generic/Phases/'
export const gameMode = 'gamemode'
export const majorAreaIdKey = 'majorAreaIdKey'
export const publicPlayerProgress = 'publicPlayerProgress'

export const initialDataDetails = {
  page: 1,
  count: 0,
  limit: 6,
}
