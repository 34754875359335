import { useContext, useMemo } from 'react'
import { USER_SESSION_KEY } from '@constant/index'
import { UserContext } from '@context/UserContext'
import { SessionUser } from '@type/user'

/**
 * Hook for getting current logged in user
 */

export default function useCurrentUser() {
  const { currentUser, isLoading, refetch } = useContext(UserContext)

  const sessionUser = useMemo(() => {
    try {
      const sessionString = localStorage.getItem(USER_SESSION_KEY)
      return JSON.parse(sessionString) as SessionUser
    } catch (_) {
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]) // We need to hit local storage every time when there's user revalidation

  return {
    currentUser,
    isLoading,
    refetch,
    isAdmin: currentUser?.isAdmin,
    userName:
      currentUser?.firstname && currentUser?.lastname
        ? `${currentUser?.firstname} ${currentUser?.lastname}`
        : currentUser?.username,
    sessionUser,
  }
}
