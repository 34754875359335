export const heroOnEarthConfig = {
  name: 'HERO ON EARTH',
  logo: 'https://d37tchdccajyhz.cloudfront.net/static/hero-no-male-icon.png',
  gaCode: process.env.NEXT_PUBLIC_GTM_CODE,
  privacyPolicy: 'https://heroonearth.com/privacy-policy/',
  marketingVideoId: 'LN2FvLCsqDM',
  marketingIntroVideoId: process.env.NEXT_PUBLIC_MARKETING_INTRO_VIDEO_ID,
  favicon: {
    32: 'HoE-favicon.png',
    16: 'HoE-favicon.png',
    apple: 'HoE-favicon.png',
  },
  theme: {
    primary: {
      main: '#2843E8',
      dark: '#3950DA',
    },
    buttonGradients: {
      gradientStart: '#4AA0FF',
      gradientEnd: '#025CC0',
    },
  },
  paymentMenu: true,
}
