/* eslint-disable consistent-return */
import { useEffect } from 'react'
import { Box, Modal, Typography, Button } from '@mui/material'
import { useRouter } from 'next/router'
import Logo from '@commons/modules/Logo'
import theme from '@theme'
import { constructPlayURL, getUserGameTokenFromLocalStorage } from '@util/helpers'
import { setDeeplink } from '@api/challenge-game.api'
import { TypographyAdventureBody } from '@template/GameDashboard/Quest/QuestPlaylanding.Styles'
import useGamePlay from '@hook/game/useGamePlay'
import { DialogState } from '@context/GamePlayContext'
import { UNREAL_DOWNLOAD_LINK } from '@constant/index'
import ROUTES from '@constant/Routes'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: `${theme.palette.blue10} ! important`,
  border: '2px solid #000',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  p: 4,
}

const GameDialog = () => {
  const { gamePlayState, setGamePlayState, state, setState } = useGamePlay()
  const router = useRouter()

  useEffect(() => {
    const checkGameInstallation = async () => {
      const JWT = localStorage.getItem('jwt')
      if (!gamePlayState) return
      const userToken = getUserGameTokenFromLocalStorage()
      let playURL = !JWT
        ? `${UNREAL_DOWNLOAD_LINK}/?userToken=${userToken}`
        : `${UNREAL_DOWNLOAD_LINK}/?mode=&token=${JWT}&userToken=${userToken}`

      if (Object.keys(gamePlayState).length > 0) {
        const { entityId, mode, sceneId } = gamePlayState
        playURL = constructPlayURL({ JWT, entityId, mode, sceneId })
      }

      window.open(playURL, '_self')
      await setDeeplink({ query: playURL })
    }

    checkGameInstallation()

    const timeoutId = setTimeout(() => {
      setState(DialogState.SHOW_DOWNLOAD)
    }, 10000)

    return () => clearTimeout(timeoutId)
  }, [gamePlayState, setState])

  const handleClose = () => {
    setState(DialogState.LOADING)
    setGamePlayState(null)
  }

  return (
    <Modal
      open={Boolean(gamePlayState)}
      onClose={handleClose}
      aria-labelledby="dream-run-dialog-title"
      aria-describedby="dream-run-dialog-description"
    >
      <Box sx={style}>
        <Logo />
        {state === DialogState.LOADING && (
          <TypographyAdventureBody sx={{ mt: 2 }} textAlign="center">
            Launching Game...
          </TypographyAdventureBody>
        )}
        {state === DialogState.SHOW_DOWNLOAD && (
          <>
            <Typography textAlign="center" sx={{ m: 2 }} color="background.paper">
              Looks like you don&apos;t have the game installed. Download and try again.
            </Typography>
            {ROUTES.Download !== router.asPath && (
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  router.push(ROUTES.Download)
                }}
              >
                Download
              </Button>
            )}
          </>
        )}
      </Box>
    </Modal>
  )
}

export default GameDialog
