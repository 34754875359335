import { WidgetsOutlined, DashboardOutlined } from '@mui/icons-material'
import { MyAccountIcon } from '@commons/modules/Icons/icons'
import ROUTES from '@constant/Routes'
import { MainMenuItem } from '@type/common'

export const RIGGED_MEDICAL_MENU_ITEMS: MainMenuItem = {
  name: 'Rigged Medical',
  path: ROUTES.MedicalPriceDashboard,
  icon: <WidgetsOutlined />,
  showSubItems: false,
  newPageMenu: true,
  subItems: [
    {
      name: 'Rigged Medical',
    },
    {
      icon: <DashboardOutlined />,
      name: 'Dashboard',
      path: ROUTES.MedicalPriceDashboard,
    },
    {
      icon: <MyAccountIcon />,
      name: 'New Medical Work',
      path: ROUTES.RiggedEconomyApps,
    },
    {
      icon: <MyAccountIcon />,
      name: 'All Medical Work',
      path: ROUTES.RiggedEconomyApps,
    },
    {
      icon: <MyAccountIcon />,
      name: 'Examples',
      path: ROUTES.ExampleIndex,
    },
    {
      icon: <DashboardOutlined />,
      name: 'Insurance',
      // path: ROUTES.InternetPriorDebates,
      showSubItems: false,
      subItems: [
        {
          name: 'Bills',
          // path: ROUTES.WorkerDefenseMyDamageReport,
        },
        {
          name: 'Insurance',
          // path: ROUTES.WorkerDefenseAboutMe,
        },
        {
          name: 'Claims',
          // path: ROUTES.WorkerDefenseAboutMe,
        },
      ],
    },
    {
      icon: <DashboardOutlined />,
      name: 'Hospitals',
      path: ROUTES.MedicalPriceHospital,
      showSubItems: false,
      subItems: [
        {
          name: 'Hospitals',
          path: ROUTES.MedicalPriceHospital,
        },
        {
          name: 'Hospital Investigations',
          path: ROUTES.RiggedEconomyApps,
        },
        {
          name: 'Hospital Investigations',
          path: ROUTES.HospitalAddForm,
          hideMenu: true,
        },
      ],
    },
  ],
}

// TODO: UPDATED LOGO AND FAVICONS
export const medicalPriceDepartmentConfig = {
  name: 'Medical Price Department',
  logo: 'https://d37tchdccajyhz.cloudfront.net/static/medical-hq-logo.png',
  gaCode: process.env.NEXT_PUBLIC_GTM_CODE,
  privacyPolicy: 'https://earth9.net/privacy-policy/',
  marketingVideoId: 'KKTU6zDYrBw',
  marketingIntroVideoId: process.env.NEXT_PUBLIC_MARKETING_INTRO_VIDEO_ID,
  favicon: {
    32: 'HoE-favicon.png',
    16: 'HoE-favicon.png',
    apple: 'HoE-favicon.png',
  },
  theme: {
    primary: {
      main: '#7F00FF',
      dark: '#4C096F',
    },
    buttonGradients: {
      gradientStart: '#4AA0FF',
      gradientEnd: '#025CC0',
    },
  },
  fixesMenuItems: {
    ...RIGGED_MEDICAL_MENU_ITEMS,
    subItems: RIGGED_MEDICAL_MENU_ITEMS.subItems.slice(1),
  },
  homePage: 'MEDICAL_EXAMPLE_TABLE',
  paymentMenu: false,
}
