// User Authentication routes
export const SignIn = '/signin'
export const SignUp = '/signup'
export const Auth = '/auth'
export const VerifyEmail = '/verify-email/[id]'
export const ResetPassword = '/password-reset/[id]'

export const PlayCenter = '/play-center'

export const UnderConstruction = '/under-construction'

export const AdventurePaths = '/cities'
export const AdventurePathDetails = 'cities/[id]'
export const CreateAdventurePath = '/cities/create'

export const UserRegister = '/register/[userInviteId]'

export const AppLinks = '/app-links'

// Visitor pages routes
// export const Visitors = '/visitors'
// export const VisLocations = '/visitor/locations'
// export const VisitorPlots = '/visitor/plots'
// export const VisitorPropertyDetaills = '/visitor/property/[id]'
// export const VisMySchoolsHome = '/visitor/my-schools-home'
// export const VisAdventurePaths = '/visitor/adventure-paths'

// Investor pages routes
// export const InvestorHomepage = '/investors'
// export const InvestorProposeNewProperty = '/investors/propose-new-property'
// // export const InvestorAuctions = '/investors/auctions-new'
// export const AuctionsResale = '/investors/auctions-resale'
// export const InvestorAuctionsBid = '/investors/auctions-new/bid'
// export const AuctionsResaleBid = '/investors/auctions-resale/bid'
// export const MyProperties = '/investors/my-properties'
// export const ForSale = '/investors/for-sale'
// export const InvestorLocations = '/investors/locations'
// export const InvestorROI = '/investors/roi'
// export const InvestorBid = '/investors/auction/bid'
// export const InvestorAuctionBidId = '/investors/auction/bid/[id]'
// export const InvestorPropertyDetaills = '/investors/property/[id]'

// Creator pages routes
// export const CreatorHomepage = '/creators'
// export const CreatorBestToBuild = '/creators/build'
// export const CreatorAdventurePath = '/creators/path'
// export const CreatorAccount = '/creators/account'
// export const CreatorCommunityImageSets = '/creators/community-image-sets'
// export const CreatorLeaderboard = '/creators/leaderboard'
// export const CreatorPropertyDetaills = '/creators/property/[id]'
// export const CreatorProposeNewProperty = '/creators/propose-new-property'

export const CreatorSubmissionDetail = '/version/[id]'

export const Home = '/'
export const NewHome = '/new-home'

export const About = '/about'
export const Buildings = '/buildings'
export const Users = '/admin/users' // Users here refers to Our Players inside Admin
export const AdminUserEdit = '/admin/users/[id]/edit' // Admin user here refers to Players
export const AdminUserDetail = '/admin/users/[id]' // Admin user here refers to Players
export const PublicPlayerDetail = '/admin/users/[id]/public' // Admin user here refers to Players
export const InviteUser = '/admin/users/new'

export const PlotForVotes = '/admin/buildings/creators/plot-for-votes'
export const PlotVotesList = '/admin/buildings/creators/plot-votes-list/[id]'
export const CreatorVoteDetaills = '/admin/buildings/creators/vote-details/[id]'

// creators
export const CreatorSubmission = '/admin/buildings/creators/submissions/[id]'
export const CreatorsVoting = '/admin/buildings/creators/voting/[id]'

export const DownloadLauncher = '/download-launcher'

// Settings pages routes
export const Settings = '/settings'
export const SettingsMyProfile = `${Settings}/profile`
export const MyProfileWalletSettings = `${Settings}/wallet`
export const SettingsChangePassword = `${Settings}/password`
export const SettingsChangeEmail = `${Settings}/email`
export const SettingsDeleteAlc = `${Settings}/delete`

// settings/billings page routes
export const SettingBillingPlans = `${Settings}/plan`
export const SettingBillingSubscriptions = `${Settings}/subscription`
export const SettingBillingPaymentHistory = `${Settings}/payment-history`

export const Payment = '/payment'

// settings/payment method page routes
export const SettingPaymentMethod = `${Settings}/payment-method`
export const PaymentMethod = `${Payment}/payment-method/`

export const PaymentMethodDetail = `${SettingPaymentMethod}/[id]`
export const EditPaymentMethod = `${SettingPaymentMethod}/[id]/edit`
export const AddPaymentMethod = `${SettingPaymentMethod}/add`

// Forgot Password
export const ForgotPassword = '/forgot-password'

// Newly added routes for the leaderboard, my account and settings
export const Leaderboard = '/leaderboard'
export const MyAccount = '/account'
export const MyGameInfo = `${MyAccount}/[id]/game-info`
export const MyGame = '/game-info'
export const PaymentWebPack = '/payment/webpack'
export const PublicAccount = `${MyAccount}/public`
export const PublicAccountEdit = `${MyAccount}/[id]/edit`
export const PlayerAvatarCustomize = `${MyAccount}/customize-avatar`

// Building levels pages
export const BuildingsTour = '/buildings-tour'
export const BuildingLevelsDetails = '/buildings-tour/[id]'

// Quests
export const Quests = '/quests'

// Community Pages
export const CommunityList = '/community'
export const CommunityDetails = '/community/[id]'
export const CommunityCreate = '/community/create'

// Admin Routes
export const AdminMain = '/admin'
export const AICenter = '/admin/aicenter'

export const Characters = `${AICenter}/characters`
export const CharacterDetail = `${Characters}/[id]`
export const CharacterEdit = `${Characters}/[id]/edit`
export const AddCharacter = `${Characters}/add-character`

export const BackgroundImages = `${AICenter}/places`
export const BackgroundImageDetail = `${BackgroundImages}/[id]`
export const AddBackgroundImage = `${BackgroundImages}/add-place`

export const GithubFile = '/admin/story-engine/quests/ai/github/file/[path]'

export const AdminVisualComponents = '/admin/visual-components'

export const Policies = `${AICenter}/policies`
export const PoliciesDetail = `${Policies}/[id]`
export const AddPolicies = `${Policies}/add-policy`

export const AdminNeededPrompts = `${AICenter}/needed-prompts`
export const AdminNeededPrompt = `${AdminNeededPrompts}/[id]`
export const AdminAddNeededPrompt = `${AdminNeededPrompts}/add-prompt`

export const AdminQuests = '/admin/quests'
export const AdminQuest = '/admin/quests/[id]'
export const AdminAddQuest = '/admin/quests/add-quest'
export const AdminBuildings = '/admin/buildings'
export const BuildingsVote = '/admin/buildings/vote'
export const BuildingsBuild = '/admin/buildings/build'
export const BuildingsPlay = '/admin/buildings/play'
export const BuildingsDetails = '/admin/buildings/details/[id]'
export const BuildingsDetailsPath = '/admin/buildings/details'

export const AdminMajorAreas = `${AICenter}/major-areas`
export const AdminAddMajorArea = `${AdminMajorAreas}/add-major-area`
export const AdminMajorArea = `${AdminMajorAreas}/[id]`

export const AdminBooks = `${AICenter}/books`
export const AdminAddBook = `${AdminBooks}/add-book`
export const AdminBook = `${AdminBooks}/[id]`

export const AdminBooksChapters = `${AdminBook}/chapters`
export const AdminBooksChapter = `${AdminBooksChapters}/[chapterid]`

export const AdminMemes = '/admin/memes'
export const AdminAddMeme = '/admin/memes/add-meme'
export const AdminEditMeme = '/admin/memes/[id]'

export const AdminBooksChaptersChapterPrompts = `${AdminBooksChapter}/chapter-prompts`
export const AdminBooksChaptersAddChapterPrompt = `${AdminBooksChaptersChapterPrompts}/add-chapter-prompt`
export const AdminBooksChaptersChapterPrompt = `${AdminBooksChaptersChapterPrompts}/[chapterpromptid]`

export const AdminStatues = '/admin/statues'
export const AdminTiktoks = '/admin/tiktoks'

export const Curriculums = `${AICenter}/curriculum`
export const AdminGenerateCurriculum = `${Curriculums}/generate-curriculum`
export const CurriculumDetail = `${Curriculums}/[id]`

export const UserGameInfo = `/admin/users/[id]${MyGame}`
export const DreamRunMetrics = `${UserGameInfo}/dream-run-metrics`
export const NextQuestMetrics = `${UserGameInfo}/next-quest-metrics`
export const FlowRateMetrics = `${UserGameInfo}/flow-rate-metrics`
export const GemsUsageMetrics = `${UserGameInfo}/gems-usage-metrics`
export const ValueEngineMetrics = `${UserGameInfo}/value-engine-metrics`
export const FactorVotingMetrics = `${UserGameInfo}/factor-voting-metrics`
export const DecisionVotingMetrics = `${UserGameInfo}/decision-voting-metrics`
export const PhilosophyPackMetrics = `${UserGameInfo}/philosophy-pack-metrics`
export const PaymentMetrics = `${UserGameInfo}/payment-metrics`

export const MyGameInfoDreamRunMetrics = `${MyGameInfo}/dream-run-metrics`
export const MyGameInfoNextQuestMetrics = `${MyGameInfo}/next-quest-metrics`
export const MyGameInfoFlowRateMetrics = `${MyGameInfo}/flow-rate-metrics`
export const MyGameInfoGemsUsageMetrics = `${MyGameInfo}/gems-usage-metrics`
export const MyGameInfoValueEngineMetrics = `${MyGameInfo}/value-engine-metrics`
export const MyGameInfoFactorVotingMetrics = `${MyGameInfo}/factor-voting-metrics`
export const MyGameInfoDecisionVotingMetrics = `${MyGameInfo}/decision-voting-metrics`
export const MyGameInfoPhilosophyPackMetrics = `${MyGameInfo}/philosophy-pack-metrics`
export const MyGameInfoPaymentMetrics = `${MyGameInfo}/payment-metrics`
// Marketing Home Page
export const MarketingHome = '/home'

// New game Play
export const GameDashBoard = '/game-dashboard'

// Marketing Videos
export const PlayMarketingVideos = '/game-dashboard/marketing-videos/[id]'

// Internet Apps
export const InternetApps = '/admin/internet-apps'
export const Fixes = '/fixes'

// Platform
export const InternetAppPlatforms = `${Fixes}/platforms`
export const InternetAppAddPlatform = `${Fixes}/platforms/add-platform`
export const InternetAppPlatform = `${Fixes}/platforms/[id]`

// Rigged Econonmy
export const RiggedEconomy = `${Fixes}/rigged-economy`
export const YourProfile = `${RiggedEconomy}/your-information/your-profile`
export const YourFix = `${RiggedEconomy}/your-information/your-fix`
export const AppSetting = `${RiggedEconomy}/setting/app-setting`

const RiggedEconomyDebate = `${RiggedEconomy}/debate`
export const InternetAddDebate = `${RiggedEconomyDebate}/add-debate`
export const InternetDebate = `${RiggedEconomyDebate}/[id]`
export const InternetEditDebate = `${RiggedEconomyDebate}/[id]/edit`
export const InternetDebateNow = `${RiggedEconomyDebate}/debate-now`
export const InternetPriorDebates = `${RiggedEconomyDebate}/prior-debates`

export const RiggedEconomyForum = `${RiggedEconomy}/forum`
export const InternetAddForum = `${RiggedEconomyForum}/add-forum`
export const InternetEditForum = `${RiggedEconomyForum}/[id]`
export const InternetForum = `${RiggedEconomyForum}`

export const WorkerDefenseDept = `${Fixes}/worker-defense`

export const WorkerDefenseDashboard = `${WorkerDefenseDept}/dashboard`
export const WorkerDefenseTrueInflation = `${WorkerDefenseDept}/true-inflation`
export const WorkerDefenseTrueInflationDataDashboard = `${WorkerDefenseTrueInflation}/data-dashboard`
export const WorkerDefenseTrueInflationAddDataSource = `${WorkerDefenseTrueInflation}/add-data-source`
export const WorkerDefenseTrueInflationEditDataSource = `${WorkerDefenseTrueInflationAddDataSource}/[id]`
export const WorkerDefenseAboutMe = `${WorkerDefenseDept}/about-me`
export const WorkerDefenseEnterMoreAboutMe = `${WorkerDefenseDept}/enter-more-about-me`
export const WorkerDefenseMyDamageReport = `${WorkerDefenseDept}/my-damage-report`
export const WorkerDefenseMySalaryIncrease = `${WorkerDefenseDept}/my-salary-increase`
export const WorkerDefenseMyDecision = `${WorkerDefenseDept}/my-decision`
export const InternetForumPost = `${RiggedEconomyForum}/forum-post`
export const InternetEditForumPost = `${RiggedEconomyForum}/forum-post/[id]`

const RiggedEconomyArticles = `${RiggedEconomy}/articles`
export const RiggedEconomyRiggedAreas = `${RiggedEconomy}/rigged-areas`
export const RiggedEconomyRiggedAreaEdit = `${RiggedEconomy}/rigged-areas/[areaId]`

export const InternetAppsRiggedAreasTacticAdd = `${RiggedEconomyRiggedAreaEdit}/tactics/add`
export const InternetAppsRiggedAreasTacticEdit = `${RiggedEconomyRiggedAreaEdit}/tactics/[tacticsId]/edit`
export const InternetAppsRiggedAreasTacticResult = `${RiggedEconomyRiggedAreaEdit}/tactics/[tacticsId]/result`

export const RiggedEconomyPhilosophicPack = `${RiggedEconomy}/philosophy-pack`
export const InternetAppsArticle = `${RiggedEconomyArticles}`
export const InternetAppsAddArticle = `${RiggedEconomyArticles}/add-article`
export const InternetAppsEditArticle = `${RiggedEconomyArticles}/[id]`

export const InternetAppsRiggedAreasTacticView = `${RiggedEconomyRiggedAreaEdit}/tactics/[tacticsId]`
export const InternetAppsPhilosophicPack = `${RiggedEconomyPhilosophicPack}`
export const InternetAppsAddPhilosophicPack = `${RiggedEconomyPhilosophicPack}/add-philosophy-pack`
export const RecommendPhilosophicPack = `${RiggedEconomyPhilosophicPack}/recommend`
export const RecommendPhilosophicPackDetails = `${RecommendPhilosophicPack}/[id]`
export const RecommendPhilosophicPackEdit = `${RecommendPhilosophicPackDetails}/edit`
export const InternetAppsPhilosophicPackDetails = `${RiggedEconomyPhilosophicPack}/[id]`
export const InternetAppsEditPhilosophicPack = `${RiggedEconomyPhilosophicPack}/[id]`

// Big Fixes
export const RiggedEconomyBigFixes = `${RiggedEconomy}/big-fixes`
export const RiggedEconomyAddBigFix = `${RiggedEconomyBigFixes}/add`
export const RiggedEconomyBigFixDetails = `${RiggedEconomyBigFixes}/[id]`
export const RiggedEconomyEditBigFix = `${RiggedEconomyBigFixes}/[id]/edit`
export const RiggedEconomyAddMajorFix = `${RiggedEconomyBigFixes}/[id]/add-major-fix`
export const RiggedEconomyEditMajorFix = `${RiggedEconomyBigFixes}/[id]/major-fixes/[majorFixId]/edit`
export const RiggedEconomyBigFixVote = `${RiggedEconomyBigFixes}/[id]/vote`

export const CreateRedboxFix = `${InternetAppsRiggedAreasTacticView}/redbox-fixes/add-redbox-fix`
export const RedboxFixView = `${InternetAppsRiggedAreasTacticView}/redbox-fixes/[id]`
export const RedboxFixEdit = `${InternetAppsRiggedAreasTacticView}/redbox-fixes/[id]/edit`

export const RiggedEconomyApps = `${RiggedEconomy}/rigged-apps`
export const RiggedEconomySlideshowImages = `${RiggedEconomy}/slideshow-images`

export const SlideshowImages = `${RiggedEconomySlideshowImages}`
export const AddSlideshowImage = `${RiggedEconomySlideshowImages}/add-slideshow`
export const SlideshowImage = `${RiggedEconomySlideshowImages}/[id]`

export const RiggedEconomyVotingResults = `${RiggedEconomy}/voting-result`
export const UndergroundVotingResults = `${RiggedEconomyVotingResults}/underground/[id]`
export const RiggedEconomyVotingFactorResultDetail = `${RiggedEconomyVotingResults}/voting-factor/[id]`
export const RiggedEconomyVotingFixesResultDetail = `${RiggedEconomyVotingResults}/fixes-result/[id]`

export const RiggedAIDetails = `${RiggedEconomyRiggedAreaEdit}/ai-details`
export const RiggedAIDetail = `${RiggedEconomyRiggedAreaEdit}/ai-details/[id]`
export const AddRiggedAIDetails = `${RiggedEconomyRiggedAreaEdit}/ai-details/add`
export const EditRiggedAIDetails = `${RiggedAIDetail}/edit`
export const AddRiggedAIDetailsHardFacts = `${RiggedAIDetail}/hard-facts/add`
export const EditRiggedAIDetailsHardFacts = `${RiggedAIDetail}/hard-facts/[hard-fact-id]`

export const RiggedRedBoxAIDetails = `${InternetAppsRiggedAreasTacticView}/ai-details`
export const RiggedRedBoxAIDetail = `${RiggedRedBoxAIDetails}/[id]`
export const AddRiggedRedBoxAIDetails = `${RiggedRedBoxAIDetails}/add`
export const EditRiggedRedBoxAIDetails = `${RiggedRedBoxAIDetail}/edit`
export const AddRiggedRedBoxAIDetailsHardFacts = `${RiggedRedBoxAIDetail}/hard-facts/add`
export const EditRiggedRedBoxAIDetailsHardFacts = `${RiggedRedBoxAIDetail}/hard-facts/[hard-fact-id]`

// Medical price
export const MedicalPrice = `${Fixes}/rigged-medical`
export const MedicalPriceDashboard = `${MedicalPrice}/dashboard`
export const MedicalPriceHospital = `${MedicalPrice}/hospitals`
export const HospitalAddForm = `${MedicalPrice}/hospitals/add-hospital`

export const ExampleIndex = `${MedicalPrice}/examples`
export const MedicalAddExample = `${MedicalPrice}/examples/add-example`

// 2D WEB GAME
export const Admin2DGame = 'admin/2d-game'
export const AdminNormalQuest = `/admin/story-engine/quests/normal`
export const AdminAiQuest = `/admin/story-engine/quests/ai`
export const AdminQuestGenericPhases = `/admin/story-engine/quests/generic-phases`
export const AdminAiQuestJobs = `/admin/story-engine/quests/ai/jobs`
export const AdminAiQuestEdit = `/admin/story-engine/quests/ai/[id]`
export const AdminAiQuestDetails = `/admin/story-engine/quests/ai/detail/[id]`
export const AdminAiQuestCreate = `/admin/story-engine/quests/ai/add-quest`
export const CreateBuildingLevels = `normal/create`
export const BuildingLevelDetails = `/admin/quests/normal/[id]`
export const AdminGameDashboard = '/admin/2d-game/game-dashboard'
export const AdminMyGame = '/admin/2d-game/game'
export const ChallengeGame = '/admin/story-engine/dream-runs'
export const ChallengeGameLevels = `${ChallengeGame}/all-levels`
export const ChallengeGameLevelsEdit = `${ChallengeGameLevels}/level-editor`
export const ChallengeGameList = `${ChallengeGame}`
export const ChallengeGameNewEditor = `${ChallengeGame}/level-editor`
export const ChallengeGameAddNew = `${ChallengeGame}/add`
export const ChallengeGameDetail = `${ChallengeGame}/[id]`
export const ChallengeGameVotingResults = `${ChallengeGame}/[id]/voting-results`
export const ChallengeGameVotingResultDetails = `${ChallengeGame}/[id]/voting-results/[votingResultId]`
export const ChallengeGameAddLevelMap = `${ChallengeGame}/add-level-map`

export const MainProgress = '/admin/story-engine/main-progress'
export const MainProgressAddNew = `${MainProgress}/add`
export const MainProgressDetail = `${MainProgress}/[id]`
export const MainProgressAddLevelMap = `${MainProgress}/level-editor`

export const SixYearStrategy = '/admin/story-engine/six-year-strategy'
export const SixYearStrategyAddNew = `${SixYearStrategy}/add`
export const SixYearStrategyDetail = `${SixYearStrategy}/[id]`
export const SixYearStrategyAddLevelMap = `${SixYearStrategy}/level-editor`
export const SixYearStrategyVotingResults = `${SixYearStrategyDetail}/voting-results`
export const SixYearStrategyVotingResultDetails = `${SixYearStrategyVotingResults}/[votingResultId]`

export const UserGeneratedContent = '/admin/user-generated-content'
export const UGCDreamRun = `${UserGeneratedContent}/dream-run`
export const UGCDreamRunDetails = `${UGCDreamRun}/[id]`
export const UGCDreamRunEdit = `${UGCDreamRun}/edit`
export const UGCDreamRunLevelEditor = `${UGCDreamRun}/level-editor`
export const UGCSixYear = `${UserGeneratedContent}/six-year`
export const UGCSixYearDetails = `${UGCSixYear}/[id]`
export const UGCSixYearEdit = `${UGCSixYear}/edit`
export const UGCSixYearLevelEditor = `${UGCSixYear}/level-editor`
export const UGCPhilPack = `${UserGeneratedContent}/phil-pack`
export const UGCPhilPackDetails = `${UGCPhilPack}/[id]`
export const UGCPhilPackEdit = `${UGCPhilPackDetails}/edit`

export const StoryEngine = '/admin/story-engine'
export const UserStoryEngine = '/user-story-engine'
export const UserDreamRuns = `${UserStoryEngine}/dream-runs`
export const UserDreamRunDetail = `${UserDreamRuns}/[id]`
export const UserDreamRunDetailEdit = `${UserDreamRuns}/add`
export const UserDreamRunNewEditor = `${UserDreamRuns}/level-editor`
export const UserSixYear = `${UserStoryEngine}/six-year`
export const UserSixYearAddNew = `${UserSixYear}/add`
export const UserSixYearDetail = `${UserSixYear}/[id]`
export const UserSixYearDetailEdit = `${UserSixYear}/add`
export const UserSixYearNewEditor = `${UserSixYear}/level-editor`
export const UserScenes = '/user-story-engine/scenes'
export const UserSceneDetails = `${UserScenes}/[id]`

export const TiktokAndYoutubeVideos = `${StoryEngine}/tiktok-and-youtube-video`
export const Quotes = `${StoryEngine}/quotes`
export const AddQuote = `${Quotes}/add`
export const QuoteDetail = `${Quotes}/[id]`
export const QuoteEdit = `${QuoteDetail}/edit`
export const UserTiktokAndYoutubeVideos = `${UserStoryEngine}/tiktok-and-youtube-video`
export const AddTiktokAndYoutubeVideo = `${TiktokAndYoutubeVideos}/add`
export const AddUserTiktokAndYoutubeVideo = `${UserTiktokAndYoutubeVideos}/add`
export const TiktokAndYoutubeVideoDetail = `${TiktokAndYoutubeVideos}/[id]`
export const TiktokAndYoutubeVideoEdit = `${TiktokAndYoutubeVideoDetail}/edit`
export const UserTiktokAndYoutubeVideoDetail = `${UserTiktokAndYoutubeVideos}/[id]`
export const UserTiktokAndYoutubeVideoEdit = `${UserTiktokAndYoutubeVideoDetail}/edit`

export const EditDialogue = `${StoryEngine}/dialogue-sessions/[id]/edit`
export const Scenes = `${StoryEngine}/scenes`
export const AddScenes = `${StoryEngine}/scenes/add`
export const ScenesDetail = `${StoryEngine}/scenes/[id]`
export const EditScenes = `${StoryEngine}/scenes/[id]/edit`
export const ScenePoliticalCheck = `${StoryEngine}/scenes/[id]/political-check`
export const SceneDialogueDetail = `${StoryEngine}/scenes/[id]/dialogue-sessions/[dialogue-id]`

export const SceneChoice = `${StoryEngine}/choices/scene-choices/[id]`
export const AddSceneChoice = `${StoryEngine}/choices/scene-choices/add`
export const DialogueChoice = `${StoryEngine}/choices/dialogue-choices/[id]`
export const AddDialogueChoice = `${StoryEngine}/choices/dialogue-choices/add`

export const StoryEngineCharacters = `${StoryEngine}/characters`
export const StoryEngineCharacter = `${StoryEngine}/characters/[id]`
export const StoryEngineCharacterEdit = `${StoryEngine}/characters/[id]/edit`
export const StoryEngineCharacterAdd = `${StoryEngine}/characters/add`

export const Areas = `${StoryEngine}/areas`
export const AreasDetail = `${StoryEngine}/areas/[id]`
export const EditAreas = `${StoryEngine}/areas/[id]/edit`
export const AddAreas = `${StoryEngine}/areas/add`
export const AreasScenesDetail = `${StoryEngine}/areas/[id]/scenes/[scene-id]`
export const AreasScenesDialogueDetail = `${StoryEngine}/areas/[id]/scenes/[scene-id]/dialogue-sessions/[dialogue-id]`

export const ContentPacks = `${StoryEngine}/content-packs`
export const ContentPackDetail = `${StoryEngine}/content-packs/[id]`
export const EditContentPack = `${StoryEngine}/content-packs/[id]/edit`
export const AddContentPack = `${StoryEngine}/content-packs/add`

export const ContentTypes = `${StoryEngine}/content-types`
export const ContentType = `${StoryEngine}/content-types/[id]`
export const EditContentType = `${StoryEngine}/content-types/[id]/edit`
export const AddContentTypes = `${StoryEngine}/content-types/add`

export const Phases = `${StoryEngine}/phases`
export const Phase = `${StoryEngine}/phases/[id]`
export const AddPhases = `${StoryEngine}/phases/add`

export const Items = `${StoryEngine}/items`
export const Item = `${StoryEngine}/items/[id]`
export const ItemEdit = `${StoryEngine}/items/[id]/edit`
export const AddItem = `${StoryEngine}/items/add`

export const VotingFactors = `${StoryEngine}/voting-factors`
export const VotingFactor = `${StoryEngine}/voting-factors/[id]`
export const EditVotingFactor = `${StoryEngine}/voting-factors/[id]/edit`
export const VotingFactorResult = `${VotingFactor}/result`
export const AddVotingFactor = `${StoryEngine}/voting-factors/add`

export const StoryEngineGameBadges = `${StoryEngine}/game-badges`
export const AddGameGoal = `${StoryEngineGameBadges}/game-goals/add`
export const GameGoals = `${StoryEngineGameBadges}/game-goals`
export const GameGoalDetails = `${StoryEngineGameBadges}/game-goals/[id]`
export const EditGameGoal = `${StoryEngineGameBadges}/game-goals/[id]/edit`

export const GameBadges = `${StoryEngineGameBadges}`
export const AddBadge = `${StoryEngineGameBadges}/add`
export const GameBadgeDetails = `${StoryEngineGameBadges}/[id]`
export const EditGameBadge = `${StoryEngineGameBadges}/[id]/edit`

export const Templates = `${StoryEngine}/templates`
export const Template = `${StoryEngine}/templates/[id]`
export const AddTemplate = `${StoryEngine}/templates/add`
export const EditTemplate = `${StoryEngine}/templates/[id]/edit`

export const SurfingSections = `${StoryEngine}/surfing-sections`
export const SurfingSection = `${StoryEngine}/surfing-sections/[id]`
export const EditSurfingSection = `${StoryEngine}/surfing-sections/[id]/edit`
export const AddSurfingSection = `${StoryEngine}/surfing-sections/add`

export const Places = `${StoryEngine}/places`
export const Place = `${StoryEngine}/places/[id]`
export const EditPlace = `${StoryEngine}/places/[id]/edit`
export const AddPlace = `${StoryEngine}/places/add`

export const AdminTour = '/admin/2d-game/tours'

// web game videos
export const WebGameVideos = '/webGameVideos'
export const Transitions = `${StoryEngine}/transitions`
export const UnrealVideoGeneration = `${Transitions}/generation`
export const CreateTransitions = `${StoryEngine}/transitions/create`
export const ViewTransitions = `${StoryEngine}/transitions/[id]`
export const EditTransitions = `${StoryEngine}/transitions/[id]/edit`
export const Videos = `${StoryEngine}/videos`
export const CreateVideos = `${StoryEngine}/videos/create`
export const EditVideos = `${StoryEngine}/videos/[id]`

export const Tricorder = '/tricorder'

export const Download = '/download'
export const LauchGame = '/launch-game'
export const Plans = '/plans'
export const GameScreen = '/game-screen'
export const ContactUs = '/contactus'

export const Chapters = `${AICenter}/chapters`
export const AddChapter = `${AICenter}/chapters/add`
export const ChapterDetail = `${AICenter}/chapters/[id]`
export const ChapterEdit = `${AICenter}/chapters/[id]/edit`

export const Missions = `${AICenter}/missions`
export const AddMission = `${AICenter}/missions/add`
export const MissionDetail = `${AICenter}/missions/[id]`
export const MissionEdit = `${AICenter}/missions/[id]/edit`
export const MissionGenerateViaAI = `${AICenter}/missions/[id]/generate-via-ai`

export const Questionnaires = `${AICenter}/questionnaires`
export const AddQuestionnaire = `${AICenter}/questionnaires/add`
export const QuestionnaireDetail = `${AICenter}/questionnaires/[id]`
export const QuestionnaireEdit = `${AICenter}/questionnaires/[id]/edit`

export const Themes = `${AICenter}/themes`
export const AddTheme = `${AICenter}/themes/add`
export const ThemeDetail = `${AICenter}/themes/[id]`
export const ThemeEdit = `${AICenter}/themes/[id]/edit`

export const AIGeneratedChallengeGames = `${AICenter}/aigenerated-challenge-games`
export const AddAIGeneratedChallengeGame = `${AICenter}/aigenerated-challenge-games/add`
export const AIGeneratedChallengeGameDetail = `${AICenter}/aigenerated-challenge-games/[id]`
export const AIGeneratedChallengeGameInteractiveDetail = `${AICenter}/aigenerated-challenge-games/[id]/interactive`
export const AIGeneratedChallengeGameEdit = `${AICenter}/aigenerated-challenge-games/[id]/edit`
export const AIGeneratedChallengeGameExtractContent = `${AICenter}/aigenerated-challenge-games/[id]/extract-content`

export const AssemblyPasses = `${AICenter}/assembly-passes`
export const AddAssemblyPass = `${AICenter}/assembly-passes/add`
export const AssemblyPassDetail = `${AICenter}/assembly-passes/[id]`
export const AssemblyPassEdit = `${AICenter}/assembly-passes/[id]/edit`

export const AssemblyMissionRuns = `${AICenter}/assembly-mission-runs`
export const AssemblyMissionDetail = `${AICenter}/assembly-passes/[id]/assembly-mission/[assembly-mission-id]`

export const LandLockPuzzle = `${AICenter}/landlock-puzzle`
export const LandLockPuzzleDetail = `${AICenter}/landlock-puzzle/[id]`
export const AddLandLockPuzzle = `${AICenter}/landlock-puzzle/add`
export const LandLockPuzzleLevelDetail = `${AICenter}/landlock-puzzle/[id]/level/[levelId]`

export const StoryEngineSceneIFTTTAdd = `${StoryEngine}/scenes/[id]/ifttt`
export const StoryEngineSceneIFTTTEdit = `${StoryEngine}/scenes/[id]/ifttt/[ifttt_id]/edit`

export const AdminPromptVariables = `${AICenter}/prompt-variables`
export const AdminPromptVariable = `${AdminPromptVariables}/[id]`
export const AdminAddPromptVariable = `${AdminPromptVariables}/add`

export const AIDetails = `${AICenter}/ai-details`
export const AddAIDetails = `${AIDetails}/add`
export const AIDetailsDetail = `${AIDetails}/[id]`
export const EditAIDetails = `${AIDetailsDetail}/edit`
export const AddAIDetailsHardFacts = `${AIDetailsDetail}/hard-facts/add`
export const AIDetailsHardFactsDetail = `${AIDetailsDetail}/hard-facts/[hard-fact-id]`

// Character Areas
export const CharacterAreas = `${StoryEngineCharacters}/character-areas`
export const CharacterAreasAdd = `${StoryEngineCharacters}/character-areas/add`
export const CharacterAreasEdit = `${StoryEngineCharacters}/character-areas/[id]/edit`
export const CharacterAreaDetail = `${StoryEngineCharacters}/character-areas/[id]`

// Item Areas
export const ItemAreas = `${Items}/item-areas`
export const ItemAreasAdd = `${Items}/item-areas/add`
export const ItemAreasEdit = `${Items}/item-areas/[id]/edit`
export const ItemAreaDetail = `${Items}/item-areas/[id]`

export const StoryEngineSceneDialogueIFTTTAdd = `${StoryEngine}/scenes/[id]/dialogue-sessions/[dialogue-id]/ifttt`
export const StoryEngineSceneDialogueIFTTTEdit = `${StoryEngine}/scenes/[id]/dialogue-sessions/[dialogue-id]/ifttt/[ifttt_id]/edit`

export const PlacesAreas = `${Places}/place-areas`
export const PlacesAreasAdd = `${Places}/place-areas/add`
export const PlacesAreasEdit = `${Places}/place-areas/[id]/edit`
export const PlacesAreaDetail = `${Places}/place-areas/[id]`

// congress-challenger
export const Congress = `${Fixes}/congress`
export const CongressMembers = `${Congress}/members`

export const CongressChallengers = `${Congress}/congress-challengers`
export const SenatorCongressChallengers = `${CongressChallengers}/senator`
export const AddSenatorCongressChallenger = `${SenatorCongressChallengers}/add`
export const SenatorCongressChallengerDetail = `${SenatorCongressChallengers}/[id]`
export const SenatorCongressChallengerEdit = `${SenatorCongressChallengerDetail}/edit`
export const RepresentativeCongressChallengers = `${CongressChallengers}/representative`
export const AddRepresentativeCongressChallenger = `${RepresentativeCongressChallengers}/add`
export const RepresentativeCongressChallengerDetail = `${RepresentativeCongressChallengers}/[id]`
export const RepresentativeCongressChallengerEdit = `${RepresentativeCongressChallengerDetail}/edit`
export const MemberProfile = `${CongressMembers}/profile/[id]`
export const CommitteeList = `${Congress}/committee`
export const CommitteeDetail = `${CommitteeList}/[id]`
export const SubCommitteeDetail = `${Congress}/sub-committee/[id]`
export const PrimaryVotes = `${Congress}/primary-votes`
export const AddPrimaryVotes = `${PrimaryVotes}/add`
export const PrimaryVotesDetail = `${PrimaryVotes}/[id]`
export const PrimaryVotesEdit = `${PrimaryVotesDetail}/edit`

export const MyRepresentatives = `${Congress}/my-representatives`
export const CongressChallengerVoting = `${Congress}/voting`
export const UnveilSenators = `${MyRepresentatives}/unveil-senators`
export const UnveilRepresentatives = `${MyRepresentatives}/unveil-representatives`

export const RevisionControl = '/admin/revision-control'
export const RevisionControlDetail = `${RevisionControl}/[id]`
