export interface UserPref {
  storage: {}
  updateStorage: (key: UserPrefKey, value: any) => void
  getStorageItem: (key: UserPrefKey) => any
}

export const UserPrefStorageKey = 'appData'

export enum UserPrefKey {
  DreamRunPublished = 'drp',
  DreamRunLevelsPublished = 'drlp',
  SixYearPublished = 'syp',
  MainProgressPublished = 'mpp',
  CharacterPublished = 'cp',
  ItemsPublished = 'ip',
  ScenesPublished = 'sp',
  MissionPublished = 'mp',
  RiggedUndergroundPublished = 'rup',
  PhilPackPublished = 'ppp',
  TablePageLimit = 'tps',
  AdminUGCStatus = 'augcs',
  BigFixPublished = 'bfp',
  VotingFactorPublished = 'vfp',
}
