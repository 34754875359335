import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { UserContext } from '@context/UserContext'
import ROUTES from '@constant/Routes'
import { UserPrefStorageKey } from '@type/usePref'

function useLogout(redirect: boolean = true) {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { changeHasUserStatus } = useContext(UserContext)
  const logoutUser = useCallback(async (hasMessage = true, redirectTo = ROUTES.SignIn) => {
    const prefCache = localStorage.getItem(UserPrefStorageKey)
    localStorage.clear()
    localStorage.setItem(UserPrefStorageKey, prefCache)
    changeHasUserStatus(false)

    // setting this to broadcast a logout event to other tabs
    localStorage.setItem('logout', Date.now().toString())

    queryClient.resetQueries(['currentUser', true], { exact: true })
    queryClient.removeQueries()
    if (hasMessage) {
      toast.success('You have successfully logged out.')
    }

    if (redirect)
      router.push(
        `${redirectTo}${
          router.query.origin ? `?origin=${encodeURIComponent(router.query.origin as string)}` : ''
        }`,
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // to notify other tabs about the logout event
  // setting up event listener for storage event
  // remember this will listen to any change that occurs in the localStorage,
  // not just specific events like "add" or "remove."
  // for specific events, we need to add our own logic to the executed method
  useEffect(() => {
    const handleLogout = () => {
      // reloading the other opened tabs in case of logout only for now
      if (localStorage.getItem('logout')) {
        localStorage.removeItem('logout')
        router.reload()
      }
    }

    // storage event listener
    window.addEventListener('storage', handleLogout)

    return () => {
      // event cleanup for storage event listener
      window.removeEventListener('storage', handleLogout)
    }
  }, [router])

  return {
    logoutUser,
  }
}

export default useLogout
