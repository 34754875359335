import male from '@asset/challenge/MALE.png'
import exit from '@asset/challenge/EXIT.png'

import monster from '@asset/challenge/MONSTER.png'
import axe from '@asset/challenge/AXE.png'
import home from '@asset/challenge/HOME.png'

import treasure from '@asset/challenge/TREASURE.png'
import boat from '@asset/challenge/BOAT.png'
import key from '@asset/challenge/KEY.png'

import laser from '@asset/challenge/LASER.png'
import gem from '@asset/challenge/GEM.png'
import water from '@asset/challenge/WATER.png'

import wall from '@asset/challenge/WALL.png'
import fire from '@asset/challenge/FIRE.png'
import tree from '@asset/challenge/TREE.png'
import arrow from '@asset/challenge/ARROW.png'
import shovel from '@asset/challenge/SHOVEL.png'
import wood from '@asset/challenge/WOOD.png'
import { WebGameVideoSchema } from '@type/schemas/WebGameVideo'
// eslint-disable-next-line import/no-cycle
import { Area } from '@type/area'
import { LandLockPuzzleAi } from '@type/landlock-puzzle'
import { Mission } from '@type/mission'
import { Theme } from '@type/theme'
import { AssemblyMission } from '@type/assembly-mission'
import { AssemblyGroup } from '@type/assembly-pass'
import { Item } from '@type/item'
import { User } from '@type/schemas/UserSchema'
import { IFactorVotingResult, IFixesVotingResult, IVotingResultDetails } from '@type/vote'
import { VotingFactor } from '@type/voting-factor'

export type CellProperty = {
  value?: JSX.Element
  key: LayoutButtonKeys | string
  id: string
  X: number
  Y: number
  name: string
  clues: string[]
  properties?: Record<any, any>
  editableProps?: EditableProperties
  itemEntityType: string
  image2dUrl: string
  assetIdentifier: string
  description: string
  slug: string
  glass?: boolean
  glassTop?: boolean
  glassLeft?: boolean
  glassRight?: boolean
  glassBottom?: boolean
  text?: string
  landSquare?: Record<string, any>
  category: string
  readOnly?: boolean
  color?: string
  items?: (Item & { key: string; editableProps?: EditableProperties; value: JSX.Element })[]
}

export type ChallengeGameDataGrid = {
  id?: string
  level?: number
  levelName?: string
  challengeGameId?: string
  data?: CellProperty[][]
  nextLevelId?: string
  transitionVideoId?: string
  transitionVideo?: WebGameVideoSchema
  introSceneId?: string
  introScene?: { id: string; name: string }
  outroSceneId?: string
  outroScene?: { id: string; name: string }
  published?: boolean
  priority?: number
  landSquare?: Record<string, any>
  difficulty?: GridDifficulty
  gridMetrics?: GridMetrics
}

export type GridMetrics = {
  gridId: string
  totalStartedPlaythroughs?: number
  totalCompletedPlaythroughs?: number
  avgStepsPerRestart?: number
}

export enum GridDifficulty {
  Unknown = 'Unknown',
  Easy = 'Easy',
  Medium = 'Medium',
  Hard = 'Hard',
  Fiendish = 'Fiendish',
  Broken = 'Broken',
}

export type ChallengeGameDataGridList = {
  id: string
  level?: number
  levelName?: string
  challengeGameId?: string
  challengeGame?: ChallengeGameData
  published?: boolean
  priority?: number
  createdAt?: Date
  updatedAt?: Date
  difficulty?: GridDifficulty
}

export type ChallengeGameDataResponse = {
  data: ChallengeGameData[]
  count: number
  page: number
  limit: number
}

export type ChallengeGameItem = {
  id: string
  slug: string | null
  name: string
  author: string
  itemEntityType: string
  assetId: string | null
  itemMetaData: any | null
  createdByUserId: string
  publishedByUserId: string
  published: boolean
  createdAt: string
  deletedAt: string | null
  deletedByUserId: string | null
  updatedAt: string
  updatedByUserId: string | null
  aiText: string
  image2dUrl: string
  image3dUrl: string | null
  hotkey: string
  parentId: string | null
  hierarchyLevelType: string
  assetIdentifier: string
}

type ChallengeGamePackToItem = {
  id: string
  challengeGamePackId: string
  itemId: string
  item: ChallengeGameItem
}

export type ChallengeGamePack = {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  children: ChallengeGamePack[]
  challengeGamePackToItems: ChallengeGamePackToItem[]
}

export type NextChallengeLevel = {
  id: string
  gameName: string
  nextLevelId: string
}

export type ChallengeGameData = {
  id?: string
  gameName?: string
  gamePublicTitle?: string
  subtitle?: string
  majorArea?: { id?: string; name?: string }
  majorAreaId?: string
  areaId?: string
  isBoughtWithGems?: boolean
  contentTypeId?: string
  published?: boolean
  challengeGameGrids?: ChallengeGameDataGrid[]
  createdByUserId?: string
  completionPercentage?: number
  nextLevelId?: string
  area?: Partial<Area>
  tactics?: Array<{ title: string }>
  challengeGameType?: string
  landLockPuzzleAiId?: string
  landLockPuzzleAi?: LandLockPuzzleAi
  missionId?: string
  mission?: Mission
  themeId?: string
  theme?: Theme
  gameYaml?: string
  author?: string
  levelSceneOrder?: Array<any>
  createdBy?: Pick<User, 'id' | 'username' | 'createdAt' | 'firstname' | 'lastname'>
  createdAt?: string | Date
  assemblyMissionId?: string
  assemblyMission?: AssemblyMission
  gemLevel?: number
  assemblyGroupId?: string
  assemblyGroup?: AssemblyGroup
  gemRequired?: boolean
  introSceneId?: string
  introScene?: { id: string; name: string }
  outroSceneId?: string
  outroScene?: { id: string; name: string }
  details?: Record<string, any>
  updatedAt?: string
  verifiedGameYaml?: boolean
  priority?: number
  category?: ChallengeGameCategory
  userGeneratedContent?: {
    status: string
    id: string
  }
  avgTimeToComplete?: number
  avgTimeToCompleteWithoutDialogue?: number
  avgSteps?: number
  avgRetries?: number
  gridMetrics?: GridMetrics[]
  gridId?: string
  level?: number
  gridChallengeGameId?: string
  year?: number
  votingFactors?: VotingFactor[]
}

export type FixesVotingResultResponse = IFixesVotingResult

export type FactorVotingResultResponse = {
  result: IFactorVotingResult[]
  count: number
}

export type RedboxFixResultResponse = {
  result: IFixesVotingResult[]
  count: number
}

export type VotingResultDetailsResponse = IVotingResultDetails

export interface FixVote {
  votesCount: number
  percentage: number
  votes: Vote[]
}

export interface Vote {
  id: string
  voterId: string
  fixId: string
  createdByUserId: string
  createdAt: string
  updatedByUserId: any
  updatedAt: string
  deletedByUserId: any
  deletedAt: any
}

export enum ChallengeGameCategory {
  DreamRun = 'Dream Run',
  MainProgress = 'Main Progress',
  SixYearStrategy = 'Six Year Strategy',
  UserGenerated = 'User Generated',
}

export enum EditorMode {
  // eslint-disable-next-line no-unused-vars
  Properties = 'Properties',
  Layout = 'Layout',
  Eraser = 'Eraser',
}

export enum LayoutButtonKeys {
  Wall = 'W',
  Fire = 'FI',
  Tree = 'T',
  Water = 'WA',
  Player = 'P',
  Gem = 'G',
  Treasure = 'TR',
  Key = 'K',
  Home = 'H',
  Laser = 'L',
  Axe = 'A',
  Boat = 'B',
  Monster = 'MO',
  Arrow = 'AR',
  Shovel = 'S',
  Wood = 'WO',
  Exit = 'E',
}

export enum TreasureAsset {
  Axe = 'Axe',
  Laser = 'Laser',
  Boat_4 = 'Boat_4',
  Boat_8 = 'Boat_8',
  Boat_12 = 'Boat_12',
}

export const ROTATION_0 = 0
export const ROTATION_90 = 90
export const ROTATION_180 = 180
export const ROTATION_270 = 270
export const ROTATION_360 = 360

export enum MovableAxis {
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

export type EditableProperties = {
  booleanFields?: string[]
  useSorted?: boolean
  inputOrder?: string[]
  height?: number
  priority?: number
  asset?: TreasureAsset
  bulletCount?: number
  assetHitCountForDeath?: number
  movableAxis?: MovableAxis[]
  rotation?: number
  health?: number
  noOfWood?: number
  description?: string
  lasers?: { X: number; Y: number }[]
  destType: string
  destinationId: string
  philosophyPackId: string
  sixYearId?: string
}

export type ChallengeGameGridMetric = {
  id: string
  gridId: string
  userId: string
  grid: ChallengeGameDataGrid
  user: User
  createdAt: string
  updatedAt: string
  timeToComplete?: number
  retries?: number
  steps?: number
}

export type ChallengeGamePropsGenericType =
  | string
  | string[]
  | MovableAxis[]
  | TreasureAsset[]
  | number
  | boolean
  | MovableAxis
  | TreasureAsset
  | Record<string, any>
  | ChallengeGameItem[]

export const EDITABLE_PROPERTIES_MAP = {}

export const Layout_Button_Data = [
  {
    label: 'Eraser',
    description: 'Walls surrounding the area',
  },
  {
    label: 'Wall',
    key: LayoutButtonKeys.Wall,
    image: wall,
    props: { height: 3, movable: false },
    editableProps: { height: 3, movable: false },
    description: 'Walls surrounding the area',
  },
  {
    label: 'Player',
    key: LayoutButtonKeys.Player,
    image: male,
    props: {
      movable: true,
      movableAxis: [MovableAxis.X, MovableAxis.Y, MovableAxis.Z],
      rotation: ROTATION_0,
    },
    editableProps: { rotation: ROTATION_0 },
    description: 'Player interacting the area',
  },
  {
    label: 'Water',
    key: LayoutButtonKeys.Water,
    image: water,
    props: { causeDeath: true, asset: TreasureAsset.Boat_4 },
    editableProps: { asset: TreasureAsset.Boat_4 },
    description:
      'Water Surrounding the area, can be set to kill Player, can have boat as asset in it',
  },
  {
    label: 'Fire',
    key: LayoutButtonKeys.Fire,
    image: fire,
    props: { causeDeath: true },
    editableProps: {},
    description: 'Fire element , has property to cause death of player ',
  },
  {
    label: 'Tree',
    key: LayoutButtonKeys.Tree,
    image: tree,
    props: { removable: true, health: 4, noOfWood: 4 },
    editableProps: { health: 4, noOfWood: 4 },
    description: 'Tree element , can be used by player to make boat with axe asset ',
  },
  {
    label: 'Gem',
    key: LayoutButtonKeys.Gem,
    image: gem,
    props: { collectable: true },
    editableProps: {},
    description: 'Asset that player can collect, can be set to refill gun or player life, etc ',
  },
  {
    label: 'Treasure',
    key: LayoutButtonKeys.Treasure,
    image: treasure,
    props: { collectable: true, asset: TreasureAsset.Axe, rotation: ROTATION_0 },
    editableProps: { asset: TreasureAsset.Axe, rotation: ROTATION_0 },
    description: 'Treasure for player than can be kept in chest or in area',
  },
  {
    label: 'Key',
    key: LayoutButtonKeys.Key,
    image: key,
    props: { collectable: true },
    editableProps: {},
    description: 'Key to unlock home ',
  },
  {
    label: 'Shovel',
    key: LayoutButtonKeys.Shovel,
    image: shovel,
    props: { collectable: true },
    editableProps: {},
    description: 'Shovel to dig ground  ',
  },
  {
    label: 'Wood',
    key: LayoutButtonKeys.Wood,
    image: wood,
    props: { collectable: true },
    editableProps: {},
    description: 'Wood to make boat  ',
  },
  {
    label: 'Home',
    key: LayoutButtonKeys.Home,
    image: home,
    props: { locked: true, rotation: ROTATION_0 },
    editableProps: { rotation: ROTATION_0 },
    description: ' Players escape out way should be unlocked by key ',
  },
  {
    label: 'Laser',
    key: LayoutButtonKeys.Laser,
    image: laser,
    props: { collectable: true, refillable: true, bulletCount: 10 },
    editableProps: { count: 10 },
    description: 'Players asset, can be used to kill monster',
  },
  {
    label: 'Axe',
    key: LayoutButtonKeys.Axe,
    image: axe,
    props: { collectable: true },
    editableProps: {},
    description:
      'Player asset, can be used to cut tree to make boat and also can be set to kill Monster',
  },
  {
    label: 'Arrow',
    key: LayoutButtonKeys.Arrow,
    image: arrow,
    props: { causeDeath: true, rotation: ROTATION_0 },
    editableProps: { rotation: ROTATION_0 },
    description: 'Player gets killed once arrow touches player',
  },
  {
    label: 'Monster',
    key: LayoutButtonKeys.Monster,
    image: monster,
    props: {
      removable: true,
      movableAxis: [MovableAxis.X],
      removedByAsset: [TreasureAsset.Laser],
      assetHitCountForDeath: 2,
      rotation: ROTATION_0,
    },
    editableProps: { assetHitCountForDeath: 2, rotation: ROTATION_0, movableAxis: [MovableAxis.X] },
    description: 'Enemy guarding keys and assets ',
  },
  {
    label: 'Boat',
    key: LayoutButtonKeys.Boat,
    image: boat,
    props: {
      removable: true,
      movable: true,
      movableAxis: [MovableAxis.X, MovableAxis.Y],
      rotation: ROTATION_0,
      spawnPosition: 'AboveWater',
      noOfWood: 8,
    },
    editableProps: { rotation: ROTATION_0, noOfWood: 8 },
    description: 'Transportation to cross water ways ',
  },
  {
    label: 'Exit',
    key: LayoutButtonKeys.Exit,
    image: exit,
    props: { rotation: ROTATION_0 },
    editableProps: { rotation: ROTATION_0 },
    description: 'Exit',
  },
]

export const ItemSlug = {
  Laser: 'laser',
  LaserSwitch: 'laserswitch',
}

export const getLayoutButtonEditableProps = (cellKey: string) => {
  const creatorButton = Layout_Button_Data.find((button) => button.key === cellKey)
  return creatorButton?.editableProps
}

export const getLayoutButtonDescription = (cellKey: string): string => {
  const creatorButton = Layout_Button_Data.find((button) => button.key === cellKey)
  return creatorButton?.description
}

export const getLayoutButtonProperties = (cellKey: string) => {
  const creatorButton = Layout_Button_Data.find((button) => button.key === cellKey)
  return creatorButton?.props
}
