/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AccessTime,
  AdminPanelSettingsOutlined,
  AttachMoney as AttachMoneyIcon,
  BrightnessHighOutlined,
  DeleteForeverOutlined,
  Description,
  Engineering,
  ExtensionOutlined,
  FestivalOutlined,
  FilterVintageOutlined,
  FormatQuoteOutlined,
  GroupOutlined,
  HistoryToggleOff,
  HomeRepairService,
  HubOutlined,
  InsightsOutlined,
  LocationOn,
  LockOutlined,
  MailOutline,
  MenuBookOutlined,
  MilitaryTechOutlined,
  MonetizationOnOutlined,
  MovieFilter,
  OndemandVideoOutlined,
  PeopleOutline,
  SellOutlined,
  SlowMotionVideoOutlined,
  Source,
  TrendingUpOutlined,
  VideogameAsset,
  Wallpaper,
  WidgetsOutlined,
  DynamicFeed,
  People,
  FilterNone,
} from '@mui/icons-material'
import {
  LeaderboardIcon,
  MyAccountIcon,
  SettingsIcon,
  StadiaControllerIcon,
} from '@commons/modules/Icons/icons'
import ROUTES from '@constant/Routes'
import { MainMenuItem } from '@type/common'
import { RIGGED_ECONOMY_MENU_ITEMS } from 'configs/riggedEconomy'

import { IsHeroOnEarth, PROJECT } from '../configs'
import { CONGRESS_MEMBER_MENU_ITEMS } from '../configs/congressMember'

export const MENU_ITEMS_NAME = {
  STORY_ENGINE: 'Story Engine',
  AI: 'AI',
  USER_GENERATED_CONTENT: 'User Generated Content',
}

// ALL HERO ON EARTH specific routes menu items will go here.
const HERO_MENU_ITEMS: MainMenuItem[] = [
  {
    name: 'Quests',
    path: ROUTES.Quests,
    icon: <BrightnessHighOutlined />,
    admin: true,
  },
  {
    name: 'Buildings Tour',
    path: ROUTES.BuildingsTour,
    icon: <FestivalOutlined />,
    hideMenu: true,
  },
]

export const USER_GENERATED_STORY_ENGINE: MainMenuItem = {
  name: MENU_ITEMS_NAME.USER_GENERATED_CONTENT,
  path: ROUTES.UserGeneratedContent,
  showSubItems: false,
  newPageMenu: true,
  icon: <People />,
  subItems: [
    {
      name: 'Dream Runs',
      path: ROUTES.UGCDreamRun,
      icon: <StadiaControllerIcon />,
    },
    {
      name: 'Dream Run Details',
      path: ROUTES.UGCDreamRunDetails,
      hideMenu: true,
    },
    {
      name: 'Dream Run Edit',
      path: ROUTES.UGCDreamRunEdit,
      hideMenu: true,
    },
    {
      name: 'Dream Run Level Editor',
      path: ROUTES.UGCDreamRunLevelEditor,
      hideMenu: true,
    },
    {
      name: 'Six Year Strategies',
      path: ROUTES.UGCSixYear,
      icon: <InsightsOutlined />,
    },
    {
      name: 'Six Year Strategy Details',
      path: ROUTES.UGCSixYearDetails,
      hideMenu: true,
    },
    {
      name: 'Six Year Strategy Edit',
      path: ROUTES.UGCSixYearEdit,
      hideMenu: true,
    },
    {
      name: 'Six Year Level Editor',
      path: ROUTES.UGCSixYearLevelEditor,
      hideMenu: true,
    },
    {
      name: 'Philosophy Packs',
      path: ROUTES.UGCPhilPack,
      icon: <FilterNone />,
    },
    {
      name: 'Philosophy Pack Details',
      path: ROUTES.UGCPhilPackDetails,
      hideMenu: true,
    },
    {
      name: 'Philosophy Pack Edit',
      path: ROUTES.UGCPhilPackEdit,
      hideMenu: true,
    },
  ],
}

export const STORY_ENGINE_ITEM: MainMenuItem = {
  name: MENU_ITEMS_NAME.STORY_ENGINE,
  path: ROUTES.StoryEngine,
  showSubItems: false,
  newPageMenu: true,
  icon: <Engineering />,
  subItems: [
    {
      name: 'Content Types',
      path: ROUTES.ContentTypes,
      icon: <Description />,
    },
    {
      name: 'Content Type',
      path: ROUTES.ContentType,
      hideMenu: true,
    },
    {
      name: 'Add Content Types',
      path: ROUTES.AddContentTypes,
      hideMenu: true,
    },
    {
      name: 'Edit Content Types',
      path: ROUTES.EditContentType,
      hideMenu: true,
    },
    {
      name: 'Content Packs(Games)',
      path: ROUTES.ContentPacks,
      icon: <VideogameAsset />,
      subItems: [],
      hideMenu: true,
    },
    {
      name: 'Phases',
      path: ROUTES.Phases,
      icon: <Description />,
    },
    {
      name: 'Phase',
      path: ROUTES.Phase,
      hideMenu: true,
    },
    {
      name: 'Add Phases',
      path: ROUTES.AddPhases,
      hideMenu: true,
    },
    {
      name: 'Items',
      path: ROUTES.Items,
      icon: <Description />,
      showSubItems: true,
      subItems: [
        {
          name: 'Categories',
          icon: <PeopleOutline />,
          path: ROUTES.ItemAreas,
        },
        {
          name: 'Item Area Detail',
          path: ROUTES.ItemAreaDetail,
          hideMenu: true,
        },
        {
          name: 'Add Item Area',
          path: ROUTES.ItemAreasAdd,
          hideMenu: true,
        },
        {
          name: 'Update Item Area',
          path: ROUTES.ItemAreasEdit,
          hideMenu: true,
        },
      ],
    },
    {
      name: 'Voting Factors',
      path: ROUTES.VotingFactors,
      icon: <Description />,
    },
    {
      name: 'Add Voting Factor ',
      path: ROUTES.AddVotingFactor,
      hideMenu: true,
    },
    {
      name: 'Edit Voting Factor ',
      path: ROUTES.EditVotingFactor,
      hideMenu: true,
    },
    {
      name: 'Voting Factor Detail',
      path: ROUTES.VotingFactor,
      hideMenu: true,
    },
    {
      name: 'Voting Factor Result',
      path: ROUTES.VotingFactorResult,
      hideMenu: true,
    },
    {
      name: 'Templates',
      path: ROUTES.Templates,
      icon: <HubOutlined />,
    },
    {
      name: 'Add Template',
      path: ROUTES.AddTemplate,
      hideMenu: true,
    },
    {
      name: 'Edit Template',
      path: ROUTES.EditTemplate,
      hideMenu: true,
    },
    {
      name: 'Template Detail',
      path: ROUTES.Template,
      hideMenu: true,
    },
    {
      name: 'Item',
      path: ROUTES.Item,
      hideMenu: true,
    },
    {
      name: 'Add Item',
      path: ROUTES.AddItem,
      hideMenu: true,
    },
    {
      name: 'Edit Item',
      path: ROUTES.ItemEdit,
      hideMenu: true,
    },
    {
      name: 'Surfing Sections',
      path: ROUTES.SurfingSections,
      icon: <Description />,
    },
    {
      name: 'Surfing Section',
      path: ROUTES.SurfingSection,
      hideMenu: true,
    },
    {
      name: 'Add Surfing Sections',
      path: ROUTES.AddSurfingSection,
      hideMenu: true,
    },
    {
      name: 'Edit Surfing Sections',
      path: ROUTES.EditSurfingSection,
      hideMenu: true,
    },
    {
      name: 'Scenes',
      path: ROUTES.Scenes,
      icon: <MovieFilter />,
    },
    {
      name: 'Add Scenes',
      path: ROUTES.AddScenes,
      hideMenu: true,
    },
    {
      name: 'Scenes Detail',
      path: ROUTES.ScenesDetail,
      hideMenu: true,
    },
    {
      name: 'Scene Political Check',
      path: ROUTES.ScenePoliticalCheck,
      hideMenu: true,
    },
    {
      name: 'Scene Dialogue Detail',
      path: ROUTES.SceneDialogueDetail,
      hideMenu: true,
    },
    {
      name: 'Edit Scenes',
      path: ROUTES.EditScenes,
      hideMenu: true,
    },
    {
      name: 'Edit Scenes',
      path: ROUTES.EditScenes,
      hideMenu: true,
    },
    {
      name: 'Add New Scene IFTTT',
      path: ROUTES.StoryEngineSceneIFTTTAdd,
      hideMenu: true,
    },
    {
      name: 'Edit Scene IFTTT',
      path: ROUTES.StoryEngineSceneIFTTTEdit,
      hideMenu: true,
    },
    {
      name: 'Add New Dialogue IFTTT',
      path: ROUTES.StoryEngineSceneDialogueIFTTTAdd,
      hideMenu: true,
    },
    {
      name: 'Edit Dialogue IFTTT',
      path: ROUTES.StoryEngineSceneDialogueIFTTTEdit,
      hideMenu: true,
    },
    {
      name: 'Characters',
      path: ROUTES.StoryEngineCharacters,
      icon: <PeopleOutline />,
      showSubItems: true,
      subItems: [
        {
          name: 'Categories',
          icon: <PeopleOutline />,
          path: ROUTES.CharacterAreas,
        },
        {
          name: 'Character Area Detail',
          path: ROUTES.CharacterAreaDetail,
          hideMenu: true,
        },
        {
          name: 'Add Character Area',
          path: ROUTES.CharacterAreasAdd,
          hideMenu: true,
        },
        {
          name: 'Update Character Area',
          path: ROUTES.CharacterAreasEdit,
          hideMenu: true,
        },
      ],
    },
    {
      name: 'Character',
      path: ROUTES.StoryEngineCharacterAdd,
      hideMenu: true,
    },
    {
      name: 'Character Detail',
      path: ROUTES.StoryEngineCharacter,
      hideMenu: true,
    },
    {
      name: 'Character Edit',
      path: ROUTES.StoryEngineCharacterEdit,
      hideMenu: true,
    },

    {
      name: 'Places',
      path: ROUTES.Places,
      icon: <Wallpaper />,
      showSubItems: true,
      subItems: [
        {
          name: 'Categories',
          icon: <PeopleOutline />,
          path: ROUTES.PlacesAreas,
        },
        {
          name: 'Places Area Detail',
          path: ROUTES.PlacesAreaDetail,
          hideMenu: true,
        },
        {
          name: 'Add Places Area',
          path: ROUTES.PlacesAreasAdd,
          hideMenu: true,
        },
        {
          name: 'Update Places Area',
          path: ROUTES.PlacesAreasEdit,
          hideMenu: true,
        },
      ],
    },
    {
      name: 'Add Place',
      path: ROUTES.AddPlace,
      hideMenu: true,
    },

    {
      name: 'Edit Place',
      path: ROUTES.EditPlace,
      hideMenu: true,
    },

    {
      name: 'Place Detail',
      path: ROUTES.Place,
      hideMenu: true,
    },

    {
      name: 'Add Scene Choices',
      path: ROUTES.AddSceneChoice,
      hideMenu: true,
    },
    {
      name: 'Scene Choice',
      path: ROUTES.SceneChoice,
      hideMenu: true,
    },
    {
      name: 'Add Dialogue Choices',
      path: ROUTES.AddDialogueChoice,
      hideMenu: true,
    },
    {
      name: 'Edit Dialogue Choices',
      path: ROUTES.EditDialogue,
      hideMenu: true,
    },
    {
      name: 'Dialogue Choice',
      path: ROUTES.DialogueChoice,
      hideMenu: true,
    },
    {
      name: 'Transitions',
      icon: <SlowMotionVideoOutlined />,
      path: ROUTES.Transitions,
      showSubItems: true,
      subItems: [
        {
          name: 'Video Generation',
          icon: <AccessTime />,
          path: ROUTES.UnrealVideoGeneration,
        },
      ],
    },
    {
      name: 'Create Transition',
      path: ROUTES.CreateTransitions,
      hideMenu: true,
    },
    {
      name: 'Transition Detail',
      path: ROUTES.ViewTransitions,
      hideMenu: true,
    },
    {
      name: 'Edit Transition',
      path: ROUTES.EditTransitions,
      hideMenu: true,
    },
    {
      name: 'Areas',
      path: ROUTES.Areas,
      icon: <LocationOn />,
    },
    {
      name: 'Add Areas',
      path: ROUTES.AddAreas,
      hideMenu: true,
    },
    {
      name: 'Areas Detail',
      path: ROUTES.AreasDetail,
      hideMenu: true,
    },
    {
      name: 'Edit Areas Detail',
      path: ROUTES.EditAreas,
      hideMenu: true,
    },
    {
      name: 'Area Scene Detail',
      path: ROUTES.AreasScenesDetail,
      hideMenu: true,
    },
    {
      name: 'Area Scene Dialogue Detail',
      path: ROUTES.AreasScenesDialogueDetail,
      hideMenu: true,
    },
    {
      name: 'Dream Runs',
      path: ROUTES.ChallengeGame,
      showSubItems: true,
      icon: <StadiaControllerIcon />,
      subItems: [
        {
          name: 'Levels',
          icon: <DynamicFeed />,
          path: ROUTES.ChallengeGameLevels,
        },
        {
          name: 'Levels Edit',
          path: ROUTES.ChallengeGameLevelsEdit,
          hideMenu: true,
        },
      ],
    },
    {
      name: 'Dream Run Voting Results',
      path: ROUTES.ChallengeGameVotingResults,
      hideMenu: true,
    },
    {
      name: 'Dream Run Voting Result Details',
      keepParentMenuSelected: true,
      path: ROUTES.ChallengeGameVotingResultDetails,
      hideMenu: true,
    },
    {
      name: 'Dream Run List',
      path: ROUTES.ChallengeGameList,
      hideMenu: true,
    },
    {
      name: 'Dream Run Detail',
      path: ROUTES.ChallengeGameDetail,
      hideMenu: true,
    },
    {
      name: 'Dream Run Add New',
      path: ROUTES.ChallengeGameAddNew,
      hideMenu: true,
    },
    {
      name: 'Dream Run Add Level Map',
      path: ROUTES.ChallengeGameAddLevelMap,
      hideMenu: true,
    },
    {
      name: 'Dream Run Level Editor',
      path: ROUTES.ChallengeGameNewEditor,
      hideMenu: true,
    },
    {
      name: 'Main Progress',
      path: ROUTES.MainProgress,
      icon: <TrendingUpOutlined />,
    },
    {
      name: 'Main Progress Detail',
      path: ROUTES.MainProgressDetail,
      hideMenu: true,
    },
    {
      name: 'Main Progress Add New',
      path: ROUTES.MainProgressAddNew,
      hideMenu: true,
    },
    {
      name: 'Main Progress Add Level Map',
      path: ROUTES.MainProgressAddLevelMap,
      hideMenu: true,
    },
    {
      name: 'Six Year Strategies',
      path: ROUTES.SixYearStrategy,
      icon: <InsightsOutlined />,
    },
    {
      name: 'Six Year Strategy Detail',
      path: ROUTES.SixYearStrategyDetail,
      hideMenu: true,
    },
    {
      name: 'Six Year Strategy Add New',
      path: ROUTES.SixYearStrategyAddNew,
      hideMenu: true,
    },
    {
      name: 'Six Year Strategy Add Level Map',
      path: ROUTES.SixYearStrategyAddLevelMap,
      hideMenu: true,
    },
    {
      name: 'Six Year Voting Results',
      path: ROUTES.SixYearStrategyVotingResults,
      hideMenu: true,
    },
    {
      name: 'Six Year Voting Result Details',
      path: ROUTES.SixYearStrategyVotingResultDetails,
      hideMenu: true,
    },
    {
      name: 'Quests',
      icon: <FilterVintageOutlined />,
      path: ROUTES.AdminAiQuest,
      showSubItems: true,
      subItems: [
        {
          name: 'Buildings Tour',
          icon: <FestivalOutlined />,
          hideMenu: true,
        },
        {
          name: 'Generic Phases',
          path: ROUTES.AdminQuestGenericPhases,
          hideMenu: true,
        },
        {
          name: 'Add Quest',
          path: ROUTES.AdminAiQuestCreate,
          hideMenu: true,
        },
        {
          name: 'Quest Details',
          path: ROUTES.AdminAiQuestDetails,
          hideMenu: true,
        },
        {
          name: 'Update Quest',
          path: ROUTES.AdminAiQuestEdit,
          hideMenu: true,
        },
        {
          name: 'Quest Jobs',
          icon: <AccessTime />,
          path: ROUTES.AdminAiQuestJobs,
        },
        {
          name: 'Github Directory',
          path: ROUTES.GithubFile,
          hideMenu: true,
        },
      ],
    },
    {
      name: 'Game Badges',
      icon: <MilitaryTechOutlined />,
      path: ROUTES.GameBadges,
      showSubItems: true,
      subItems: [
        {
          name: 'Game Goals',
          path: ROUTES.GameGoals,
          icon: <FestivalOutlined />,
        },
        {
          name: 'Game Goal Details',
          path: ROUTES.GameGoalDetails,
          hideMenu: true,
        },
        {
          name: 'Edit Game Goal',
          path: ROUTES.EditGameGoal,
          hideMenu: true,
        },
        {
          name: 'Add Game Goal',
          path: ROUTES.AddGameGoal,
          hideMenu: true,
        },
      ],
    },
    {
      name: 'Game Badge Details',
      path: ROUTES.GameBadgeDetails,
      hideMenu: true,
    },
    {
      name: 'Game Badge Edit',
      path: ROUTES.EditGameBadge,
      hideMenu: true,
    },
    {
      name: 'Game Badge Details',
      path: ROUTES.AddBadge,
      hideMenu: true,
    },

    // {
    //   name: 'Buildings',
    //   path: ROUTES.BuildingsPlay,
    //   icon: <LocationCity />,
    //   showSubItems: false,
    //   newPageMenu: true,
    //   legacy: true,
    //   subItems: [
    //     {
    //       name: 'Buildings (Play)',
    //       path: ROUTES.BuildingsPlay,
    //       icon: <BuildingPlayIcon />,
    //     },
    //     {
    //       name: 'Buildings (Build)',
    //       path: ROUTES.BuildingsBuild,
    //       icon: <BuildingBuildIcon />,
    //     },
    //     {
    //       name: 'Buildings (Vote)',
    //       path: ROUTES.BuildingsVote,
    //       icon: <BuildingVoteIcon />,
    //     },
    //     {
    //       name: 'Building Details',
    //       path: ROUTES.BuildingsDetails,
    //       hideMenu: true,
    //     },
    //     {
    //       name: 'Building Details Path',
    //       path: ROUTES.BuildingsDetailsPath,
    //       hideMenu: true,
    //     },
    //     {
    //       name: 'Plot for Vote',
    //       path: ROUTES.PlotForVotes,
    //       hideMenu: true,
    //     },
    //     {
    //       name: 'Plot Vote List',
    //       path: ROUTES.PlotVotesList,
    //       hideMenu: true,
    //     },
    //     {
    //       name: 'Creator Vote Details',
    //       path: ROUTES.CreatorVoteDetaills,
    //       hideMenu: true,
    //     },
    //     {
    //       name: 'Creater Submission',
    //       path: ROUTES.CreatorSubmission,
    //       hideMenu: true,
    //     },
    //     {
    //       name: 'Creators Voting',
    //       path: ROUTES.CreatorsVoting,
    //       hideMenu: true,
    //     },
    //   ],
    // },
    {
      name: 'TikTok And YouTube Videos',
      path: ROUTES.TiktokAndYoutubeVideos,
      icon: <OndemandVideoOutlined />,
    },
    {
      name: 'TikTok And YouTube Video Detail',
      path: ROUTES.TiktokAndYoutubeVideoDetail,
      hideMenu: true,
    },
    {
      name: 'Edit TikTok And YouTube Video',
      path: ROUTES.TiktokAndYoutubeVideoEdit,
      hideMenu: true,
    },
    {
      name: 'Add TikTok And YouTube Videos',
      path: ROUTES.AddTiktokAndYoutubeVideo,
      hideMenu: true,
    },
    {
      name: 'Quotes',
      path: ROUTES.Quotes,
      icon: <FormatQuoteOutlined />,
    },
    {
      name: 'Quote Detail',
      path: ROUTES.QuoteDetail,
      hideMenu: true,
    },
    {
      name: 'Edit Quote',
      path: ROUTES.QuoteEdit,
      hideMenu: true,
    },
    {
      name: 'Add Quotes',
      path: ROUTES.AddQuote,
      hideMenu: true,
    },
    // {
    //   name: '2D Web Game',
    //   path: ROUTES.AdminMyGame,
    //   showSubItems: false,
    //   newPageMenu: true,
    //   legacy: true,
    //   icon: <StadiaControllerIcon />,
    //   subItems: [
    //     {
    //       name: 'Game Play',
    //       icon: <SmartToyOutlined />,
    //       path: ROUTES.GameDashBoard,
    //     },
    //     {
    //       name: '2D Game',
    //       icon: <VideogameAssetOutlined />,
    //       path: ROUTES.AdminMyGame,
    //     },
    //     {
    //       name: 'Transitions',
    //       icon: <SlowMotionVideoOutlined />,
    //       path: ROUTES.Transitions,
    //       showSubItems: false,
    //       subItems: [
    //         {
    //           name: 'Add Video',
    //           path: ROUTES.CreateTransitions,
    //           hideMenu: true,
    //         },
    //         {
    //           name: 'Edit Video',
    //           path: ROUTES.EditVideos,
    //           hideMenu: true,
    //         },
    //       ],
    //     },
    //     {
    //       name: 'Tours',
    //       icon: <DesktopMacOutlined />,
    //       path: ROUTES.AdminTour,
    //     },
    //   ],
    // },
  ],
}

export const ADMIN_ITEM: MainMenuItem = {
  name: 'Admin',
  path: ROUTES.AdminMain,
  icon: <AdminPanelSettingsOutlined />,
  showSubItems: false,
  admin: true,
  subItems: [
    {
      name: 'AI',
      path: ROUTES.AICenter,
      icon: <HubOutlined />,
      showSubItems: false,
      newPageMenu: true,
      subItems: [
        {
          name: 'AIGenerated Dream Runs',
          path: ROUTES.AIGeneratedChallengeGames,
          icon: <HubOutlined />,
        },
        {
          name: 'Add AIGenerated Dream Run',
          path: ROUTES.AddAIGeneratedChallengeGame,
          hideMenu: true,
        },
        {
          name: 'Edit AIGenerated Dream Run',
          path: ROUTES.AIGeneratedChallengeGameEdit,
          hideMenu: true,
        },
        {
          name: 'AIGenerated Dream Run Extract Content',
          path: ROUTES.AIGeneratedChallengeGameExtractContent,
          hideMenu: true,
        },
        {
          name: 'AIGenerated Dream Run Detail',
          path: ROUTES.AIGeneratedChallengeGameDetail,
          hideMenu: true,
        },
        {
          name: 'AIGenerated Dream Run Interactive Detail',
          path: ROUTES.AIGeneratedChallengeGameInteractiveDetail,
          hideMenu: true,
        },
        {
          name: 'Themes',
          path: ROUTES.Themes,
          icon: <HubOutlined />,
        },
        {
          name: 'Add Theme',
          path: ROUTES.AddTheme,
          hideMenu: true,
        },
        {
          name: 'Edit Theme',
          path: ROUTES.ThemeEdit,
          hideMenu: true,
        },
        {
          name: 'Theme Detail',
          path: ROUTES.ThemeDetail,
          hideMenu: true,
        },
        {
          name: 'Assembly Mission Runs',
          path: ROUTES.AssemblyMissionRuns,
          icon: <HubOutlined />,
        },
        {
          name: 'Assembly Stages',
          path: ROUTES.AssemblyPasses,
          icon: <HubOutlined />,
        },
        {
          name: 'Add Assembly Stage',
          path: ROUTES.AddAssemblyPass,
          hideMenu: true,
        },
        {
          name: 'Edit Assembly Stage',
          path: ROUTES.AssemblyPassEdit,
          hideMenu: true,
        },
        {
          name: 'Assembly Stage Detail',
          path: ROUTES.AssemblyPassDetail,
          hideMenu: true,
        },
        {
          name: 'Assembly Mission Detail',
          path: ROUTES.AssemblyMissionDetail,
          hideMenu: true,
        },
        {
          name: 'Chapters',
          path: ROUTES.Chapters,
          icon: <HubOutlined />,
        },
        {
          name: 'Add Chapter',
          path: ROUTES.AddChapter,
          hideMenu: true,
        },
        {
          name: 'Edit Chapter',
          path: ROUTES.ChapterEdit,
          hideMenu: true,
        },
        {
          name: 'Chapter Detail',
          path: ROUTES.ChapterDetail,
          hideMenu: true,
        },
        {
          name: 'Missions',
          path: ROUTES.Missions,
          icon: <HubOutlined />,
        },
        {
          name: 'Add Mission',
          path: ROUTES.AddMission,
          hideMenu: true,
        },
        {
          name: 'Edit Mission',
          path: ROUTES.MissionEdit,
          hideMenu: true,
        },
        {
          name: 'Mission Detail',
          path: ROUTES.MissionDetail,
          hideMenu: true,
        },
        {
          name: 'Mission Generate Via AI',
          path: ROUTES.MissionGenerateViaAI,
          hideMenu: true,
        },
        {
          name: 'Questionnaires',
          path: ROUTES.Questionnaires,
          icon: <HubOutlined />,
          hideMenu: true, // TODO: remove this when we have the UI
        },
        {
          name: 'Add Questionnaire',
          path: ROUTES.AddQuestionnaire,
          hideMenu: true,
        },
        {
          name: 'Edit Questionnaire',
          path: ROUTES.QuestionnaireEdit,
          hideMenu: true,
        },
        {
          name: 'Questionnaire Detail',
          path: ROUTES.QuestionnaireDetail,
          hideMenu: true,
        },
        {
          name: 'Major Areas',
          path: ROUTES.AdminMajorAreas,
          icon: <WidgetsOutlined />,
          hideMenu: true,
        },
        {
          name: 'Major Areas',
          path: ROUTES.AdminAddMajorArea,
          hideMenu: true,
        },
        {
          name: 'Major Area Detail',
          path: ROUTES.AdminMajorArea,
          hideMenu: true,
        },
        {
          name: 'Policies',
          path: ROUTES.Policies,
          icon: <WidgetsOutlined />,
        },
        {
          name: 'Add Policy',
          path: ROUTES.AddPolicies,
          hideMenu: true,
        },
        {
          name: 'Policy Detail',
          path: ROUTES.PoliciesDetail,
          hideMenu: true,
        },
        {
          name: 'Needed Prompts',
          path: ROUTES.AdminNeededPrompts,
          hideMenu: true,
          icon: <WidgetsOutlined />,
        },
        {
          name: 'Needed Prompt',
          path: ROUTES.AdminAddNeededPrompt,
          hideMenu: true,
        },
        {
          name: 'Needed Prompt Detail',
          path: ROUTES.AdminNeededPrompt,
          hideMenu: true,
        },
        {
          name: 'Prompt Variables',
          path: ROUTES.AdminPromptVariables,
          icon: <WidgetsOutlined />,
        },
        {
          name: 'Prompt Variable',
          path: ROUTES.AdminPromptVariable,
          hideMenu: true,
        },
        {
          name: 'Add Prompt Variable',
          path: ROUTES.AdminAddPromptVariable,
          hideMenu: true,
        },
        {
          name: 'AI Details',
          path: ROUTES.AIDetails,
          icon: <HubOutlined />,
        },
        {
          name: 'Add AI Details',
          path: ROUTES.AddAIDetails,
          icon: <HubOutlined />,
          hideMenu: true,
        },
        {
          name: 'AI Detail',
          path: ROUTES.AIDetailsDetail,
          icon: <HubOutlined />,
          hideMenu: true,
        },
        {
          name: 'Edit AI Details',
          path: ROUTES.EditAIDetails,
          icon: <HubOutlined />,
          hideMenu: true,
        },
        {
          name: 'Add AI Detail Hard Fact',
          path: ROUTES.AddAIDetailsHardFacts,
          icon: <HubOutlined />,
          hideMenu: true,
        },
        {
          name: 'Edit AI Details Hard Fact',
          path: ROUTES.AIDetailsHardFactsDetail,
          icon: <HubOutlined />,
          hideMenu: true,
        },
        {
          name: 'Books',
          path: ROUTES.AdminBooks,
          icon: <MenuBookOutlined />,
        },
        {
          name: 'Book',
          path: ROUTES.AdminAddBook,
          hideMenu: true,
        },
        {
          name: 'Book Detail',
          path: ROUTES.AdminBook,
          hideMenu: true,
        },
        {
          name: 'Curriculums',
          path: ROUTES.Curriculums,
          hideMenu: true,
          icon: <WidgetsOutlined />,
        },
        {
          name: 'Curriculum',
          path: ROUTES.AdminGenerateCurriculum,
          hideMenu: true,
        },
        {
          name: 'Curriculum Detail',
          path: ROUTES.CurriculumDetail,
          hideMenu: true,
        },
        {
          name: 'Characters',
          path: ROUTES.Characters,
          icon: <PeopleOutline />,
        },
        {
          name: 'Character',
          path: ROUTES.AddCharacter,
          hideMenu: true,
        },
        {
          name: 'Character Detail',
          path: ROUTES.CharacterDetail,
          hideMenu: true,
        },
        {
          name: 'Character Edit',
          path: ROUTES.CharacterEdit,
          hideMenu: true,
        },
        {
          name: 'Places',
          path: ROUTES.BackgroundImages,
          icon: <Wallpaper />,
        },
        {
          name: 'Add Places',
          path: ROUTES.AddBackgroundImage,
          hideMenu: true,
        },
        {
          name: 'Place Detail',
          path: ROUTES.BackgroundImageDetail,
          hideMenu: true,
        },
        {
          name: 'Land Lock Puzzles',
          path: ROUTES.LandLockPuzzle,
          icon: <ExtensionOutlined />,
        },
        {
          name: 'Land Lock Puzzle Detail',
          path: ROUTES.LandLockPuzzleDetail,
          hideMenu: true,
        },
        {
          name: 'Add Land Lock Puzzle',
          path: ROUTES.AddLandLockPuzzle,
          hideMenu: true,
        },
        {
          name: 'LandLock Puzzle Level Detail',
          path: ROUTES.LandLockPuzzleLevelDetail,
          hideMenu: true,
        },
      ],
    },
    STORY_ENGINE_ITEM,
    USER_GENERATED_STORY_ENGINE,
    {
      name: 'Internet Apps',
      path: ROUTES.Fixes,
      icon: <HomeRepairService />,
      // subItems: [
      // RIGGED_ECONOMY_MENU_ITEMS, // keep it hidden as we won't need this here as of now
      // CONGRESS_MENU_ITEMS,
      // WORK_DEFENSE_MENU_ITEMS,
      // RIGGED_MEDICAL_MENU_ITEMS,
      // ],
    },
    {
      name: 'Our Players',
      icon: <GroupOutlined />,
      path: ROUTES.Users,
      showSubItems: false,
      keepParentMenuSelected: true,
      subItems: [
        {
          name: 'Edit User Details',
          path: ROUTES.AdminUserEdit,
          hideMenu: true,
        },
        {
          name: 'User Details',
          path: ROUTES.AdminUserDetail,
          hideMenu: true,
        },
        {
          name: 'Public User Details',
          path: ROUTES.PublicPlayerDetail,
          hideMenu: true,
        },
        {
          name: 'Invite User',
          path: ROUTES.InviteUser,
          hideMenu: true,
        },
        {
          name: 'User Game Info',
          path: ROUTES.UserGameInfo,
          hideMenu: true,
        },
        {
          name: 'Dream Run Metrics',
          path: ROUTES.DreamRunMetrics,
          hideMenu: true,
        },
        {
          name: 'Flow Rate Metrics',
          path: ROUTES.FlowRateMetrics,
          hideMenu: true,
        },
        {
          name: 'Next Quest Metrics',
          path: ROUTES.NextQuestMetrics,
          hideMenu: true,
        },
        {
          name: 'Gems Usage Metrics',
          path: ROUTES.GemsUsageMetrics,
          hideMenu: true,
        },
        {
          name: 'Value Engine Metrics',
          path: ROUTES.ValueEngineMetrics,
          hideMenu: true,
        },
        {
          name: 'Factor Voting Metrics',
          path: ROUTES.FactorVotingMetrics,
          hideMenu: true,
        },
        {
          name: 'Decision Voting Metrics',
          path: ROUTES.DecisionVotingMetrics,
          hideMenu: true,
        },
        {
          name: 'Philosophy Pack Metrics',
          path: ROUTES.PhilosophyPackMetrics,
          hideMenu: true,
        },
        {
          name: 'Payment Metrics',
          path: ROUTES.PaymentMetrics,
          hideMenu: true,
        },
      ],
    },
    {
      name: 'Revision Control',
      icon: <HistoryToggleOff />,
      path: ROUTES.RevisionControl,
      showSubItems: false,
      keepParentMenuSelected: true,
      subItems: [
        {
          name: 'Revision Details',
          path: ROUTES.RevisionControlDetail,
          hideMenu: true,
        },
      ],
    },
  ],
}

const PLATFORM_MENU_ITEM: MainMenuItem[] = [
  {
    name: 'Platforms',
    icon: <Source />,
    path: ROUTES.InternetAppPlatforms,
  },
  {
    name: 'Add Platforms',
    hideMenu: true,
    path: ROUTES.InternetAppAddPlatform,
  },
  {
    name: 'Edit Platforms',
    hideMenu: true,
    path: ROUTES.InternetAppPlatform,
  },
]

export const FIXES_MENU_ITEM: MainMenuItem = {
  name: 'Fixes',
  path: ROUTES.Fixes,
  icon: <HomeRepairService />,
  subItems: [
    RIGGED_ECONOMY_MENU_ITEMS,
    CONGRESS_MEMBER_MENU_ITEMS,
    // WORK_DEFENSE_MENU_ITEMS,
    // RIGGED_MEDICAL_MENU_ITEMS,
    // ...PLATFORM_MENU_ITEM,
  ],
}

export const USER_STORY_ENGINE: MainMenuItem = {
  name: 'Story Engine',
  showOnlyUserRole: true,
  path: ROUTES.UserStoryEngine,
  icon: <Engineering />,
  showSubItems: false,
  subItems: [
    {
      name: 'TikTok And YouTube Video',
      path: ROUTES.UserTiktokAndYoutubeVideos,
      icon: <OndemandVideoOutlined />,
    },
    {
      name: 'Add TikTok And YouTube Video',
      path: ROUTES.AddUserTiktokAndYoutubeVideo,
      hideMenu: true,
    },
    {
      name: 'Edit TikTok And YouTube Video',
      path: ROUTES.UserTiktokAndYoutubeVideoDetail,
      hideMenu: true,
    },
    {
      name: 'Dream Runs',
      path: ROUTES.UserDreamRuns,
      icon: <StadiaControllerIcon />,
    },
    {
      name: 'User Dream Run Details',
      path: ROUTES.UserDreamRunDetail,
      hideMenu: true,
    },
    {
      name: 'User Dream Run Level Editor',
      path: ROUTES.UserDreamRunNewEditor,
      hideMenu: true,
    },
    {
      name: 'Scenes',
      path: ROUTES.UserScenes,
      icon: <MovieFilter />,
    },
    {
      name: 'UGG SceneDetails',
      path: ROUTES.UserSceneDetails,
      hideMenu: true,
    },
    {
      name: 'Six Year Strategy',
      path: ROUTES.UserSixYear,
      icon: <InsightsOutlined />,
    },
    {
      name: 'User Six Year Strategy Details',
      path: ROUTES.UserSixYearDetail,
      hideMenu: true,
    },
    {
      name: 'User Six Year Strategy Level Editor',
      path: ROUTES.UserSixYearNewEditor,
      hideMenu: true,
    },
  ],
}

const HERO_SETTING_SUBMENU: MainMenuItem[] = [
  {
    name: 'Billings',
  },
  {
    name: 'Plans',
    path: ROUTES.SettingBillingPlans,
    icon: <SellOutlined />,
  },
  {
    name: 'Subscriptions',
    path: ROUTES.SettingBillingSubscriptions,
    icon: <MonetizationOnOutlined />,
  },
  {
    name: 'Payment History',
    path: ROUTES.SettingBillingPaymentHistory,
    icon: <MailOutline />,
  },
  {
    name: 'Payment Methods',
    path: ROUTES.SettingPaymentMethod,
    icon: <SettingsIcon />,
  },
  {
    name: 'Payment Method Detail',
    path: ROUTES.PaymentMethodDetail,
    hideMenu: true,
  },

  {
    name: 'Add Payment Method',
    path: ROUTES.AddPaymentMethod,
    hideMenu: true,
  },
]

export const SETTINGS_MENU_ITEM: MainMenuItem = {
  name: 'Settings',
  path: ROUTES.Settings,
  icon: <SettingsIcon />,
  showSubItems: false,
  subItems: [
    {
      name: 'Settings',
    },
    {
      name: 'My Profile',
      path: ROUTES.SettingsMyProfile,
      icon: <MyAccountIcon />,
    },
    {
      name: 'Password',
      path: ROUTES.SettingsChangePassword,
      icon: <LockOutlined />,
    },
    {
      name: 'Change Email',
      path: ROUTES.SettingsChangeEmail,
      icon: <MailOutline />,
    },
    {
      name: 'Delete Account',
      path: ROUTES.SettingsDeleteAlc,
      icon: <DeleteForeverOutlined />,
    },
    ...(IsHeroOnEarth() ? HERO_SETTING_SUBMENU : []),
  ],
}

const MAIN_MENU_ITEMS: MainMenuItem[] = PROJECT.fixesMenuItems
  ? [...(PROJECT.fixesMenuItems as MainMenuItem).subItems]
  : [
      {
        name: 'Play Center',
        path: ROUTES.PlayCenter,
        icon: <StadiaControllerIcon />,
      },
      ...(IsHeroOnEarth() ? HERO_MENU_ITEMS : []),

      {
        name: 'Leaderboard',
        path: ROUTES.Leaderboard,
        icon: <LeaderboardIcon />,
      },
      // STORY_ENGINE_ITEM,
      // Hidden for now
      // {
      //   name: 'My Game',
      //   path: ROUTES.MyGame,
      //   icon: <StadiaControllerIcon />,
      // },
      // {
      //   name: 'Cities',
      //   path: ROUTES.AdventurePaths,
      //   icon: <ConversionPathIcon />,
      // },
      ADMIN_ITEM, // only admins are allowed
      // FIXES_MENU_ITEM, // hiding fixes from main nav for now as we have rigged economy already in landing page for our customer
      USER_STORY_ENGINE,
      {
        name: 'My Profile',
        path: ROUTES.MyAccount,
        icon: <MyAccountIcon />,
      },
      SETTINGS_MENU_ITEM,
      {
        name: 'Payment',
        path: ROUTES.Payment,
        icon: <AttachMoneyIcon />,
      },
    ]

const USER_MENU_ITEMS: MainMenuItem[] = []

export { MAIN_MENU_ITEMS, USER_MENU_ITEMS }
