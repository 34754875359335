/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/prefer-default-export */
import axios, { AxiosInstance } from 'axios'
import qs from 'qs'
import { API_URL, RATE_API, STRIPE_API_URL, STRIPE_HEADERS } from '@constant'
import { Plot } from '@type/schemas/PlotSchema'
import { CurrentUser } from '@type/user'
import {
  AddVote,
  BidSubmitDto,
  PaymentIntentDto,
  RegisterUserDto,
  UpdatePaymentIntentDto,
  UpdateUserDto,
  UpdateUserProfileDto,
} from 'types/dto'
import { TrustedDomains } from '@constant/SSO'
import { emitEvent } from '@util/event'

const jwt = typeof window === 'object' ? localStorage.getItem('jwt') || '' : ''
export const axiosInstance: AxiosInstance = axios.create({
  headers: {
    ...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
  },
  paramsSerializer: (params) => qs.stringify(params),
})

axiosInstance.interceptors.request.use((config) => {
  const jwt = typeof window === 'object' ? localStorage.getItem('jwt') || '' : ''
  if (config.headers && jwt) {
    config.headers.Authorization = `Bearer ${jwt}`
  }
  return config
})

// Interceptor to emit an event with the id and its corresponding value from the response data
axiosInstance.interceptors.response.use((response) => {
  const { id, name, title, gameName } = response.data
  const value = name ?? title ?? gameName

  if (id && value) {
    // Emit an event with the id and corresponding value
    // This event is listened to in the IdValueMapContext
    emitEvent('updateIdValueMap', { key: id, value })
  }

  return response
})

export function getAxios(baseUrl = API_URL): AxiosInstance {
  axiosInstance.defaults.baseURL = baseUrl
  return axiosInstance
}

export const reqLoginUser = (username: string, password: string) =>
  getAxios().post('/auth/login', {
    username,
    password,
  })

export const getTempCode = () => getAxios().get('/auth/temp-token')

export const getTokenStatus = (token) => getAxios().post('/auth/status', { token })

export const getAccessTokenViaTempToken = (tempToken) =>
  getAxios().post('/auth/temp-token', {
    tempToken,
  })

export const reqRegisterUser = (registerUserDto: RegisterUserDto) =>
  getAxios().post('/auth/register', registerUserDto)

export const updateUser = (id: string, updateUserDto: UpdateUserDto) =>
  getAxios().put(`/user/${id}`, {
    ...updateUserDto,
  })

export const deleteUser = () => getAxios().delete(`/user/delete`)

export const updateUserProfile = (updateUserProfileDto: UpdateUserProfileDto) =>
  getAxios().patch('/user-profile', {
    ...updateUserProfileDto,
  })

export const reqUpdateUser = (updateUserDto: UpdateUserDto) =>
  getAxios().put('/user', {
    ...updateUserDto,
  })

export const reqLogoutUser = (jwt: string) => getAxios().post('/auth/logout', { jwt })

// Api call for uploading user profile picture
export async function reqUploadUserProfilePic(file: File) {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await getAxios().post('/user/profile-picture', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

// Api call for deleting user profile picture
export async function reqDeleteUserProfilePic() {
  const { data } = await getAxios().delete('/user/profile-picture')
  return data
}

export const fetchCurentUser = async () => {
  const { data } = await getAxios().get<CurrentUser>('/user')
  return data
}

/**
 * fetch the user details by the id
 * @param id
 * @returns CurrentUser type user object
 */
export const fetchUserDetails = async (id: string) => {
  const { data } = await getAxios().get<CurrentUser>(`/user/${id}`)
  return data
}

export const createPlot = async (createPlotDto) => {
  const {
    data: { data },
  } = await getAxios().post('/plot', {
    ...createPlotDto,
  })
  return data
}

// Call the api for the plot details
export async function getPlotDetails(propertyId: string) {
  const { data } = await getAxios().get<Plot>(`${API_URL}/plot/${propertyId}`)
  return data
}

// Call the api for the plot list
export async function getPlotList(queryText = '') {
  const { data } = await getAxios().get(`${API_URL}/plot${queryText}`)
  return data
}

// Call the api for the plot list
export async function getBuildingList(queryText = '') {
  const { data } = await getAxios().get(`${API_URL}/plot-buildings${queryText}`)
  const plots = data.plots
    .map((plot) => ({ buildingId: plot.id, ...plot, ...plot.plot }))
    .filter((b) => b.noOfCreations > 0)
  return { count: data.count, plots }
}

// Call the api for the plot auction details
export async function getAuctionSaleDetails(propertyId: string): Promise<any> {
  const { data } = await getAxios().get(`${API_URL}/auctions?plotId=${propertyId}`)
  return data
}

// Call the api for the plot sales bids
export async function getPlotSalesBids(propertyId: string): Promise<any> {
  const response = await getAxios().get(`${API_URL}/buy-orders?plotId=${propertyId}`)
  return response.data
}

// Call the api for the auction history
export async function getAuctionHistory(auctionId: string, paymentType: string): Promise<any> {
  const response = await getAxios().get(
    `${API_URL}/auction-bid-history?auctionId=${auctionId}&type=${paymentType}&limit=-1`,
  )
  return response.data
}

// Call the api for the auction history
export async function getAuctions(queryText = ''): Promise<any> {
  const response = await getAxios().get(`${API_URL}/auctions${queryText}`)
  return response.data
}

// Call the api for the plot sales bids
export async function getAuctionBidsOfUser(auctionId: string, userId: string): Promise<any> {
  const response = await getAxios().get(
    `${API_URL}/auction-bid-history?auctionId=${auctionId}&userId=${userId}`,
  )
  return response.data
}

// TODO: Change the functions to typescript functions
// Create DTO for the other objects similar to CreatePlotDTO
// Create a buy order
export async function createBuyOrder(data: BidSubmitDto): Promise<any> {
  const { data: response } = await getAxios().post(`${API_URL}/buy-orders`, data)
  return response.data
}

// Create a buy order
export async function updateBuyOrder(data: BidSubmitDto): Promise<any> {
  const { data: response } = await getAxios().put(`${API_URL}/buy-orders`, data)
  return response.data
}

// Feed data to auction algorithm
export async function feedAuctionData(data) {
  const { data: response } = await getAxios().post(`${API_URL}/load-test`, data)
  return response.data
}

// Call the api for the exchange rates
export async function getExchangeRates(url: string): Promise<any> {
  const response = await getAxios().get(`${RATE_API}/${url}`)
  return response.data
}

// Get payment intent from stripe
export async function getPaymentIntent(params: PaymentIntentDto): Promise<any> {
  const { data } = await axios.post(
    `${STRIPE_API_URL}/payment_intents`,
    new URLSearchParams(params as unknown as URLSearchParams),
    {
      headers: STRIPE_HEADERS,
    },
  )
  return data
}

// Update payment intent from stripe
export async function updatePaymentIntent(
  paymentIntentId: string,
  params: UpdatePaymentIntentDto,
): Promise<any> {
  const { data } = await axios.post(
    `${STRIPE_API_URL}/payment_intents/${paymentIntentId}`,
    new URLSearchParams(params),
    {
      headers: STRIPE_HEADERS,
    },
  )
  return data
}

// Get the customer details from stripe
export async function createStripeCustomer(email: string, name: string): Promise<any> {
  const { data } = await axios.post(
    `${STRIPE_API_URL}/customers`,
    new URLSearchParams({ email, name }),
    {
      headers: STRIPE_HEADERS,
    },
  )
  return data
}

// Get the customer cards
export async function getStripeCustomerCards(customerId: string): Promise<any> {
  const { data } = await axios.get(
    `${STRIPE_API_URL}/customers/${customerId}/payment_methods?type=card`,
    {
      headers: STRIPE_HEADERS,
    },
  )
  return data
}

// detach the card from customer
export async function detachCardFromCustomer(cardId: string): Promise<any> {
  const { data } = await axios.post(
    `${STRIPE_API_URL}/payment_methods/${cardId}/detach`,
    {},
    {
      headers: STRIPE_HEADERS,
    },
  )
  return data
}

// Get my properties
export async function getMyProperties() {
  const { data } = await getAxios().get(`${API_URL}/plot-owners`)
  return data
}

/**
 * Plot related API Requests
 */
export const fetchAllPlots = async () => {
  const { data } = await getAxios().get('/plot')
  return data.plots
}

export const fetchPlot = async (plotId: string) => {
  const { data } = await getAxios().get(`/plot/${plotId}`)
  return data
}

// File Upload Post Request
export async function reqPlotAssetUpload(plotId: string, files: File[]) {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })
  const { data } = await getAxios().post(`${API_URL}/plot/upload-assets/${plotId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

export async function reqAssetDelete(assetId: string) {
  const data = await getAxios().delete(`/assets/${assetId}`)
  return data
}

// File Upload Post Request
export async function reqFilesUpload(files: File[]) {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })
  const response = await getAxios().post(`${API_URL}/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}

// Call the api for the auction vitals
export async function getAuctionVitals(auctionId: string, paymentType: string): Promise<any> {
  const { data } = await getAxios().get(
    `${API_URL}/auction-bid-history/vitals?auctionId=${auctionId}&type=${paymentType}`,
  )
  return data
}

// Post request api for changing password
export async function reqChangePassword({
  currentPassword,
  newPassword,
}: {
  currentPassword: string
  newPassword: string
}): Promise<any> {
  const { data } = await getAxios().post('/auth/change-password', {
    currentPassword,
    newPassword,
  })
  return data
}

// Post request api for user veriication
export async function reqUserVerification(id: string): Promise<any> {
  const { data } = await getAxios().post(`/user-verification/${id}`)
  return data
}

// Post request api for sending password reset request
export async function reqPasswordReset({
  email,
  origin,
}: {
  email: string
  origin: TrustedDomains
}) {
  const { data } = await getAxios().post('/password-reset', {
    email,
    origin,
  })
  return data
}

// Post request api for setting new password via password reset link
export async function reqPasswordResetNew(values: { id: string; password: string }): Promise<any> {
  const { data } = await getAxios().post('/password-reset/new', {
    ...values,
  })
  return data
}

// Call the api for the auction vitals
export async function fetchAllCreatorSubmissions(plotId: string, buildingId = ''): Promise<any> {
  const { data } = await getAxios().get(
    `${API_URL}/creator-submissions?plotId=${plotId}&buildingId=${buildingId}`,
  )
  return data
}

export async function submitCreatorSubmissions(payload: any): Promise<any> {
  const { data } = await getAxios().post(`${API_URL}/creator-submissions`, payload)
  return data
}

// Call the api to vote
export async function requestVote(submissionId: string, voteType: string): Promise<any> {
  const { data } = await getAxios().post(`${API_URL}/votes`, {
    creatorSubmissionId: submissionId,
    voteType,
  })
  return data
}

// Call the api to vote
export async function addVote(data: AddVote): Promise<any> {
  await getAxios().post(`${API_URL}/votes`, data)
}

// get one creator submission
export async function fetchCreatorSubmission(id: string): Promise<any> {
  const { data } = await getAxios().get(`${API_URL}/creator-submissions/${id}`)
  return data
}

export async function approveCreatorSubmission(id: string): Promise<any> {
  const { data } = await getAxios().patch(`${API_URL}/creator-submissions/approve/${id}`)
  return data
}

export async function rejectCreatorSubmission(id: string): Promise<any> {
  const { data } = await getAxios().patch(`${API_URL}/creator-submissions/reject/${id}`)
  return data
}

// Call the votes api to get my votes
export async function findMyVotes(plotId: string): Promise<any> {
  const { data } = await getAxios().get(`${API_URL}/votes/user?plotId=${plotId}`)
  return data
}

export async function fetchVoteWeight(plotId: string, buildingId = ''): Promise<any> {
  const {
    data: { data },
  } = await getAxios().get(`${API_URL}/votes/weight?plotId=${plotId}&buildingId=${buildingId}`)
  return data
}

export async function requestFinalizeVote(plotId: string): Promise<any> {
  const {
    data: { data },
  } = await getAxios().post(`${API_URL}/votes/close`, {
    plotId,
  })
  return data
}

export async function getUSACitiesByState(selectedState: string): Promise<any> {
  const { data } = await getAxios().get(`${API_URL}/assets/cities/${selectedState}`)
  return data
}
