import Image, { ImageProps } from 'next/image'
import { blurDataURL } from '@util/helpers'
import { PROJECT } from 'configs'

const Logo = ({
  loading,
  priority,
  width = '100%',
  src = PROJECT.logo,
  ...props
}: Omit<ImageProps, 'src' | 'alt' | 'width'> & { width?: string; src?: string }) => (
  <Image
    src={src}
    width={0}
    height={0}
    style={{ width, height: 'auto' }}
    alt={PROJECT.name}
    placeholder="blur"
    blurDataURL={blurDataURL(160, 70)}
    priority={priority || false}
    loading={loading || 'eager'}
    {...props}
  />
)

export default Logo
