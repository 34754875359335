export const TRUSTED_DOMAINS = {
  workerdefense: process.env.NEXT_PUBLIC_WORK_DEFENSE_URL,
  // heroonearth: process.env.NEXT_PUBLIC_HERO_APP_URL,
  endtherig: process.env.NEXT_PUBLIC_HERO_APP_URL,
  riggedeconomy: process.env.NEXT_PUBLIC_RIGGED_ECONOMY_URL,
  congresshq: process.env.NEXT_PUBLIC_CONGRESS_MEMBER_URL,
  medicalapp: process.env.NEXT_PUBLIC_MEDICAL_APP_URL,
} as const

export const TRUSTED_DOMAINS_REVERSED = {
  [process.env.NEXT_PUBLIC_WORK_DEFENSE_URL]: 'workerdefense',
  [process.env.NEXT_PUBLIC_HERO_APP_URL]: 'endtherig',
  // [process.env.NEXT_PUBLIC_HERO_APP_URL]: 'heroonearth',
  [process.env.NEXT_PUBLIC_RIGGED_ECONOMY_URL]: 'riggedeconomy',
  [process.env.NEXT_PUBLIC_CONGRESS_MEMBER_URL]: 'congresshq',
  [process.env.NEXT_PUBLIC_MEDICAL_APP_URL]: 'medicalapp',
}

export type TrustedDomains = keyof typeof TRUSTED_DOMAINS
