import styled from '@emotion/styled'
import { Box, Button, Dialog, Typography } from '@mui/material'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import Image from 'next/image'
import CloseIcon from '@mui/icons-material/Close'
import { SettingsIcon } from '@commons/modules/Icons/icons'
import theme from '@theme'

export const BoxqplMainWrapper = styled(Box)(() => ({
  position: 'relative',
  background: theme.palette.grey10,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '100vh',
  '@media (orientation: landscape)': {
    [theme.breakpoints.down('sm')]: {
      height: '70vh',
    },
    [theme.breakpoints.up('sm')]: {
      height: '80vh',
    },
    [theme.breakpoints.up('md')]: {
      height: '85vh',
    },
    [theme.breakpoints.up('lg')]: {
      height: '90vh',
    },
    [theme.breakpoints.up('xl')]: {
      height: '100vh',
    },
  },
  '@media (orientation: portrait)': {
    [theme.breakpoints.down('sm')]: {
      height: '60vh',
    },
    [theme.breakpoints.up('sm')]: {
      height: '65vh',
    },
    [theme.breakpoints.up('md')]: {
      height: '75vh',
    },
    [theme.breakpoints.up('lg')]: {
      height: '80vh',
    },
    [theme.breakpoints.up('xl')]: {
      height: '100vh',
    },
  },

  userSelect: 'none',
  display: 'flex',
  flexDirection: 'row',
  flexBasis: '100%',
  zIndex: 5,
}))

export const BoxqplheadWrapper = styled(Box)(() => ({
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(15, 32, 51, 0.00) 100%)',
  height: '80px',
  display: 'flex',
  flexDirection: 'row',
}))

export const BoxqplMainBody = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
}))
export const BoxqplInnerWrapper = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  flexBasis: '100%',
}))
export const BoxqplinnerHead = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5vw',
  flex: 1,
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  padding: '1.5vw',
}))
export const BoxqplInnerBody = styled(Box)(() => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignTtems: 'center',
  position: 'relative',
}))
export const TypographyqplQuestName = styled(Typography)(() => ({
  color: theme.palette.neutralVariant100,
  fontSize: '1.7vw',
  fontWeight: 800,
}))
export const BoxqplQuestName = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  padding: '0vw 2vw',
}))
export const BoxqplContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))
export const BoxqplleftContent = styled(Box)(() => ({
  position: 'absolute',
  left: '2%',
  bottom: '5%',
  padding: '1vw',
  borderRadius: '4px',
  background: 'rgba(255, 255, 255, 0.10)',
  boxShadow: '0px 5px 30px 0px rgba(251, 255, 74, 0.10) inset',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}))
export const BoxqlpMainCharacterWrapper = styled(Box)(() => ({
  position: 'fixed',
  bottom: 0,
  right: '5%',
  height: '85vh',
  aspectRatio: '5/6',
  zindex: -1,
}))
export const BoxqplInnerCharacter = styled(Box)(() => ({
  position: 'relative',
}))
export const BoxqplChatBubble = styled(Box)(() => ({
  position: 'absolute',
  top: '18%',
  right: '80%',
  width: '80%',
  maxWidth: '620px',
  aspectRatio: '6/3',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  paddingBottom: '10px',
}))
export const BoxqplChatbubbletitleWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '-15%',
  left: '28%',
  transform: 'translate(-30%, 0)',
  color: theme.palette.neutralVariant100,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center top',
  zIndex: -2,
  minWidth: '20vh',
  height: '6vh',
  display: 'flex',
  justifyContent: 'center',
}))
export const TypographyqplChatbubbletitle = styled(Typography)(() => ({
  padding: '14px 60px',
  fontSize: '.9vw',
  textWrap: 'nowrap',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const BoxqplChatbubbleText = styled(Box)(() => ({
  textAlign: 'center',
  paddingTop: '0%',
  paddingBottom: '0%',
  paddingLeft: '12%',
  paddingRight: '28%',
}))
export const TypographyqplChatbubbleText = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1vw',
  fontWeight: 700,
}))

export const TypographyqplText = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '2vw',
  fontWeight: 700,
  color: theme.palette.neutralVariant100,
  WebkitTextStroke: `0.6px ${theme.palette.neutralVariant0}`,
  textShadow: `0px 1px 1px ${theme.palette.neutralVariant0}`,
  padding: '.3vw',
}))

export const BoxqplVideoWraper = styled(Box)(() => ({
  display: 'block',
  width: '100%',
  height: '100%',
}))

export const BoxqplMediaControlMainWrapper = styled(Box)(() => ({
  position: 'absolute',
  right: '10%',
  bottom: '5%',
  padding: '1vw',
  borderRadius: '4px',
  background: 'rgba(255, 255, 255, 0.10)',
  boxShadow: '0px 5px 30px 0px rgba(251, 255, 74, 0.10) inset',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  cursor: 'pointer',
}))
export const BoxqplMediaControlSubWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '.5vw',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}))
export const TypographyqplHeaderTitle = styled(Typography)(() => ({
  color: theme.palette.grey4,
  fontSize: '1.2vw',
  fontWeight: 700,
  cursor: 'pointer',
  ':hover': {
    color: theme.palette.neutralVariant100,
  },
  textTransform: 'none',
}))
export const SettingIconStyled = styled(SettingsIcon)(() => ({
  color: theme.palette.grey4,
  fontSize: '1.2vw',
  fontWeight: 700,
  cursor: 'pointer',
  ':hover': {
    color: theme.palette.neutralVariant100,
  },
}))

export const TypographyqplQuestTitleLeftButton = styled(Typography)(() => ({
  color: theme.palette.grey4,
  fontWeight: 700,
  fontSize: '2vw',
}))
export const TypographyqplQuestSubTitleLeftButton = styled(Typography)(() => ({
  color: theme.palette.grey4,
  fontWeight: 400,
  fontSize: '1.5vw',
}))
export const TypographyqplQuestContinueLeftButton = styled(Typography)(() => ({
  color: theme.palette.neutralVariant100,
  fontWeight: 800,
  fontSize: '2.5vw',
  textTransform: 'uppercase',
  WebkitTextStroke: `0.6px ${theme.palette.neutralVariant0}`,
  textShadow: `0px 1px 1px ${theme.palette.neutralVariant0}`,
  padding: '.3vw',
  cursor: 'pointer',
}))
export const TypographyqplQuestHotKey = styled(Typography)(() => ({
  color: theme.palette.neutralVariant0,
  fontWeight: 800,
  fontSize: '1.5vw',
  textTransform: 'uppercase',
  backgroundColor: 'rgba(255, 255, 255, 0.50)',
  border: `2px solid ${theme.palette.neutralVariant0}`,
  WebkitTextStroke: `0.6px ${theme.palette.neutralVariant100}`,
  textShadow: `0px 1px 1px ${theme.palette.neutralVariant0}`,
  padding: '0vw .3vw 0vw .3vw',
  display: 'flex',
  justifyContent: 'center',
  flexBasis: '2vw',
  maxWidth: '2vw',
  flexShrink: 0,
}))
export const TypographyqplQuestArrow = styled(KeyboardDoubleArrowRightIcon)(() => ({
  color: theme.palette.neutralVariant0,
  fontWeight: 800,
  fontSize: '3.5vw',
  WebkitTextStroke: `0.6px ${theme.palette.neutralVariant100}`,
  textShadow: `0px 1px 1px ${theme.palette.neutralVariant0}`,
}))
export const BoxqplFooterWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))
export const MediaControlIcon = styled(Box)(({ theme: Theme }) => ({
  height: '3vh',
  width: '2vw',
  display: 'flex',
  justifyContent: 'center',
  [Theme.breakpoints.up('lg')]: {
    height: '4vh',
    width: '2vw',
  },
}))
export const icon = {
  height: '100%',
  width: '100%',
}
export const BoxMediaControlMainWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '.5vw',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}))
export const BoxContinueBtnWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1vw',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  background: theme.palette.purple1,
  padding: '0vw 2vw 0vw 2vw',
  ':hover': {
    background: theme.palette.purple,
  },
}))

export const BoxHeaderTitleContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  gap: '2.5vw',
  flexDirection: 'row',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
}))

export const BoxBigGameMainWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  border: '8px solid rgba(255, 255, 255, 0.30)',
  background: theme.palette.blue1,
  padding: '0vw 2vw 0vw 2vw',
  ':hover': {
    backgroundColor: 'rgb(5 126 255 / 96%)',
  },
}))
export const BoxBigGameSubWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1vw',
  justifyContent: 'center',
  alignItems: 'center',
}))
export const BoxloginContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '.5vw',
  justifyContent: 'center',
  alignItems: 'center',
}))
export const BoxHeroLogoWrapper = styled(Box)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  display: 'inline-block',
  margin: 0,
  padding: 0,
  verticalAalign: 'middle',
}))
export const TypographyMutelbl = styled(Typography)(() => ({
  fontSize: '1vw',
  fontWeight: 900,
  color: theme.palette.grey4,
  textTransform: 'uppercase',
  WebkitTextStroke: `0.6px ${theme.palette.neutralVariant0}`,
  textShadow: `0px 1px 1px ${theme.palette.neutralVariant0}`,
}))

export const BoxqplSubDivWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  flexBasis: '100%',
}))

export const BoxqplMarketingVideoWrapper = styled(Box)(() => ({
  padding: '10px',
  flexBasis: '50%',
}))

export const BoxqplDivContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '40%',
}))

export const TypographyFirstDivContentTitle = styled(Typography)(() => ({
  color: theme.palette.yellow5,
  textShadow: '0px 4px 0px #000',
  fontWeight: 800,
  lineHeight: 'normal',
  fontSize: '3vw',
  textTransform: 'uppercase',
}))

export const TypographyFirstDivContentBody = styled(Typography)(() => ({
  fontSize: '1.5vw',
  color: theme.palette.neutralVariant100,
  padding: '10px',
}))

export const TypographyGamePlayLevel = styled(Typography)(() => ({
  color: theme.palette.yellow5,
  textShadow: '0px 4px 0px #000',
  fontWeight: 800,
  lineHeight: 'normal',
  fontSize: '4vw',
  textTransform: 'uppercase',
}))
export const TypographyInovationContent = styled(Typography)(() => ({
  fontSize: '2.5vw',
  color: theme.palette.neutralVariant100,
  fontWeight: '500',
  WebkitTextStroke: `0.3px ${theme.palette.neutralVariant0}`,
  textShadow: `0px 1px 1px ${theme.palette.black}`,
}))

export const TypographyInovationContentBody = styled(Typography)(() => ({
  fontSize: '1.5vw',
  color: theme.palette.neutralVariant100,
  lineHeight: 'normal',
}))

export const BoxqplDivAdventureWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px',
  flexBasis: '100%',
  '@media (orientation: portrait)': {
    [theme.breakpoints.up('sm')]: {
      padding: '10px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '70px',
    },
  },
  '@media (orientation: landscape)': {
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '10px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '50px',
    },
    [theme.breakpoints.up('xxl')]: {
      padding: '90px',
    },
  },
}))
export const TypographyAdventureTitle = styled(Typography)(() => ({
  color: theme.palette.yellow5,
  textShadow: '0px 4px 0px #000',
  fontWeight: 800,
  lineHeight: 'normal',
  fontSize: '4vw',
  textTransform: 'uppercase',
}))
export const TypographyAdventureBody = styled(Typography)(() => ({
  fontSize: '2vw',
  color: theme.palette.neutralVariant100,
  lineHeight: '1.5',
}))

export const TypographyTopTitle = styled(Typography)(() => ({
  fontSize: '1.5vw',
  color: theme.palette.neutralVariant100,
  paddingLeft: '2vw',
}))

export const BoxqplCenterCharacterWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '3rem',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
    marginTop: 0,
    height: '100%',
    justifyContent: 'unset',
    alignItems: 'flex-end',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.up('xl')]: {
    marginRight: '2rem',
    display: 'flex',
  },
}))

export const StyleImage = styled(Image)(() => ({
  objectFit: 'contain',
  width: '100%',
  height: '80vh',
  position: 'relative',
  bottom: 0,
  objectPosition: 'center bottom',
  aspectRatio: ' 3 / 4',
}))

export const StyleLogoImage = styled(Image)(() => ({
  maxWidth: '240px',
  height: '100%',
  width: '100%',
  minWidth: '200px',
  padding: '30px 0 0 15px',
}))

export const ButtonPlayNow = styled(Button)(() => ({
  borderRadius: '12px',
  border: `4px solid ${theme.palette.blue16}`,
  background: theme.palette.purple1,
  boxShadow: `0px 4px 0px 0px ${theme.palette.blue16}`,
  display: 'flex',
  color: theme.palette.neutralVariant100,
  fontSize: '2vh',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  padding: '16px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  maxWidth: 'fit-content',
  textTransform: 'none',
  minWidth: '300px',
  textDecoration: 'none',
  cursor: 'pointer',
  ':hover': {
    background: theme.palette.purple,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1vh',
    padding: '6px',
    minWidth: '100px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '2vh',
    padding: '8px',
    minWidth: '160px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2vh',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '4px',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '16px 20px',
    fontSize: '2.4vh',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '2vh',
  },
}))

export const TypographyCenterTitleWrapper = styled(Typography)(() => ({
  color: theme.palette.blue17,
  textAlign: 'center',
  fontSize: '3vh',
  fontStyle: 'normal',
  fontWeight: 800,
  textTransform: 'uppercase',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2vh',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '3vh',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.5vh',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '3vh',
  },
}))

export const BoxqplCenterContentMainWrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  width: '100%',
  '@media screen and (min-width: 992px)': {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.down('xl')]: {
    justifyContent: 'center',
  },
}))

export const BoxCenterContentMainWrapper = styled(Box)(() => ({
  display: 'flex',
  padding: '30px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '30px',
  borderRadius: '8px',
  background: theme.palette.blue16,
  margin: '20px',
  maxWidth: '80vw',
  [theme.breakpoints.down('md')]: {
    padding: '20px',
    gap: '10px',
    '@media (orientation: landscape)': {
      maxWidth: '100vh',
    },
    '@media (orientation: portrait)': {
      maxWidth: '80vw',
    },
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '60vh',
    '@media (orientation: landscape)': {
      maxWidth: '90vh',
    },
    '@media (orientation: portrait)': {
      maxWidth: '60vh',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '@media (orientation: landscape)': {
      maxWidth: '45vw',
      marginRight: '5vh !important',
    },
    '@media (orientation: portrait)': {
      maxWidth: '45vw',
      marginRight: '2vh',
    },
  },
  [theme.breakpoints.up('xxxl')]: {
    maxWidth: '40vw',
    marginRight: '-12vh',
  },
}))

export const TypographyCenterSubTitleWrapper = styled(Typography)(() => ({
  color: theme.palette.neutralVariant100,
  fontSize: '1.5vh',
  fontStyle: 'normal',
  fontWeight: 500,
  textAlign: 'left',
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2vh',
    padding: '2px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.6vh',
    padding: '2px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8vh',
  },
  [theme.breakpoints.up('xxxl')]: {
    fontSize: '1.5vh',
  },
}))

export const BoxqplMainBodyWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignSelf: 'flex-end',
  width: '100%',
}))

export const StyleImagefemalecharacter = styled(Image)(() => ({
  objectFit: 'contain',
  width: '100%',
  height: '90vh',
  position: 'relative',
  bottom: 0,
  objectPosition: 'center bottom',
  aspectRatio: ' 4 / 4',
}))

export const StyleLogoImagefooterlogo = styled(Image)(() => ({
  maxWidth: '240px',
  height: '100%',
  width: '100%',
  minWidth: '200px',
  '@media (orientation: landscape)': {
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      minWidth: '0px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '40%',
      minWidth: '50px',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  '@media (orientation: portrait)': {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      minWidth: '0px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '40%',
      minWidth: '50px',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}))

export const ButtonSubscribe = styled(Button)(() => ({
  borderRadius: '12px',
  border: `4px solid ${theme.palette.blue16}`,
  background: theme.palette.purple1,
  boxShadow: `0px 4px 0px 0px ${theme.palette.blue16}`,
  display: 'flex',
  color: theme.palette.neutralVariant100,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  maxWidth: 'fit-content',
  textTransform: 'none',
  textDecoration: 'none',
  cursor: 'pointer',
  ':hover': {
    background: theme.palette.purple,
  },
  marginRight: '-15px',
}))

export const DialogVideoPlayerMainWrapper = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    width: '70vw',
    height: '70vh',
    maxWidth: 'none',
    maxHeight: 'none',
    margin: 0,
    '@media (orientation: portrait)': {
      [theme.breakpoints.down('sm')]: {
        width: '50vw',
        height: '70vh',
      },
      [theme.breakpoints.down('md')]: {
        width: '80vw',
        height: '40vh',
      },
      [theme.breakpoints.down('lg')]: {
        width: '80vw',
        height: '45vh',
      },
    },
    '@media (orientation: landscape)': {
      [theme.breakpoints.down('sm')]: {
        width: '50vw',
        height: '70vh',
      },
      [theme.breakpoints.down('md')]: {
        width: '80vw',
        height: '60vh',
      },
      [theme.breakpoints.down('lg')]: {
        width: '60vw',
        height: '60vh',
      },
    },
  },
}))

export const BoxVideoSubWrapper = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
}))

export const CloseIconWprapper = styled(CloseIcon)(() => ({
  color: theme.palette.neutralVariant100,
  background: theme.palette.neutralVariant10,
  borderRadius: '50%',
  fontSize: '20px',
  ':hover': {
    color: theme.palette.neutralVariant0,
  },
  position: 'absolute',
  top: '10px',
  right: '5px',
}))
