/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { Dialog } from '@mui/material'
import Image from 'next/image'

import { networkFailureWifiImage } from '@template/GameDashboard/constants'
import { BoxMainNetworkStatusWrapper, TypographyStatusMessage } from './Index.styles'

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  return null
  return (
    !isOnline && (
      <Dialog open sx={{ zIndex: 9999999999 }}>
        <BoxMainNetworkStatusWrapper>
          <Box>
            <Image src={networkFailureWifiImage} alt="Network failer" width="60" height="60" />
          </Box>
          <Box>
            <TypographyStatusMessage>
              You are currently offline. Please check your network connection.
            </TypographyStatusMessage>
          </Box>
        </BoxMainNetworkStatusWrapper>
      </Dialog>
    )
  )
}

export default NetworkStatus
