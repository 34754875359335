import { createContext, useState, ReactNode, useEffect, useMemo, useCallback } from 'react'
import { listenEvent, removeEventListener } from '@util/event'

interface IdValueMapContextProps {
  idValueMap: Record<string, string>
  updateIdValueMap: (id: string, Value: string) => void
}

export const IdValueMapContext = createContext<IdValueMapContextProps | undefined>(undefined)

export const IdValueMapProvider = ({ children }: { children: ReactNode }) => {
  const [idValueMap, setIdValueMap] = useState<Record<string, string>>({})

  const updateIdValueMap = useCallback((id: string, value: string) => {
    setIdValueMap((prevMap) => ({ ...prevMap, [id]: value }))
  }, [])

  useEffect(() => {
    const handleUpdateIdValueMap = (event) => {
      const { key, value } = event.detail
      updateIdValueMap(key, value)
    }

    listenEvent('updateIdValueMap', handleUpdateIdValueMap)

    return () => removeEventListener('updateIdValueMap', handleUpdateIdValueMap)
  })

  const value = useMemo(() => ({ idValueMap, updateIdValueMap }), [idValueMap, updateIdValueMap])
  return <IdValueMapContext.Provider value={value}>{children}</IdValueMapContext.Provider>
}
