import { Avatar } from '@mui/material'
import React from 'react'

type Props = {
  height?: number | string
  width?: number | string
  src?: string
  text?: string
}

// eslint-disable-next-line arrow-body-style
const UserAvatar = ({ height = 80, width = 80, src, text }: Props) => (
  <Avatar
    sx={(theme) => ({
      width,
      height,
      // Calculate font size based on height
      fontSize: `${(parseInt(height as string, 10) || 80) / 26}rem`,
      bgcolor: theme.palette.background.userAvatar,
    })}
    {...(src && { src })}
  >
    {text || null}
  </Avatar>
)

export default UserAvatar
