import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import jwt from 'jsonwebtoken'
import { fetchCurentUser, getTokenStatus } from '@api/api'
import {
  ROLE_TYPE_CREATOR,
  ROLE_TYPE_SUPER_ADMIN,
  ROLE_TYPE_USER,
  USER_SESSION_KEY,
} from '@constant/index'
import { TypeUserContext } from 'types/user'

type Props = {
  children: React.ReactNode
}

const UserContext = createContext<TypeUserContext>(null)

const UserProvider = ({ children }: Props) => {
  const [hasUser, setHasUser] = useState<boolean>(false)
  const token = typeof window !== 'undefined' ? localStorage.getItem('jwt') : null
  useEffect(() => {
    if (token) {
      const decodedToken = jwt.decode(token)
      const expirationTime = decodedToken?.exp
      const currentTime = Math.floor(Date.now() / 1000)
      if (currentTime >= expirationTime) {
        localStorage.removeItem('jwt')
      } else {
        setHasUser(true)
      }
    }
  }, [token])
  const { data: statusData, isFetched: isStatusFetched } = useQuery(
    ['currentUser', token],
    () => getTokenStatus(token),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!token,
      onSettled: (res) => setHasUser(!!res?.data),
    },
  )

  // somehow, fetchCurentUser was always being triggered when loggin out
  // this will obviously be unauthorized after logout and was causing unwanted message popup as well in some cases
  // the query key here are responsible for invalidating and refetching the querry whenever the query key value changes
  // the combination of hasUser in query key and enabled options helps prevent calling this method after each logout
  const { data, isFetched, refetch, remove } = useQuery(
    ['currentUser', hasUser, token],
    fetchCurentUser,
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: isStatusFetched && hasUser,
    },
  )

  const changeHasUserStatus = useCallback((status: boolean) => {
    setHasUser(status)
  }, [])

  const contextValues: TypeUserContext = useMemo(() => {
    const isAdmin = data?.roles?.includes(ROLE_TYPE_SUPER_ADMIN)
    const hasOnlyUserRole = data?.roles?.every((role) => role === ROLE_TYPE_USER)
    const hasCreatorAccess = data?.roles?.some((role) => role === ROLE_TYPE_CREATOR)

    // Persist frequently used user data
    if (!(data == null) && token) {
      const sessionUser = {
        id: data?.id,
        roles: data?.roles,
        isAdmin,
        hasOnlyUserRole,
        hasCreatorAccess,
      }
      localStorage.setItem(USER_SESSION_KEY, JSON.stringify(sessionUser))
    }

    return {
      currentUser:
        data && token
          ? {
              ...data,
              isAdmin,
              hasOnlyUserRole,
            }
          : null,
      isLoading: !!token && (!isStatusFetched || (!isFetched && !!statusData?.data)),
      refetch,
      remove,
      changeHasUserStatus,
    }
  }, [data, statusData, token, isFetched, isStatusFetched, refetch, remove, changeHasUserStatus])

  return <UserContext.Provider value={contextValues}>{children}</UserContext.Provider>
}

export { UserContext, UserProvider }
