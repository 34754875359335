/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import get from 'lodash/get'
import moment from 'moment-timezone'
import jwt from 'jsonwebtoken'
import orderBy from 'lodash/orderBy'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import Router, { useRouter } from 'next/router'
import qs from 'qs'
import {
  APP_BASE_LINK,
  AUCTION_TYPE_CASH,
  CASH,
  DEFAULT_CREATOR_CREDIT_INCREASE,
  DEFAULT_MIN_BID_INCREASE,
  DEFAULT_MIN_BID_INCREASE_AFTER_100,
  DEFAULT_MIN_BID_INCREASE_AFTER_200,
  DEFAULT_MIN_BID_INCREASE_AFTER_50,
  DEFAULT_PAGINATION_LIMIT,
  OS_LINUX,
  OS_MAC,
  OS_UNIX,
  OS_WINDOWS,
  S3_BASE_URI,
  UNREAL_DOWNLOAD_LINK,
} from '@constant'
import ROUTES from '@constant/Routes'
import { ChallengeGameCell, FilterComparators, GameMode } from '@type/common'
import { getExchangeRates, getTokenStatus } from '@api/api'
import { CellProperty } from '@api/challenge-game'
import { addPageFilters, setUserPref } from '@hook/common/useUserPref'

/**
 * Return formatted date in the desired format
 * @param date
 * @param format
 * @returns formattedDate
 */
export function formatDate(date: Date | string, format = 'M/D/YYYY hh:mm A'): string {
  const formattedDate = moment(date).tz('America/Los_Angeles').format(format)
  const pstIndicator = moment.tz(date, 'America/Los_Angeles').isDST() ? 'PDT' : 'PST'
  return `${formattedDate} (${pstIndicator})`
}

/**
 * Convert number to fixed precision value.
 * @param number - Number to be fixed with precision
 * @param precision - Precision value used.
 * @returns Converted fixed number value.
 */
export function getFixedNumber(number: number, precision: number = 8): number {
  return Number(number.toFixed(precision))
}

/**
 * Return the formatted amount with the $ appended
 * @param {number} - amount
 * @returns {string} - formatted amount
 */
export function formatAmount(amount: number, type = CASH): string {
  return type === AUCTION_TYPE_CASH
    ? `$${amount.toLocaleString('en-US')}`
    : amount.toLocaleString('en-US')
}

/**
 * Handle numeric key press
 * @param e - Event of the key press
 * @returns {void}
 */
export function handleNumericKeyPress(e: any): void {
  const characterCode = e.key
  if (characterCode === 'Backspace') return

  const characterNumber = Number(characterCode)
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      // eslint-disable-next-line no-useless-return
      return
    }
    if (characterNumber === 0 && e.target.value === 0) {
      e.preventDefault()
    }
  } else {
    e.preventDefault()
  }
}

/**
 * Add or switch network based on the chain id
 * Try requesting to switch to our network
 * If doesn't exist, then add our network to the list
 */
// export async function addOrSwitchNetwork() {
//   const chainIDHex = `0x${CHAIN_ID.toString(16)}`
//   const params = BLOCKCHAIN_NETWORK_PARAMS
//   try {
//     await window.ethereum.request({
//       method: 'wallet_switchEthereumChain',
//       params: [{ chainId: chainIDHex }],
//     })
//   } catch (switchError) {
//     // This error code indicates that the chain has not been added to MetaMask.
//     if (switchError.code === 4902) {
//       // eslint-disable-next-line no-useless-catch
//       try {
//         await window.ethereum.request({
//           method: 'wallet_addEthereumChain',
//           params: [params],
//         })
//       } catch (addError) {
//         throw addError
//       }
//     } else {
//       throw switchError
//     }
//   }
// }

/**
 * Check if network id matches or not. If not then prompt to swtich the network.
 * @param injectedProvider - This is a browser wallet.
 */
// export async function checkNetworkID(injectedProvider: TypeEtherContext['injectedProvider']) {
//   const networkChangeError: string = 'underlying network changed'
//   try {
//     const network = await injectedProvider.current.getNetwork()
//     if (network.chainId !== CHAIN_ID) {
//       // If user is in different network ID then auto switch the network.
//       throw new Error(networkChangeError)
//     }
//   } catch (e) {
//     if (e.message.indexOf(networkChangeError) > -1) {
//       await addOrSwitchNetwork()
//     }
//   }
// }

/**
 * Check if network id matches or not
 * @param injectedProvider
 * @param signer
 * @param requiredBalance
 * @returns {Boolean}
 */
// export async function checkForBalance(
//   injectedProvider: TypeEtherContext['injectedProvider'],
//   signer: TypeEtherContext['signer'],
//   requiredBalance: number,
// ): Promise<Boolean> {
//   const address = await signer.current.getAddress()
//   const balance = await injectedProvider.current.getBalance(address)
//   const formmattedEther = await ethers.utils.formatEther(balance)
//   return Number(formmattedEther) > requiredBalance // Balance must be greater than requiredBalance. We reject even if balance is euqals to requiredBalance.
// }

/**
 *
 * @param injectedProvider
 * @param signer
 * @param amount
 * @returns {Boolean || string}
 */
// export async function transferBalance(
//   injectedProvider: TypeEtherContext['injectedProvider'],
//   signer: TypeEtherContext['signer'],
//   amount: string,
// ) {
//   if (!DEPOSIT_ADDRESS) {
//     throw new Error('Receiver wallet address not found.')
//   }
//   const params = [
//     {
//       from: await signer.current.getAddress(),
//       to: DEPOSIT_ADDRESS,
//       value: ethers.utils.parseUnits(amount, 'ether').toHexString(),
//     },
//   ]

//   const transactionHash = await injectedProvider.current.send('eth_sendTransaction', params)
//   return transactionHash
// }

/**
 * Get the exchange rates for the given token
 * @returns {Boolean}
 */
// export async function getExchangeRate(token) {
//   try {
//     const rateUrl = `data/price?fsym=${token}&tsyms=USD`
//     const rates = await getExchangeRates(rateUrl)
//     return rates.USD
//   } catch (e) {
//     return false
//   }
// }

/**
 * Get equivalent value for the amount and token
 * @param amount
 * @param token
 * @returns {number | ErrorConstructor}
 */
export async function getEquivalentValue(amount, token): Promise<number | ErrorConstructor> {
  const exchangeRate = await getExchangeRates(token)
  if (!exchangeRate) {
    throw new Error('Unable to process the token for exchange rate.')
  }
  return getFixedNumber(amount / exchangeRate)
}

/**
 * Get the asset url from s3 instead of the database
 * @param {string} - filename - name of the file
 * @param {string} - location - location of the file
 * @returns { string } - url of the image
 */
export function getAssetURI({ filename, location }: { filename: string; location: string }) {
  return `${S3_BASE_URI}/${location}/${filename}`
}

/**
 * Get the increase in amount from the bid amount
 * @param {number} bidAmount - current bidding amount
 * @returns { number } - Bid increase amount
 */
export function getBidIncrementAmount(bidAmount: number, paymentType = AUCTION_TYPE_CASH): number {
  return paymentType === AUCTION_TYPE_CASH
    ? bidAmount >= 200
      ? DEFAULT_MIN_BID_INCREASE_AFTER_200
      : bidAmount >= 100
      ? DEFAULT_MIN_BID_INCREASE_AFTER_100
      : bidAmount >= 50
      ? DEFAULT_MIN_BID_INCREASE_AFTER_50
      : DEFAULT_MIN_BID_INCREASE
    : Math.ceil(bidAmount * DEFAULT_CREATOR_CREDIT_INCREASE)
}

/**
 * Provided role enum get formatted string of the role
 * @param {string} role - role string
 * @returns { string } - formatted role
 */
export function getFormattedUserRole(role: string) {
  const roleMap = {
    PLOT_ADMIN: 'Plot Admin',
    EMPLOYEE_ADMIN: 'Employee Admin',
    SUPER_ADMIN: 'SuperAdmin',
    USER: 'User',
    CREATOR: 'Creator',
  }

  return roleMap[role] || role
}

/**
 * Format the point by flooring (For the removal of decimal points) and based on the us system
 * @param num - string or number. This can be of type either string or number. The input has been parsed to number inside the function
 * @returns modified formatted value with commas
 */
export function formatPoint(num: string | number | undefined | null) {
  if (!num) {
    return '0'
  }
  if (typeof num === 'string' && Number.isNaN(+num)) {
    return num
  }
  const modifiedNumber = Math.floor(+num)
  return modifiedNumber.toLocaleString('en-US')
}

export function formatPointIgnorNull(num: string | number) {
  if (num === '' || num == null || num === 'undefined') {
    return ''
  }
  const modifiedNumber = Math.floor(+num)
  return modifiedNumber.toLocaleString('en-US')
}

export const getErrorMessage = (error: any) => {
  if (error?.response?.data?.message) {
    const { message } = error.response.data
    if (Array.isArray(message)) {
      // If message is an array, return the first element
      return message[0]
    }
    if (typeof message === 'string') {
      // If message is a string, return it directly
      return message
    }
  }
  // Default error message if no message found in response data
  return 'An error occurred. Please try again later.'
}

export function formatPointWithoutComma(num: string | number) {
  const modifiedNumber = Math.floor(+num)
  return modifiedNumber.toLocaleString('en-US', { useGrouping: false })
}

/**
 * Check if the input field is valid or not based on regex
 * @param { any } - regex
 * @param { any } - any - value to test
 * @returns { boolean } - flag if the field is valid or not
 */
export function checkRegex(regex: any, value: any) {
  return regex.test(value)
}

export function parseDetails(building) {
  const { details } = building
  const state = details?.['addr:state'] ?? ''
  const street = details?.['addr:street'] ?? ''
  const city = details?.['addr:city'] ?? ''
  const postcode = details?.['addr:postcode'] ?? ''
  const housenumer = details?.['addr:housenumber'] ?? ''
  const decider = (state + street + city + postcode + housenumer).length > 0
  building.name =
    details?.name ??
    (decider
      ? `${state} ${street} ${city} ${postcode} ${housenumer}`
      : `Building-${building.osmId}`)
  const buildingLocation = `${state} ${city} ${street}`
  building.location =
    building.location ?? get(building?.adventurePath, 'details.formatted_address', buildingLocation)
  // eslint-disable-next-line no-restricted-globals
  building.floor = formatPoint(
    Number.isNaN(Number(details?.['building:levels'])) ? 1 : details?.['building:levels'],
  )
  building.type = building?.details?.building === 'yes' ? 'building' : building?.details?.building
  building.percentCompleted = Math.floor(building.percentCompleted)
  building.floor = parseInt(building.floor, 10)
  building.points = building?.creatorCredits ?? 0
  building.creatorCredits = formatPoint(building?.creatorCredits ?? 0)
  return building
}

export function parseBuildingData(data) {
  const buildings = data.buildings.map(parseDetails)
  data.buildings = buildings
  return data
}

export function parseBuildingForLeaderBoard(data) {
  const buildings = data.leaderboard.map(parseDetails)
  data.leaderboard = buildings
  return data
}

/**
 * Parse the building data for the votes
 * @param data
 * @returns
 */
export function parseBuildingForVotes(data) {
  const buildings = data.votes.map(parseDetails)
  data.votes = buildings
  return data
}

/**
 * Get the OS type of the user
 * @returns string i.e. one of the constants defined in the constants
 */
export function getOsOfUser(): string {
  const os = navigator?.userAgent
  let finalOs = ''
  if (os.search('Windows') !== -1) {
    finalOs = OS_WINDOWS
  } else if (os.search('Mac') !== -1) {
    finalOs = OS_MAC
  } else if (os.search('X11') !== -1 && !(os.search('Linux') !== -1)) {
    finalOs = OS_UNIX
  } else if (os.search('Linux') !== -1 && os.search('X11') !== -1) {
    finalOs = OS_LINUX
  }

  return finalOs
}

export function formatEnum(status: string = '') {
  const word = status.toLowerCase().replace('_', ' ')
  const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
  return capitalized
}

/**
 * Calcs remaining time for the building construction
 * @param date - string
 * @returns string
 */
export function getRemaingingTime(date: string) {
  const currentDate = moment()
  const startDate = moment(date)
  const days = currentDate.diff(startDate, 'days')

  if (days > 2) return 'Expired'

  const actualHour = currentDate.diff(startDate, 'hours')
  let hours = actualHour
  if (days > 0) {
    hours = actualHour % (days * 24)
  }

  const actualMinutes = currentDate.diff(startDate, 'minutes')
  let minutes = actualMinutes
  if (actualHour > 0) {
    minutes = actualMinutes % (actualHour * 60)
  }

  return `${days}d ${hours}h ${minutes}m`
}

/**
 * Calcs remaining time for the building construction
 * @param dateString - string
 * @returns string
 */
export function remainingReadableTime(dateString) {
  const SECONDS_IN_MINUTE = 60
  const MINUTES_IN_HOUR = 60
  const HOURS_IN_DAY = 24

  const targetDate = new Date(dateString)
  const currentTime = new Date()

  const targetTimeUTC = Date.UTC(
    targetDate.getUTCFullYear(),
    targetDate.getUTCMonth(),
    targetDate.getUTCDate(),
    targetDate.getUTCHours(),
    targetDate.getUTCMinutes(),
    targetDate.getUTCSeconds(),
  )

  const currentTimeUTC = Date.UTC(
    currentTime.getUTCFullYear(),
    currentTime.getUTCMonth(),
    currentTime.getUTCDate(),
    currentTime.getUTCHours(),
    currentTime.getUTCMinutes(),
    currentTime.getUTCSeconds(),
  )

  const timeDifferenceMs = targetTimeUTC - currentTimeUTC

  let remainingSeconds = Math.floor(timeDifferenceMs / 1000)
  let remainingMinutes = Math.floor(remainingSeconds / SECONDS_IN_MINUTE)
  remainingSeconds %= SECONDS_IN_MINUTE

  let remainingHours = Math.floor(remainingMinutes / MINUTES_IN_HOUR)
  remainingMinutes %= MINUTES_IN_HOUR

  const remainingDays = Math.floor(remainingHours / HOURS_IN_DAY)
  remainingHours %= HOURS_IN_DAY

  // const formattedTime = `${remainingDays}d ${remainingHours}h ${remainingMinutes}m ${remainingSeconds}s`

  const formattedTime = `${remainingDays > 0 ? `${remainingDays}d ` : ''}${
    remainingHours > 0 ? `${remainingHours}h ` : ''
  }${remainingMinutes > 0 ? `${remainingMinutes}m ` : ''}${
    remainingSeconds > 0 ? `${remainingSeconds}s` : ''
  }`

  return formattedTime.trim()
}

/**
 * Get the jwt if present in the local storage
 * @param null
 * @returns jwt token || null
 */
export function getAuthenticatedJWT() {
  const token = typeof window === 'object' ? localStorage.getItem('jwt') || null : null
  return token
}

/**
 * Get Lat long co ordinate
 * if spawn point exists return spawn point else get the center of building
 * @param buildingDetail Building info with center and spawnpoint
 * @returns lat lng co ordinate of spawin point or center.
 */
function getLatLngCoordinate(buildingDetail: any): string[] {
  const latLng: string[] = []
  if (buildingDetail && buildingDetail?.spawnPoint) {
    latLng.push(buildingDetail?.spawnPoint[1])
    latLng.push(buildingDetail?.spawnPoint[0])
  } else if (buildingDetail) {
    if (buildingDetail?.center?.coordinates) {
      latLng.push(buildingDetail?.center?.coordinates[1])
      latLng.push(buildingDetail?.center?.coordinates[0])
    } else {
      latLng.push(buildingDetail?.center[1])
      latLng.push(buildingDetail?.center[0])
    }
  }
  return latLng
}

/**
 * Get the deeplink url for the play mode app
 * @param lat - string
 * @param long - string
 * @returns string - deeplink url
 */
export function getDeepLinkUrlForPlay(buildingDetail: any) {
  const authenticatedToken = getAuthenticatedJWT()
  const latLng = getLatLngCoordinate(buildingDetail)
  let url = `${APP_BASE_LINK}?mode=play${authenticatedToken ? `&token=${authenticatedToken}` : ''}`
  if (latLng && latLng.length > 0) {
    url += `&latitude=${latLng[0]}&longitude=${latLng[1]}&building_id=${buildingDetail.id}`
  }
  return url
}

type GetDeepLinkUrl = {
  lat: number
  lng: number
  buildingId: string
  buildingLevelId?: string
  mode: GameMode
}

/**
 * Generates the deeplink game buttons for given params
 * @remarks
 * Check the doc for detail { @link https://docs.google.com/document/d/1qwrpgnmbOV6rAxkr0RKWVkoWdlkejZPi5WZGz4UQ_q0 }
 *
 * @param { Object } deepLinkParams - GetDeepLinkUrl
 * @param { number } deepLinkParams.lat
 * @param { number } deepLinkParams.lng
 * @param { string } deepLinkParams.buildingId
 * @param { string } deepLinkParams.mode - play or build
 * @returns { string }  url - deeplink url
 */

export function getDeepLinkUrl({
  lat,
  lng,
  buildingId,
  mode,
  buildingLevelId = null,
}: GetDeepLinkUrl) {
  const authenticatedToken = getAuthenticatedJWT()

  if (!authenticatedToken) {
    const previousPagePath = window.location.pathname
    const baseUrl = window.location.origin
    window.location.href = `${baseUrl}/${ROUTES.SignIn}?redirectTo=${encodeURIComponent(
      previousPagePath,
    )}&action=game-play`
    return null
  }

  const authenticatedTokenQuery = `&token=${authenticatedToken}`
  if (mode === GameMode.Auth) {
    return `${APP_BASE_LINK}?mode=${mode}${authenticatedTokenQuery}`
  }
  const url = `${APP_BASE_LINK}?mode=${mode}${authenticatedTokenQuery}&latitude=${lat}&longitude=${lng}&building_id=${buildingId}&building_lvl_id=${buildingLevelId}`
  return url
}

/**
 * Used by deeplink and websocket to hide and show game pop up
 * Retrieves the user's GToken from local storage.
 * If the token does not exist, generates a new one using uuidv4 and stores
 * it in local storage.
 *
 * @return {string} The user's GToken
 */
export function getUserGameTokenFromLocalStorage() {
  let token = localStorage.getItem('token')

  if (!token) {
    token = uuidv4()
    localStorage.setItem('token', token)
  }

  return token
}

/**
 * Get the default play url
 * @param lat
 * @param long
 * @returns string
 */
export function getDefaultDeepLinkUrlForPlay(lat: string, long: string) {
  const authenticatedToken = getAuthenticatedJWT()
  const url = `${APP_BASE_LINK}?mode=play&latitude=${lat}&longitude=${long}${
    authenticatedToken ? `&token=${authenticatedToken}` : ''
  }`
  return url
}

/**
 * Get the deeplink url for the build mode is app
 * @param buildingId - string
 * @returns string - deep link url
 */
export function getDeepLinkUrlForBuild(buildingId: string, buildingDetail: any) {
  const authenticatedToken = getAuthenticatedJWT()
  const latLng = getLatLngCoordinate(buildingDetail)
  let url = `${APP_BASE_LINK}?mode=build&building_id=${buildingId}${
    authenticatedToken ? `&token=${authenticatedToken}` : ''
  }`
  if (latLng && latLng.length > 0) {
    url += `&latitude=${latLng[0]}&longitude=${latLng[1]}`
  }
  return url
}

/**
 * Get the deep link url for the play in building level
 * @param buildingLevelId
 * @returns string - deeplink url
 */
export function getDeepLInkUrlForBuildingLevelPlay(buildingLevelId: string, buildingId: string) {
  const authenticatedToken = getAuthenticatedJWT()
  const url = `${APP_BASE_LINK}?mode=buildingLevel&building_lvl_id=${buildingLevelId}&building_id=${buildingId}${
    authenticatedToken ? `&token=${authenticatedToken}` : ''
  }`
  return url
}

/**
 * It will format number in redable from
 *If 1000=> 1k, 1,000,000=> 1M similarily to trillon and with 2 decimal points
 * @param number - number in numerical form
 * @returns string - in abbrebiated form
 */
export function abbreviateNumber(number: number): string {
  const prefixes = ['', 'k', 'M', 'B', 'T']
  const divisor = 1000
  let magnitude = 0

  while (number >= divisor && magnitude < prefixes.length - 1) {
    number /= divisor
    magnitude += 1
  }

  const precision = number % Math.floor(number) > 0 ? 2 : 0
  const suffix = prefixes[magnitude]
  const formattedNum = number.toFixed(precision)

  return formattedNum + suffix
}

/**
 * A placeholder to use while the image is loading.
 * When blur, the blurDataURL property will be used as the placeholder.
 *If src is an object from a static import and the imported image is .jpg, .png, .webp, or .avif, then blurDataURL will be automatically populated.
 * @param w - width of image
 * @param h - height of image
 */
export function blurDataURL(w: number, h: number) {
  const svgStr = `<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`
  const base64 =
    typeof window === 'undefined' ? Buffer.from(svgStr).toString('base64') : window.btoa(svgStr)
  return `data:image/svg+xml;base64,${base64}`
}

export function isTokenExpired() {
  // Get the token from localStorage
  const token = localStorage.getItem('jwt')

  if (!token) {
    // Token not found in localStorage
    return true
  }

  // Decode the token to access its payload
  const decodedToken = jwt.decode(token)

  // Get the expiration time from the decoded token
  const expirationTime = decodedToken?.exp

  // Get the current time in seconds (Unix timestamp)
  const currentTime = Math.floor(Date.now() / 1000)
  if (currentTime >= expirationTime) {
    localStorage.removeItem('jwt')
  }
  // Compare with the current time
  return currentTime >= expirationTime
}

export async function hasUserSessionExpired() {
  // Get the token from localStorage
  const token = localStorage.getItem('jwt')

  if (!token) {
    // Token not found in localStorage
    return true
  }

  const { data: status } = await getTokenStatus(token)
  if (!status) {
    return true
  }

  // Decode the token to access its payload
  const decodedToken = jwt.decode(token)

  // Get the expiration time from the decoded token
  const expirationTime = decodedToken?.exp

  // Get the current time in seconds (Unix timestamp)
  const currentTime = Math.floor(Date.now() / 1000)
  if (currentTime >= expirationTime) {
    localStorage.removeItem('jwt')
  }
  // Compare with the current time
  return currentTime >= expirationTime
}

export function getNestedValue(obj: Record<string, any>, keyPath: string) {
  if (!keyPath.includes('.')) {
    return obj[keyPath]
  }

  const keys: string[] = keyPath.split('.')
  let nestedValue: any = obj

  for (const key of keys) {
    if (nestedValue?.hasOwnProperty(key)) {
      nestedValue = nestedValue[key]
    } else {
      nestedValue = undefined
      break
    }
  }

  return nestedValue
}

export const hasDoubleSlashes = (text: string) =>
  text.indexOf('//') !== -1 && text.indexOf('//') !== text.lastIndexOf('//')

export const downloadFile = (response: BlobPart, fileName: string) => {
  const blob = new Blob([response], { type: 'application/octet-stream' })
  const blobURL = URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = blobURL
  anchor.download = fileName
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export const formatDuration = (from: Date, to: Date): string => {
  const duration = to.getTime() - from.getTime()

  const seconds = Math.floor(duration / 1000) // 1 second = 1000 milliseconds
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  const remainingSeconds = seconds % 60
  const remainingMinutes = minutes % 60
  const remainingHours = hours % 24

  let formattedDuration = ''
  if (days > 0) {
    formattedDuration += `${days}d `
  }
  if (remainingHours > 0) {
    formattedDuration += `${remainingHours}h `
  }
  if (remainingMinutes > 0) {
    formattedDuration += `${remainingMinutes}m `
  }
  // show seconds only if days is not available
  if (remainingSeconds > 0 && days === 0) {
    formattedDuration += `${remainingSeconds}s`
  }

  return (formattedDuration || '0s').trim()
}
export const getDebateDateFormat = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }
  return null
}
export const handleLongText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text
  }
  return `${text.slice(0, maxLength - 1)}…`
}

export const orderDataByKey = <T extends any[]>(data: T, key = 'createdAt'): Array<any> => {
  if (data?.every((obj) => obj.hasOwnProperty(key))) {
    return orderBy(data, [key], ['asc'])
  }
  return data
}

export enum GameMode3D {
  CGame = 'cgame',
  CGameMenu = 'cgamemenu',
  PhilosophyCenter = 'philosophycenter',
  MainProgress = 'mainprogress',
  SixYearStrategy = 'sixyearstrategy',
}

type ConstructPlayURL = {
  JWT?: string
  entityId: string
  sceneId?: string
  mode?: GameMode3D
}

export const constructPlayURL = ({
  JWT,
  entityId,
  mode = GameMode3D.CGame,
  sceneId = '',
}: ConstructPlayURL) => {
  const MODE_ENTITY = {
    cgame: 'challengeGameGridId',
    cgamemenu: 'majorAreaId',
    philosophycenter: 'sceneId',
    mainprogress: 'challengeGameGridId',
    sixyearstrategy: 'challengeGameGridId',
  }

  const url = `${UNREAL_DOWNLOAD_LINK}/?mode=${mode}&${
    MODE_ENTITY[mode]
  }=${entityId}&sceneId=${sceneId}&userToken=${getUserGameTokenFromLocalStorage()}`

  if (JWT) return `${url}&token=${JWT}`

  return url
}

/**
 * Checks whether the given 2D array contains a player cell.
 *
 * @param {CellProperty[][]} data - The 2D array of cell properties.
 * @return {boolean} Returns `true` if a player cell is found, `false` otherwise.
 */
export function hasPlayer(data: CellProperty[][]) {
  return data.some((row) =>
    row.some(
      (cell) =>
        cell.name === ChallengeGameCell.Player ||
        cell.items?.some((item) => item.name === ChallengeGameCell.Player),
    ),
  )
}

export function hasExit(data: CellProperty[][]) {
  return data.some((row) => row.some((cell) => cell.name === 'Exit'))
}

/**
 * Checks the validity of character cells in a 2D array.
 * @param data The 2D array of cell properties.
 * @returns An object containing validity status and message.
 */
export function checkCharacterCellsValidity(data: CellProperty[][]): {
  valid: boolean
  message: string
} {
  let errorMsg = 'Invalid Character cell without Scene at coordinate '
  let valid = true
  data.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      // @ts-ignore
      if (cell && cell.name === ChallengeGameCell.Character && !cell.editableProps?.sceneId) {
        valid = false
        errorMsg += `, (${colIndex}, ${rowIndex}) `
      }
    })
  })
  return { valid, message: valid ? '' : errorMsg }
}

interface UserData {
  zipCode?: string | null
  city?: string | null
  state?: string | null
  country?: string | null
}

export function formatAddress(userData: UserData) {
  const addressComponents = _.compact([
    userData?.zipCode,
    userData?.city,
    userData?.state,
    userData?.country,
  ])

  return _.join(addressComponents, ', ')
}

export const arrayToTree = <
  T extends { id?: string; parentId?: string | string[]; children?: T[] },
>(
  arr: T[],
  parent = null,
) =>
  arr
    ?.filter((item) => item?.parentId === parent)
    .map((child) => ({
      ...child,
      children: arrayToTree(arr, child?.id),
    }))

const URLComparatorMap = {
  EqualTo: 'EqualTo',
  Between: 'Between',
  In: 'In',
  BooleanIn: 'BooleanIn',
} as const

type URLFilterComparator = 'EqualTo' | 'Between' | 'In' | 'BooleanIn'
type URLFilterValue = string | string[]

export type URLFilter = Record<
  string,
  {
    value: URLFilterValue
    comparator: URLFilterComparator
  }
>

function combinations<T>(...arrays: T[][]): T[][] {
  return arrays.reduce(
    (acc, arr) => acc.flatMap((combination) => arr.map((element) => combination.concat(element))),
    [[]] as T[][],
  )
}

export const mapFilterQuery = (filters: URLFilter) => {
  const tempFilters = Object.keys(filters).map((column) => {
    const value = Array.isArray(filters[column].value)
      ? (filters[column].value as string[])
      : ([filters[column].value] as string[])

    const comparator = URLComparatorMap[filters[column].comparator]

    return [{ value: value.join(','), column, comparator }]
  })

  if (tempFilters.length === 0) {
    return {
      orConditions: [],
    }
  }

  const combinationsArray = combinations(...tempFilters)

  return {
    orConditions: combinationsArray.map((arr) => ({
      andConditions: arr,
    })),
  }
}

type UsePageParams = {
  defaultFilter?: URLFilter
  tableId?: string
}

export const usePageParams = ({ defaultFilter, tableId }: UsePageParams = {}) => {
  const router = useRouter()
  const prefix = tableId ? `${tableId}_` : ''

  const limit = Number(router.query[`${prefix}limit`] || DEFAULT_PAGINATION_LIMIT)
  const page = Number(router.query[`${prefix}page`] || 1)
  const search = router.query[`${prefix}search`] as string
  const sortingParams = qs.parse(router.query[`${prefix}sort`] as string) as {
    column: string
    arrange: string
  }
  const sorting = sortingParams.column
    ? {
        arrange: sortingParams.arrange as 'asc' | 'desc',
        column: sortingParams.column,
      }
    : undefined
  const filterQuery = router.query[`${prefix}filters`] as string

  const parsedQuery = mapFilterQuery({
    ...(defaultFilter || {}),
    ...(qs.parse(filterQuery as string, {
      allowDots: true,
      parseArrays: true,
      comma: true,
    }) as URLFilter),
  })

  return {
    pagination: {
      limit,
      page,
    },
    sorting,
    search,
    filters: parsedQuery,
  }
}

export const customStartCase = (str?: string) =>
  str
    ? str
        .split(' ')
        .map(
          (word) =>
            // Capitalize the first character of each word
            word.charAt(0).toUpperCase() + word.slice(1),
        )
        .join(' ') // Join the words back into a single string
    : str

/**
 * Checks if the input string is a valid JSON.
 *
 * @param {string} str - The input string to be checked.
 * @return {boolean} true if the input is a valid JSON, false otherwise.
 */
export const isValidJSON = (str: string) => {
  try {
    JSON.parse(str)
    return true
  } catch (error) {
    return false
  }
}

export const getFilters = (filterKey: string, tableId?: string): string[] => {
  const prefix = tableId ? `${tableId}_` : ''
  const filterQuery = Router.query[`${prefix}filters`] as string

  const filters = qs.parse(filterQuery, {
    allowDots: true,
    parseArrays: true,
    comma: true,
  })

  const filtersValue = filters as URLFilter

  return (
    (filtersValue?.[filterKey]?.value as string[]) ??
    (Array.isArray(filters?.[filterKey]) ? (filters?.[filterKey] as string[]) : undefined)
  )
}

export const setFilters = ({
  filterKey,
  value,
  tableId,
  prefKey,
}: {
  filterKey: string
  value: string[]
  tableId?: string
  prefKey?: string
}) => {
  const prefix = tableId ? `${tableId}_` : ''
  const filterQuery = Router.query[`${prefix}filters`] as string

  const filters = qs.parse(filterQuery, {
    allowDots: true,
    parseArrays: true,
    comma: true,
  }) as URLFilter

  if (value?.length) filters[filterKey] = { value, comparator: FilterComparators.In }
  else delete filters[filterKey]

  addPageFilters({ [`${prefix}filters`]: qs.stringify(filters) })

  if (prefKey) setUserPref(prefKey, value)
}

export const openTab = (url: string) => {
  const newTab = window.open(url, '_blank')
  if (newTab) {
    newTab.focus()
  }
}
export function RemoveInvalidFields(obj: any) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '' || obj[key]?.length === 0) {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      RemoveInvalidFields(obj[key])
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    }
  }
}

export function formatSnakeCase(str: string) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function convertMsToHMS(ms) {
  if (!ms) return '00:00'
  const seconds = Math.floor(ms / 1000)
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${remainingSeconds}s`
}
