import styled from '@emotion/styled'
import { Button } from '@mui/material'

import theme from '@theme'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DisplayIcon = styled.img`
  width: 50px;
  height: 50px;
`

export const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  padding: 1rem;
  border-radius: 100%;
  background-color: ${theme.palette.red2};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 1rem solid ${theme.palette.red2}80;
`

export const Title = styled.p`
  font-size: 1.625rem;
  font-weight: 600;
  text-align: center;
`

export const Description = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  max-width: 450px;
`

export const ActionButton = styled(Button)`
  border-radius: 8px;
  text-transform: uppercase;
`
