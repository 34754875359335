import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps,
} from '@mui/material'
import { ReactNode } from 'react'
import { DialogProps } from '@mui/material/Dialog/Dialog'
import LoadingButton from '@mui/lab/LoadingButton'

type Props = {
  title: string
  description?: string
  open: boolean
  agreeText?: string
  disagreeText?: string
  handleAgree: Function
  handleDisagree?: Function
  children?: ReactNode
  sx?: SxProps
  disableAgree?: boolean
  loading?: boolean
  hideDisagree?: boolean
  disableBackdropClick?: boolean
} & DialogProps

const DialogComponent = ({
  title,
  description,
  open,
  agreeText = 'Yes',
  disagreeText = 'Cancel',
  handleAgree,
  handleDisagree,
  children,
  disableAgree = false,
  loading = false,
  hideDisagree = false,
  sx,
  disableBackdropClick,
  ...props
}: Props) => {
  const onClose = (_, reason: string) => {
    if (reason === 'backdropClick' && disableBackdropClick) {
      return
    }
    if (handleDisagree) handleDisagree()
  }
  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={sx}>
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {!hideDisagree && (
          <Button onClick={() => handleDisagree && handleDisagree()} disabled={loading}>
            {disagreeText}
          </Button>
        )}
        <LoadingButton
          disabled={disableAgree}
          onClick={() => handleAgree && handleAgree()}
          loading={loading}
          autoFocus
        >
          {agreeText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DialogComponent
