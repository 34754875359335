import { useMemo } from 'react'
import useLogout from '@hook/user/useLogout'
import ROUTES from '@constant/Routes'
import { axiosInstance } from './api'

const WithAxios = ({ children }: { children: any }) => {
  const { logoutUser } = useLogout()
  useMemo(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const requestURL = error?.response?.request?.responseURL ?? ''
        if (error?.response?.status === 401 && !requestURL.includes('auth/login')) {
          // toast.warning('Please login to continue further.', {
          //   toastId: 'login-toast',
          // })
          // this is to remove redirectTo in our url while logging out
          const redirectPath = window.location.pathname.includes('signin')
            ? ''
            : `?redirectTo=${window.location.pathname}`
          logoutUser(false, `${ROUTES.SignIn}${redirectPath}`)
        }
        return Promise.reject(error)
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutUser])

  return children
}

export default WithAxios
