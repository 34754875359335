type EventType = 'updateIdValueMap'

interface EventPayload {
  key: string
  value: any
}

export function listenEvent(
  event: EventType,
  cb: (event: CustomEvent<EventPayload | null>) => void,
) {
  document.addEventListener(event, cb)
}

export function emitEvent(event: EventType, detail: EventPayload | null = null) {
  document.dispatchEvent(new CustomEvent(event, { detail }))
}

export function removeEventListener(event: EventType, listener: EventListener) {
  document.removeEventListener(event, listener)
}
