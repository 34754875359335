import { BRAND_NAME } from '@constant'
import ROUTES from '@constant/Routes'
import useLoadingOverlay from '@hook/loadingOverlay/useLoadingOverlay'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useRouter } from 'next/router'
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TypeLauncherContext } from 'types/launcher'

type Props = {
  children: React.ReactNode
}

const LauncherContext = createContext<TypeLauncherContext>(null)

const LauncherProvider = ({ children }: Props) => {
  const router = useRouter()
  const socket = useRef<WebSocket>()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [installed, setInstalled] = useState<TypeLauncherContext['installed']>()
  const [authenticated, setAuthenticated] = useState<TypeLauncherContext['authenticated']>()
  const { setLoadingOverlay } = useLoadingOverlay()

  const connectSocket = useCallback(
    () =>
      new Promise<boolean>((resolve, reject) => {
        socket.current = new WebSocket('ws://127.0.0.1:40107')
        const onConnect = () => {
          resolve(true)
        }
        socket.current.onopen = onConnect

        const onConnectError = (error) => {
          // socket.current.off('connect_error', onConnectError)
          reject(error)
        }
        socket.current.onerror = onConnectError
      }),
    [],
  )

  const contextValues = useMemo(
    () => ({
      socket,
      connectSocket,
      showDialog,
      installed,
      authenticated,
      setShowDialog,
      setInstalled,
      setAuthenticated,
    }),
    [
      socket,
      connectSocket,
      showDialog,
      installed,
      authenticated,
      setShowDialog,
      setInstalled,
      setAuthenticated,
    ],
  )

  useEffect(() => {
    setLoadingOverlay(showDialog)
    if (authenticated === 'not-authenticated') {
      router.push(ROUTES.Home)
    }
  }, [showDialog, authenticated])

  return (
    <LauncherContext.Provider value={contextValues}>
      {/* Demo Dialog For Desktop App for now, It needs to be replaced with proper UI */}
      {installed === 'loading' && (
        <Dialog
          open={showDialog}
          onClose={() => {
            setShowDialog(false)
          }}
        >
          <DialogTitle>
            Starting the
            {BRAND_NAME} Game Launcher
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              {installed === 'loading' && <CircularProgress />}
              {/* {installed === 'not-installed' && (
              <p>
                The
                {' '}
                {BRAND_NAME}
                {' '}
                Game Launcher does not appear to be installed.  Would you like to install it now?
                <br />
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  onClick={() => {
                    setShowDialog(false)
                    router.push('/download-launcher')
                    // window.open(LAUNCHER_DOWNLOAD_LINK, '_blank')
                  }}
                >
                  Download
                </Button>
              </p>
              )} */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>{/* <Button onClick={handleClose}>Disagree</Button> */}</DialogActions>
        </Dialog>
      )}
      {children}
    </LauncherContext.Provider>
  )
}

export { LauncherContext, LauncherProvider }
