/* eslint-disable react/jsx-props-no-spreading */
import 'nprogress/nprogress.css'
import 'react-toastify/dist/ReactToastify.css'
import 'css/video-react.css'
import 'css/global.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'css/ifttt.css'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'

// import { EtherProvider } from '@context/EtherContext'
import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Script from 'next/script'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import NoSSR from 'react-no-ssr'
import NextNProgress from 'nextjs-progressbar'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ToastPortal from '@element/ToastPortal'
import theme from '@theme'
import DefaultLayout from '@layout/Default/Default'
import { UserProvider } from '@context/UserContext'
import { LoadingOverlayProvider } from '@context/LoadingOverlayContext'
import { LauncherProvider } from '@context/LauncherContext'
import { AWSProvider } from '@context/AWSContext'
import { PROJECT, updateProject } from 'configs'
import { MAIN_MENU_ITEMS, USER_MENU_ITEMS } from '@constant/MainMenuItems'
import createEmotionCache from 'utils/createEmotionCache'
import WithAxios from '@api/with-axios'
import AppLayout from '@layout/AppLayout'
import { hasDoubleSlashes } from '@util/helpers'
import { publicPlayerProgress } from '@template/GameDashboard/constants'
import ErrorBoundary from '@element/ErrorBoundary'
import { GamePlayProvider } from '@context/GamePlayContext'
import { IdValueMapProvider } from '@context/IdValueMapContext'

Chart.register(ChartDataLabels)

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

Chart.register(CategoryScale, ChartDataLabels)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      cacheTime: 0,
    },
    mutations: {
      retry: 0,
    },
  },
})

type Page<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactNode) => ReactNode
}

type NewAppProps = AppProps & {
  Component: Page
  emotionCache?: EmotionCache
}

const App = (props: NewAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const router = useRouter()
  const getLayout =
    Component.getLayout ||
    ((page) => (
      <DefaultLayout menuItems={MAIN_MENU_ITEMS} userMenuItems={USER_MENU_ITEMS}>
        {page}
      </DefaultLayout>
    ))

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (hasDoubleSlashes(url)) {
        router.push('/404') // Redirect to your custom 404 page
      }
    }
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getItem = localStorage.getItem(publicPlayerProgress)
    if (
      getItem &&
      !router.asPath.includes('redirectTo=%2Fgame-dashboard%3') &&
      !router.pathname.includes('/game-dashboard')
    ) {
      localStorage.removeItem(publicPlayerProgress)
    }
  }, [router])
  // During server side rendering the window is undefined, thus it was not possible
  // to figure out the domain, thus project configuration accordingly so this line was added
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
  }, [])

  useEffect(() => updateProject(), [])

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{PROJECT.name}</title>
        <link rel="shortcut icon" href={`/favicon/${PROJECT.favicon[32]}`} />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
      </Head>
      {/* <Script id="gtm" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${PROJECT.gaCode}');
      `}
      </Script> */}

      {/* Google Tag Manager (noscript)  */}
      {/* <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${PROJECT.gaCode}`}
          height="0"
          width="0"
          title="Google Tag Manager"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript> */}
      {/* End Google Tag Manager (noscript) */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`,
        }}
      />
      {/* <Script */}
      {/*   async */}
      {/*   strategy="afterInteractive" */}
      {/*   src={`https://www.googletagmanager.com/gtag/js?id=${PROJECT.gaCode}`} */}
      {/* /> */}
      {/* <Script */}
      {/*   strategy="afterInteractive" */}
      {/*   dangerouslySetInnerHTML={{ */}
      {/*     __html: ` */}
      {/*       window.dataLayer = window.dataLayer || []; */}
      {/*       function gtag(){dataLayer.push(arguments);} */}
      {/*       gtag('js', new Date()); */}
      {/*       gtag('config', '${PROJECT.gaCode}', { */}
      {/*         page_path: window.location.pathname, */}
      {/*       }); */}
      {/*     `, */}
      {/*   }} */}
      {/* /> */}
      <NextNProgress showOnShallow={false} options={{ showSpinner: false }} />
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <WithAxios>
            <AWSProvider>
              <ThemeProvider theme={theme}>
                <LoadingOverlayProvider>
                  <LauncherProvider>
                    <GamePlayProvider>
                      <IdValueMapProvider>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <NoSSR>
                          <ErrorBoundary>
                            <AppLayout>{getLayout(<Component {...pageProps} />)}</AppLayout>
                            <ToastPortal />
                          </ErrorBoundary>
                        </NoSSR>
                      </IdValueMapProvider>
                    </GamePlayProvider>
                  </LauncherProvider>
                </LoadingOverlayProvider>
              </ThemeProvider>
            </AWSProvider>
          </WithAxios>
        </UserProvider>
      </QueryClientProvider>
    </CacheProvider>
  )
}

export default App
