export enum SortDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface Sort {
  direction: SortDirection
  field?: string
}

export interface QueryParams extends Pagination {
  searchParams?: string
  search?: string
  sorts?: Array<Sort>
  contentTypeId?: string
  contentTypeName?: string
  published?: boolean
  parentId?: string
}

export interface ErrorResponse {
  message: string
  statusCode: number
}

export interface Pagination {
  limit?: number
  page?: number
}

export type CompositeKeyValue<T = any> = {
  [key: string]: T
}

export type GetAllResponse<T> = {
  data: Array<T>
  count: number
  meta?: CompositeKeyValue
  sorts?: Array<Sort>
  limit?: number
  page?: number
  pagination?: {
    count?: number
  }
}

export type Identifier = {
  id: string
}

export type AddResponse<T> = {
  identifiers: Identifier[]
  generatedMaps: T[]
  raw: T[]
}

export interface DeleteUpdateResponse {
  generatedMaps: []
  raw: []
  affected: number
}

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

export enum GameMode {
  Play = 'play',
  Build = 'build',
  BuildingLevel = 'buildingLevel',
  Adventure = 'adventure',
  Ranch = 'ranch',
  Auth = 'auth',
}

export enum TimeTravel {
  TwoDays = '2_DAYS',
  TwoHours = '2_HOURS',
}

export type ItemKey = string | number

export interface Item<T = ItemKey> {
  key: T
  label: string
}

export interface MainMenuItem {
  name: string
  showOnlyUserRole?: boolean
  path?: string
  domain?: string
  icon?: React.ReactNode
  subItems?: MainMenuItem[]
  showSubItems?: boolean
  isCreatorOnly?: boolean
  newPageMenu?: boolean
  hideMenu?: boolean
  legacy?: boolean
  admin?: boolean
  keepParentMenuSelected?: boolean // to handle cases where the subitem has further subitems but we need the parent menu to remain selected
}

export type DomainHostList = {
  origin: string
  allowedMethods: string[]
}

export enum ChallengeGameCell {
  Scene = 'SCENE',
  Player = 'Player',
  Exit = 'Exit',
  Eraser = 'Eraser',
  Character = 'Character',
}

export type ListParams = {
  pagination: {
    page: number
    limit: number
  }
  sorting?: {
    arrange?: 'asc' | 'desc'
    column?: string
  }
  search?: string
  published?: boolean
  filters?: Filter
}

export const FilterComparators = {
  EqualTo: 'EqualTo',
  Between: 'Between',
  In: 'In',
} as const

export type AndCondition = {
  column: string
  comparator: 'EqualTo' | 'Between' | 'In' | 'BooleanIn' | 'HasValue'
  value: string | string[]
}

export type OrCondition = {
  andConditions: AndCondition[]
}

export type Filter = {
  orConditions: OrCondition[]
}
