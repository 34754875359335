import { ParsedUrlQuery } from 'querystring'
import Router from 'next/router'
import { UserPrefStorageKey } from '@type/usePref'

const pageQueryState = {
  queryQueue: [],
  isRouting: false,
}

export const addPageFilters = async (query: ParsedUrlQuery) => {
  if (pageQueryState.isRouting) {
    pageQueryState.queryQueue.push(query)
    return
  }

  pageQueryState.isRouting = true
  await Router.push(
    {
      query: {
        ...Router.query,
        ...query,
      },
    },
    undefined,
    {
      shallow: true,
    },
  )
  pageQueryState.isRouting = false

  if (pageQueryState.queryQueue.length) {
    const nextQuery = pageQueryState.queryQueue.shift()
    addPageFilters(nextQuery)
  }
}

export const setUserPref = (key: string, value: any) => {
  if (!key) return
  localStorage.setItem(
    UserPrefStorageKey,
    JSON.stringify({
      ...JSON.parse(localStorage.getItem(UserPrefStorageKey) || '{}'),
      [key]: value,
    }),
  )
}

export const getUserPref = (key: string) =>
  JSON.parse(localStorage.getItem(UserPrefStorageKey) || '{}')?.[key]

export const useUserPref = () => ({
  setUserPref,
  getUserPref,
})
