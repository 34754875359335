import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import useCurrentUser from '@hook/user/useCurrentUser'
import useLoadingOverlay from '@hook/loadingOverlay/useLoadingOverlay'
import {
  HIDE_PRIVATE_ROUTES,
  PRIVATE_ROUTES,
  PROTECTED_ROUTES,
  RoleType,
  CREATOR_PROTECTED_ROUTES,
} from '@constant'
import ROUTES from '@constant/Routes'
import { isTokenExpired } from '@util/helpers'
import useAccessToken from '@hook/user/useAccessToken'
import { TRUSTED_DOMAINS_REVERSED } from '@constant/SSO'

const useProtectedRoute = () => {
  const router = useRouter()
  const { loginUser } = useAccessToken()
  const [first, setIsFirst] = useState(true)
  const [showPayment, setShowPayment] = useState(false)
  const { sessionUser, currentUser, isLoading } = useCurrentUser()
  const { setLoadingOverlay } = useLoadingOverlay()

  const isProtectedRoute = useMemo<boolean>(
    () => PROTECTED_ROUTES.includes(router.pathname) || router.pathname.includes(ROUTES.AdminMain),
    [router.pathname],
  )
  const isPrivateRoute = useMemo<boolean>(
    () => PRIVATE_ROUTES.includes(router.pathname),
    [router.pathname],
  )

  useEffect(() => {
    setIsFirst(false)
  }, [])

  useEffect(() => {
    // block non admin out of admin pages
    if ((!sessionUser || !sessionUser?.isAdmin) && router.pathname.includes(ROUTES.AdminMain))
      router.push(ROUTES.Home)

    // show non paying user payment for creator protected routes
    if (CREATOR_PROTECTED_ROUTES.some((path) => router.pathname.includes(path))) {
      if (!sessionUser || !sessionUser?.roles?.includes(RoleType.CREATOR)) {
        if (!showPayment) setShowPayment(true)
      } else if (showPayment) setShowPayment(false)
    } else if (showPayment) setShowPayment(false)

    // block admin out of user story engine
    if ((!sessionUser || sessionUser?.isAdmin) && router.pathname.includes(ROUTES.UserStoryEngine))
      router.push(ROUTES.Home)

    if (
      sessionUser &&
      !sessionUser?.isAdmin &&
      !currentUser?.subscriptions.length &&
      router.pathname.includes(ROUTES.RiggedEconomyRiggedAreas)
    ) {
      router.push(`${process.env.NEXT_PUBLIC_HERO_APP_URL}${ROUTES.Payment}`)
      return () => {}
    }
    if (isProtectedRoute) {
      if (isLoading) {
        setLoadingOverlay(true)
        return () => {}
      }
      setLoadingOverlay(false)
      if (isTokenExpired() || !currentUser) {
        router.push({
          pathname: '/signin',
          query: {
            redirectTo: router.asPath,
          },
        })
      }
    } else {
      setLoadingOverlay(false)
    }

    // TODO: Remove this section to enable the private routes
    if (HIDE_PRIVATE_ROUTES && isPrivateRoute) {
      router.push({
        pathname: ROUTES.Home,
      })
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, sessionUser, isLoading, isProtectedRoute, isPrivateRoute, currentUser?.subscriptions])

  // FEATURE: SS0
  useEffect(() => {
    if (router.isReady && isProtectedRoute) {
      const { tempToken } = router.query

      if (tempToken !== undefined) {
        loginUser()
        return
      }

      // WHEN USER TRIES TO VISIT THE PROTECTED ROUTES THEN
      // REDIRECT THEM TO MAIN AUTH DOMAIN FOR SIGN IN
      if (isTokenExpired() && window.location.origin !== process.env.NEXT_PUBLIC_HERO_APP_URL) {
        window.location.href = `${process.env.NEXT_PUBLIC_HERO_APP_URL}/signin?origin=${
          TRUSTED_DOMAINS_REVERSED[window.location.origin]
        }`
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, isProtectedRoute])

  return { isProtectedRoute, currentUser, isLoading, first, showPayment }
}

export default useProtectedRoute
