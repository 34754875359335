import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'
import theme from '@theme'

export const SLButton = styled(Button)(() => ({
  borderRadius: '12px',
  minWidth: 64,
}))

export const SLInteriorButton = styled(Box)(() => ({
  fontWeight: '500',
  fontSize: '20px',
  padding: 0,
  color: theme.palette.neutralVariant100,
}))

export const SLExteriorButton = styled(Button)(({ sx }) => ({
  borderRadius: sx?.borderRadius ?? '10px',
  fontWeight: sx?.fontWeight ?? '700',
  fontSize: sx?.fontSize ?? '30px',
  padding: '0px',
  mb: '6px',
  textTransform: 'none',
  background: `linear-gradient(180deg, ${theme.palette.gradientEnd} 0%, ${theme.palette.gradientStart} 100%)`,
  width: sx?.width ?? '150px',
  height: sx?.height ?? '38px',
}))

export const SLOutlinedButton = styled(Button)(() => ({
  color: theme.palette.neutralVariant70,
  borderColor: theme.palette.neutralVariant70,
  border: `2px solid ${theme.palette.neutralVariant70}`,
  borderRadius: '10px',
  '&:hover': {
    color: theme.palette.neutralVariant70,
    borderColor: theme.palette.neutralVariant70,
    border: `2px solid ${theme.palette.neutralVariant70}`,
  },
}))
