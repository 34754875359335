import { Backdrop, CircularProgress, Portal } from '@mui/material'
import { createContext, useMemo, useState } from 'react'
import { TypeLoadingOverlayContext } from 'types/loadingOverlay'

type Props = {
  children: React.ReactNode
}

const LoadingOverlayContext = createContext<TypeLoadingOverlayContext>(null)

const LoadingOverlayProvider = ({ children }: Props) => {
  const [isShowingLoadingOverlay, setLoadingOverlay] = useState<boolean>(false)

  const contextValues: TypeLoadingOverlayContext = useMemo(
    () => ({
      isShowingLoadingOverlay,
      setLoadingOverlay,
    }),
    [isShowingLoadingOverlay, setLoadingOverlay],
  )

  return (
    <LoadingOverlayContext.Provider value={contextValues}>
      {children}
      {isShowingLoadingOverlay && (
        <Portal>
          <Backdrop
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
            }}
            open={isShowingLoadingOverlay}
          >
            <CircularProgress size={60} />
          </Backdrop>
        </Portal>
      )}
    </LoadingOverlayContext.Provider>
  )
}

export { LoadingOverlayContext, LoadingOverlayProvider }
