import { getAxios } from '@api/api'
import { DeleteUpdateResponse, GetAllResponse, ListParams, QueryParams } from '@type/common'
import {
  ChallengeGameCategory,
  ChallengeGameData,
  ChallengeGameDataGrid,
  ChallengeGameDataGridList,
  ChallengeGameDataResponse,
  ChallengeGameGridMetric,
  ChallengeGamePack,
  FactorVotingResultResponse,
  FixesVotingResultResponse,
  NextChallengeLevel,
  VotingResultDetailsResponse,
} from '@api/challenge-game'
import { Area } from '@type/area'
import { EntityInfoCallback } from '@type/tiktok-and-youtube-video'
import { ChallengeGamePoliticalData } from '@type/scene'
import { IFactorVotingResult } from '@type/vote'

export type MajorArea = {
  id: string
  areaId: string
  name: string
}

export const CHALLENGE_GAME_GRID_API_PATH = '/challenge-game-grid-data'
export const CHALLENGE_GAME_API_PATH = '/challenge-game'
export const VOTING_RESULT_GAME_API_PATH = '/voting-result'

export const MAJOR_AREA_API =
  '/story-engine/area/search-by-content-type-name?contentTypeName=Undergrounds'

export const MAP_LOCATIONS_API =
  '/story-engine/area/search-by-content-type-name?contentTypeName=Map Locations'

export const getOneChallengeGameGridApi = async (id: string): Promise<ChallengeGameDataGrid> => {
  const { data } = await getAxios().get(`${CHALLENGE_GAME_GRID_API_PATH}/${id}`)
  return data
}

export type ChallengeGameQueryParams = QueryParams & {
  areaId?: string
  tacticId?: string
  exclude?: Array<string>
}

export const getDreamRunForTeleporter = async (
  category: ChallengeGameCategory = ChallengeGameCategory.DreamRun,
) => {
  const { data } = await getAxios().get<Pick<ChallengeGameData, 'id' | 'gameName'>[]>(
    `/challenge-game/game/teleporter`,
    {
      params: {
        category,
      },
    },
  )
  return data
}

export const getAllChallengeGamesDetail = async (
  params: ChallengeGameQueryParams,
): Promise<GetAllResponse<ChallengeGameData>> => {
  const { data } = await getAxios().get<GetAllResponse<ChallengeGameData>>(
    '/challenge-game/detail',
    {
      params,
    },
  )
  return data
}

export const getDreamRunsByPhilPackId = async (
  params: ListParams,
  philosophyPackId: string,
): Promise<GetAllResponse<ChallengeGameData>> => {
  const { data } = await getAxios().get<GetAllResponse<ChallengeGameData>>(
    `/challenge-game/list/philosophy-pack/${philosophyPackId}`,
    {
      params,
    },
  )
  return data
}

export const getDreamRunsByRedBoxId = async (
  params: ListParams,
  redboxId: string,
): Promise<GetAllResponse<ChallengeGameData>> => {
  const { data } = await getAxios().get<GetAllResponse<ChallengeGameData>>(
    `/challenge-game/list/red-box/${redboxId}`,
    {
      params,
    },
  )
  return data
}

export const getAllChallengeGameApi = async (
  page: number,
  limit: number,
  published?: boolean,
): Promise<ChallengeGameDataResponse> => {
  const { data } = await getAxios().get(`${CHALLENGE_GAME_API_PATH}`, {
    params: { page, limit, published },
  })
  return data
}

export const getAllChallengeGamePacks = async () => {
  const { data } = await getAxios().get<ChallengeGamePack[]>('/challenge-game-pack')
  return data
}

export const getChallengeGames = async (params): Promise<ChallengeGameDataResponse> => {
  const { data } = await getAxios().get(CHALLENGE_GAME_API_PATH, {
    params,
  })
  return data
}

export const getAllChallengeGameLevels = async (params: ListParams) => {
  const { data } = await getAxios().get<GetAllResponse<ChallengeGameDataGridList>>(
    CHALLENGE_GAME_GRID_API_PATH,
    {
      params,
    },
  )
  return data
}

export const getClonableItems = async (params): Promise<ChallengeGameDataResponse> => {
  const { data } = await getAxios().get(`${CHALLENGE_GAME_API_PATH}/clonable-items`, {
    params,
  })
  return data
}

export const getUserGeneratedDreamRuns = async (userId: string, params) => {
  const { data } = await getAxios().get<GetAllResponse<ChallengeGameData>>(
    `${CHALLENGE_GAME_API_PATH}/user-contents/${userId}`,
    {
      params,
    },
  )
  return data
}

export const getChallengeGameNextLevel = async (id?: string): Promise<NextChallengeLevel> => {
  const { data } = await getAxios().get(
    `${CHALLENGE_GAME_API_PATH}/main-progress${id ? `/${id}` : ''}`,
  )
  return data
}

export const getAllChallengeGameByAreaIdApi = async (
  areaId: string,
): Promise<Array<ChallengeGameData>> => {
  const { data } = await getAxios().get(`${CHALLENGE_GAME_API_PATH}/area/${areaId}`)
  return data
}

export const getAllChallengeGamePackWithItems = async (): Promise<ChallengeGamePack[]> => {
  const { data } = await getAxios().get(`/challenge-game-pack`)
  return data
}

export const getAllChallengeGamePackWithItemsNested = async (): Promise<ChallengeGamePack[]> => {
  const { data } = await getAxios().get(`/challenge-game-pack/nested`)
  return data
}

export const getChallengeGameByIdApi = async (id: string): Promise<ChallengeGameData> => {
  const { data } = await getAxios().get(`${CHALLENGE_GAME_API_PATH}/${id}`)
  return data
}

export const getDreamRunsUserMetrics = async ({
  userId,
  params,
}: {
  userId: string
  params: ListParams
}) => {
  const { data } = await getAxios().get<GetAllResponse<any>>(
    `${CHALLENGE_GAME_API_PATH}/user-logs/${userId}`,
    {
      params,
    },
  )
  return data
}

export const getCharacterAndItemsDetails = async (
  id: string,
): Promise<ChallengeGamePoliticalData> => {
  const { data } = await getAxios().get(`${CHALLENGE_GAME_API_PATH}/${id}/political-puzzle-data`)
  return data
}

export const getMajorAreas = async (): Promise<Area[]> => {
  const { data } = await getAxios().get(`${MAJOR_AREA_API}`)
  return data
}

export const getMapLocations = async (): Promise<MajorArea[]> => {
  const { data } = await getAxios().get(`${MAP_LOCATIONS_API}`)
  return data
}

export const saveChallengeGameApi = async ({
  challengeGameData,
  entityInfoCallback,
}: {
  challengeGameData: ChallengeGameData
  entityInfoCallback?: EntityInfoCallback
}): Promise<ChallengeGameData> => {
  const payload = entityInfoCallback
    ? { ...challengeGameData, entityInfoCallback }
    : challengeGameData
  const { data } = await getAxios().post(`${CHALLENGE_GAME_API_PATH}`, payload)
  return data
}

export const setDeeplink = async (queryData: { query: string }) => {
  const { data } = await getAxios().post('/story-engine/deeplink', queryData)
  return data
}

export const updateChallengeGameApi = async (
  challengeGameData: Omit<ChallengeGameData, 'assemblyGroup'> & {
    assemblyGroup?: string
    regenerate?: boolean
  },
): Promise<ChallengeGameData> => {
  const { data } = await getAxios().put(
    `${CHALLENGE_GAME_API_PATH}/${challengeGameData?.id}`,
    challengeGameData,
  )
  return data
}

export const cloneChallengeGameApi = async (id: string) => {
  const { data } = await getAxios().post(`${CHALLENGE_GAME_API_PATH}/clone/${id}`)
  return data
}

export const assignGridGame = async ({
  id,
  challengeGameId,
}: {
  id: string
  challengeGameId: string
}) => {
  const { data } = await getAxios().patch(
    `${CHALLENGE_GAME_GRID_API_PATH}/game/${id}/${challengeGameId}`,
  )
  return data
}

export const pushChallengeGameToProdApi = async (id: string) => {
  const { data } = await getAxios().post(`/transfer/initiate`, {
    id,
    table: 'ChallengeGame',
  })
  return data
}

export const saveChallengeGameGridApi = async (
  challengeGameDataGrid: ChallengeGameDataGrid,
): Promise<ChallengeGameDataGrid> => {
  const modifiedGrid = challengeGameDataGrid.data.map((row) =>
    row.map((obj) => {
      // Create a copy of the object without the "value" property
      // the value has react image component in it, so we need to remove it
      // the value was assigned react component to display image in spread sheet( level editor)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { value, ...newObj } = obj
      return newObj
    }),
  )

  // Assign the modified array back to challengeGameGrid.data
  challengeGameDataGrid.data = modifiedGrid
  const { data } = await getAxios().post(CHALLENGE_GAME_GRID_API_PATH, challengeGameDataGrid)
  return data
}

export const updateChallengeGameGridApi = async (
  challengeGameDataGrid: ChallengeGameDataGrid,
): Promise<ChallengeGameDataGrid> => {
  if (challengeGameDataGrid.data) {
    const modifiedGrid = challengeGameDataGrid.data.map((row) =>
      row
        .map((obj) => {
          if (obj.items) {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of obj.items) {
              delete item?.value
            }
          }

          // Create a copy of the object without the "value" property
          delete obj?.value
          return obj
        })
        .filter((obj) => obj.X <= 24 && obj.Y <= 24),
    )

    // Assign the modified array back to challengeGameGrid.data
    challengeGameDataGrid.data = modifiedGrid
  }
  const { data } = await getAxios().put(
    `${CHALLENGE_GAME_GRID_API_PATH}/${challengeGameDataGrid?.id}`,
    challengeGameDataGrid,
  )
  return data
}

export const publishToggleDreamRun = async ({
  id,
  published,
  category,
}: {
  id: string
  published: boolean
  category?: ChallengeGameCategory
}) => {
  const { data } = await getAxios().patch(`${CHALLENGE_GAME_API_PATH}/publish/${id}`, {
    published,
    category,
  })
  return data
}

export const deleteChallengeGameGridApi = async (id: string) => {
  const { data } = await getAxios().delete<DeleteUpdateResponse>(
    `${CHALLENGE_GAME_GRID_API_PATH}/${id}`,
  )
  return data
}

export const deleteChallengeGame = async (id: string) => {
  const { data } = await getAxios().delete<DeleteUpdateResponse>(`${CHALLENGE_GAME_API_PATH}/${id}`)
  return data
}

export const cloneChallengeGame = async (id: string) => {
  const { data } = await getAxios().post<ChallengeGameData>(
    `${CHALLENGE_GAME_API_PATH}/clone/${id}`,
    {
      id,
    },
  )
  return data
}

export const getAllDreamRunByPhilosophyPackId = async (philosophyPackId: string) => {
  const { data } = await getAxios().get<ChallengeGameData[]>(
    `${CHALLENGE_GAME_API_PATH}/philosophy-pack/${philosophyPackId}`,
  )
  return data
}

export const generateChallengeGameElementsFromGameYaml = async (id: string) => {
  const { data } = await getAxios().post(
    `${CHALLENGE_GAME_API_PATH}/${id}/generate-challenge-game-elements`,
  )
  return data
}

export const cloneChallengeGameGridApi = async (id: string) => {
  const { data } = await getAxios().post(`${CHALLENGE_GAME_GRID_API_PATH}/clone/${id}`)
  return data
}

export const getChallengeGameGridMetric = async (params: ListParams) => {
  const { data } = await getAxios().get<GetAllResponse<ChallengeGameGridMetric>>(
    `${CHALLENGE_GAME_GRID_API_PATH}/metrics`,
    {
      params,
    },
  )
  return data
}

export const getFixesVotingResult = async (id: string): Promise<FixesVotingResultResponse> => {
  const { data } = await getAxios().get(`${VOTING_RESULT_GAME_API_PATH}/tactic/${id}`)
  return data
}

export const getFactorVotingResults = async (id: string): Promise<FactorVotingResultResponse> => {
  const { data } = await getAxios().get(`${VOTING_RESULT_GAME_API_PATH}/challenge-game/${id}`)
  return data
}

export const getVotingFactorResults = async (
  id: string,
  details?: boolean,
): Promise<IFactorVotingResult | VotingResultDetailsResponse> => {
  const { data } = await getAxios().get(
    `${VOTING_RESULT_GAME_API_PATH}/voting-factor/${id}${details ? '?details=true' : ''}`,
  )
  return data
}

export const getTacticResults = async (id: string): Promise<VotingResultDetailsResponse> => {
  const { data } = await getAxios().get(`${VOTING_RESULT_GAME_API_PATH}/tactic-result/${id}`)
  return data
}

export const getFixesVotingResultDetails = async (
  id: string,
): Promise<VotingResultDetailsResponse> => {
  const { data } = await getAxios().get(`${VOTING_RESULT_GAME_API_PATH}/tactic/${id}/details`)
  return data
}

export const getFactorVotingResultDetails = async (
  id: string,
  votingFactorId,
): Promise<VotingResultDetailsResponse> => {
  const { data } = await getAxios().get(
    `${VOTING_RESULT_GAME_API_PATH}/challenge-game/${id}/details/${votingFactorId}`,
  )
  return data
}

export const getSixYearStrategyForArea = async (areaId: string) => {
  const { data } = await getAxios().get<ChallengeGameData[]>(
    `challenge-game/six-year/area/${areaId}`,
  )
  return data
}
