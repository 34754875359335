import LegalLinks from '@constant/LegalLinks'

export const endtheRigConfig = {
  name: 'End the Rig',
  mainDomain: 'endtherig',
  logo: 'https://d37tchdccajyhz.cloudfront.net/static/endtherig-logo.png',
  gaCode: process.env.NEXT_PUBLIC_GTM_CODE,
  privacyPolicy: LegalLinks.PrivacyPolicy,
  marketingVideoId: 'LN2FvLCsqDM',
  marketingIntroVideoId: process.env.NEXT_PUBLIC_MARKETING_INTRO_VIDEO_ID,
  favicon: {
    32: 'endtherig-favicon.ico',
    16: 'endtherig-favicon.ico',
    apple: 'endtherig-favicon.ico',
  },
  theme: {
    primary: {
      main: '#2843E8',
      dark: '#3950DA',
    },
    buttonGradients: {
      gradientStart: '#4AA0FF',
      gradientEnd: '#025CC0',
    },
  },
  paymentMenu: true,
}
