import { LoadingOverlayContext } from '@context/LoadingOverlayContext'
import { useContext } from 'react'

export default function useLoadingOverlay() {
  const { isShowingLoadingOverlay, setLoadingOverlay } = useContext(LoadingOverlayContext)
  return {
    isShowingLoadingOverlay,
    setLoadingOverlay,
  }
}
