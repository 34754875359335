/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import get from 'lodash/get'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { getAssetURI } from '@util/helpers'
import theme from '@theme'

import ROUTES from '@constant/Routes'
import { PROJECT } from 'configs'
import useCurrentUser from '@hook/user/useCurrentUser'
import { riggedEconomyConfig } from 'configs/riggedEconomy'
import { congressMemberConfig } from 'configs/congressMember'
import UserAvatar from '../UserAvatar/UserAvatar'
import { MenuItem } from '../Drawer/types/types'

const UserActionContainer = styled(List)`
  background-color: ${({ theme }) => theme.palette.background.nav2};
  padding: 0;

  &:hover {
    background: ${({ theme }) => theme.palette.background.nav2};
  }
  & .MuiListItemButton-root .MuiTypography-root {
    font-weight: 600 !important;
    font-size: ${({ theme }) => theme.typography.body2};
  }
  & > .MuiListItemButton-root .MuiListItemIcon-root {
    min-width: 35px;
  }
`

const UserMenuItem = styled(Button)<{ component?: React.ElementType }>({
  width: '100%',
  border: 'none',
  size: 'small',
  variant: 'text',
  color: theme.palette.text.primary,
  textTransform: 'none',
  justifyContent: 'left',
})
type Props = {
  userMenuItems?: MenuItem[]
  selectedMenuName?: string
  onSelect?: (MenuItem) => void
  currentUser?: {
    firstname?: string
    lastname?: string
    creatorCredits?: string
    profilePicture?: {
      filename: string
      location: string
    }
  }
  logoutUser?: () => void
  showCreatorCredit?: boolean
}

const DrawerUser = ({
  // eslint-disable-next-line no-unused-vars
  selectedMenuName,
  userMenuItems,
  onSelect,
  currentUser,
  logoutUser,
  showCreatorCredit = false,
}: Props) => {
  const router = useRouter()

  const { userName } = useCurrentUser()

  const [expanded, setExpanded] = useState<boolean>(false)

  if (!currentUser) {
    return <Skeleton />
  }

  const navigateToSettings = () => {
    if ([riggedEconomyConfig.homePage, congressMemberConfig.homePage].includes(PROJECT.homePage)) {
      window.open(`${process.env.NEXT_PUBLIC_HERO_APP_URL}${ROUTES.SettingsMyProfile}`, '_blank')
    } else {
      router.push(ROUTES.Settings)
    }
  }

  return (
    <UserActionContainer>
      <style>
        {`
          .user-dropdown:hover {
            background-color: transparent;
          }

          .user-drawer span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `}
      </style>
      <ListItemButton
        disableRipple
        onClick={() => {
          setExpanded((v) => !v)
        }}
        sx={{ padding: 1 }}
      >
        <ListItemIcon>
          {get(currentUser, 'profilePicture') ? (
            <UserAvatar src={getAssetURI(currentUser.profilePicture)} height={43} width={43} />
          ) : (
            <UserAvatar height={43} width={43} text={userName[0]} />
          )}
        </ListItemIcon>
        <Stack direction="column" sx={{ marginLeft: 0.5 }}>
          <ListItemText
            sx={{
              width: '100px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            primary={userName}
            title={userName}
            className="user-drawer"
          />
        </Stack>
        <Button
          disableRipple
          className="user-dropdown"
          sx={{
            color: 'inherit',
            p: 0,
            minWidth: '40px',
            justifyContent: 'end',
            width: '100%',
            backgroundColor: 'transparent',
          }}
        >
          {/* though the wording is opposite, these are just icons and this suits for better visual placement */}
          {expanded ? <ExpandMore /> : <ExpandLess />}
        </Button>
      </ListItemButton>
      {expanded && <Divider />}
      {userMenuItems && (
        <Collapse
          orientation="vertical"
          in={expanded}
          timeout="auto"
          unmountOnExit
          sx={{
            paddingLeft: '40px',
          }}
        >
          <UserMenuItem disableRipple onClick={() => navigateToSettings()}>
            Settings
          </UserMenuItem>
          {/* <UserMenuItem
            disableRipple
            onClick={() => {
              window.open(LegalLinks.TermsAndConditions, '_blank')
            }}
          >
            Terms & services
          </UserMenuItem> */}
          <UserMenuItem disableRipple onClick={() => window.open(PROJECT.privacyPolicy, '_blank')}>
            Privacy policy
          </UserMenuItem>
          <UserMenuItem
            disableRipple
            onClick={() => window.open(`${process.env.NEXT_PUBLIC_HERO_APP_URL}${ROUTES.Download}`)}
          >
            Downloads
          </UserMenuItem>
          <UserMenuItem
            disableRipple
            onClick={async () => {
              await logoutUser()
            }}
            sx={{ color: theme.palette.red }}
          >
            Logout
          </UserMenuItem>
        </Collapse>
      )}
    </UserActionContainer>
  )
}

export default DrawerUser
