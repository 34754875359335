/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable  no-unused-vars */
import ROUTES from './Routes'
import { IsHeroOnEarth, PROJECT } from '../configs'
/* eslint-disable import/prefer-default-export */

export const PAYMENT_MENU = PROJECT.paymentMenu
export const BRAND_NAME = PROJECT.name
export const HOME_PAGE = PROJECT.homePage
export const DRAWER_WIDTH = 230
export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const APP_API_URL = process.env.NEXT_PUBLIC_APP_API_URL
export const WS_URL = process.env.NEXT_PUBLIC_WS_URL
export const RPC_URL = process.env.NEXT_PUBLIC_RPC_URL
// Env constants
export const IS_DEVELOPMENT = process.env.NEXT_PUBLIC_MODE === 'development'
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_MODE === 'production'
export const IS_TESTING = process.env.NEXT_PUBLIC_MODE === 'testing'
export const IS_STAGING = process.env.NEXT_PUBLIC_MODE === 'staging'
export const IS_ADMIN_CLIENT = process.env.NEXT_PUBLIC_APP_FOR === 'admin_client'
export const QUEST_URL = `${process.env.NEXT_PUBLIC_APP_URL}/game-dashboard/q/`
export const MEDICAL_ICD_CODE_BASE_URL =
  'https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms='
export const TRICORDER_APP_URL = process.env.NEXT_PUBLIC_TRICORDER_APP_URL

// Blockchain constants
export const SMARTCONTRACTS_DIR = process.env.NEXT_PUBLIC_LOCAL_SMARTCONTRACTS_DIR
export const LOCAL_CHAINID = 31337
export const TESTING_CHAINID = 9650
export const STAGING_CHAINID = 80001
export const PRODUCTION_CHAINID = 137
let chain = LOCAL_CHAINID
let networkInfo = null
if (IS_TESTING) {
  chain = TESTING_CHAINID
  networkInfo = {
    chainId: `0x${chain.toString(16)}`,
    rpcUrls: [RPC_URL],
    chainName: 'Polygon Private',
    nativeCurrency: {
      name: 'tMatic',
      symbol: 'tMatic',
      decimals: 18,
    },
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  }
} else if (IS_PRODUCTION) {
  chain = PRODUCTION_CHAINID
  networkInfo = {
    chainId: `0x${chain.toString(16)}`,
    rpcUrls: [RPC_URL],
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'Matic',
      decimals: 18,
    },
    blockExplorerUrls: ['https://polygonscan.com'],
  }
} else if (IS_STAGING) {
  chain = STAGING_CHAINID
  networkInfo = {
    chainId: `0x${chain.toString(16)}`,
    rpcUrls: [RPC_URL],
    chainName: 'Mumbai',
    nativeCurrency: {
      name: 'tMatic',
      symbol: 'tMatic',
      decimals: 18,
    },
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  }
}
export const CHAIN_ID = chain
export const GAS_PRICE = 225000000000 // 225 nAVAX
// AWS constants
export const S3_URL_CONTRACT_ARTIFACTS = 'smartcontracts/hardhat_contracts.json'
export const AWS_KEY_ID = process.env.NEXT_PUBLIC_AWS_KEY
export const AWS_SECRET_KEY = process.env.NEXT_PUBLIC_AWS_SECRET
export const S3_BUCKET = 'earth9'
export const AWS_REGION = 'us-east-1'

// Launcher download link
export const LAUNCHER_DOWNLOAD_LINK = process.env.NEXT_PUBLIC_LAUNCHER_DOWNLOAD_LINK

// Check windows
export const IS_WINDOWS =
  typeof window !== 'undefined' ? navigator.userAgent.toUpperCase().indexOf('WIN') >= 0 : false

// Maps
export const MAPBOX_ACCESS_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN
export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY
export const DEFAULT_PAGE_TABLE_LENGTH = 20
export const DEFAULT_MAX_BID_MULTIPLE = 2
export const DEFAULT_MIN_BID_INCREASE = 5
export const DEFAULT_MIN_BID_INCREASE_AFTER_50 = 10
export const DEFAULT_MIN_BID_INCREASE_AFTER_100 = 15
export const DEFAULT_MIN_BID_INCREASE_AFTER_200 = 20
export const DEFAULT_CREATOR_CREDIT_INCREASE = 0.1 // 10 percent of the amount

export const DEFAULT_MAX_BID_INCREASE = 10
export const DEFAULT_TABLE_ROW_HEIGHT = 12

export const DEPOSIT_ADDRESS = process.env.NEXT_PUBLIC_AMOUNT_DEPOSIT_ADDRESS
export const RATE_API = 'https://min-api.cryptocompare.com'
export const RATE_API_TOKEN = 'ETH'

// Blockchain network params to switch or add network in metamask
export const BLOCKCHAIN_NETWORK_PARAMS = networkInfo

// Propose New Property Fee
export const PROPOSE_NEW_PROPERTY_FEE = 25
export const FIRST_AUCTION_BID = 25

// Protected routes array
export const PROTECTED_ROUTES = [
  ROUTES.MyProfileWalletSettings,
  ROUTES.GameDashBoard,
  ROUTES.SettingsMyProfile,
  ROUTES.SettingsChangePassword,
  ROUTES.PlotVotesList,
  ROUTES.CreatorSubmission,
  ROUTES.PlotForVotes,
  ROUTES.CreatorVoteDetaills,
  ROUTES.Auth,
  ROUTES.MyAccount,
  ROUTES.Settings,
  ROUTES.SettingsChangeEmail,
  ROUTES.CreatorsVoting,
  ROUTES.Payment,
  ROUTES.SettingBillingPlans,
  ROUTES.SettingBillingSubscriptions,
  ROUTES.SettingBillingPaymentHistory,
  ROUTES.SettingPaymentMethod,
  ROUTES.MyGame,
  ROUTES.AdminMain,
  ROUTES.AICenter,
  ROUTES.AdminNeededPrompts,
  ROUTES.AdminVisualComponents,
  ROUTES.AdminQuest,
  ROUTES.AdminQuests,
  ROUTES.AdminAddQuest,
  ROUTES.AdminStatues,
  ROUTES.AdminBooks,
  ROUTES.AdminMemes,
  ROUTES.AdminMajorAreas,
  ROUTES.AdminTiktoks,
  ROUTES.AdminMyGame,
  ROUTES.WorkerDefenseTrueInflationDataDashboard,
  ROUTES.WorkerDefenseTrueInflationAddDataSource,
  ROUTES.BuildingsVote,
  ROUTES.Buildings,
  ROUTES.BuildingsBuild,
  ROUTES.BuildingsDetails,
  ROUTES.BuildingsDetailsPath,
  ROUTES.PlotForVotes,
  ROUTES.PlotVotesList,
  ROUTES.CreatorVoteDetaills,
  ROUTES.CreatorSubmission,
  ROUTES.CreatorsVoting,
  ROUTES.UserStoryEngine,
]

export const CREATOR_PROTECTED_ROUTES = [
  ROUTES.UserDreamRuns,
  ROUTES.UserScenes,
  ROUTES.UserSixYear,
]

export const AUTH_ROUTES = [
  ROUTES.SignIn,
  ROUTES.SignUp,
  ROUTES.UserRegister,
  ROUTES.ForgotPassword,
  ROUTES.ResetPassword,
]

// TODO: Hidden routes for the investor
export const PRIVATE_ROUTES = [
  // ROUTES.InvestorHomepage,
  // ROUTES.InvestorAuctions,
  // ROUTES.AuctionsResale,
  // ROUTES.MyProperties,
  // ROUTES.ForSale,
  // ROUTES.InvestorLocations,
  // ROUTES.InvestorROI,
  // '/investors/auction/bid/[propertyId]',
  // '/investors/auction/bid/[propertyId]/payment',
  // ROUTES.InvestorProposeNewProperty,
  ROUTES.MyProfileWalletSettings,
]

// Marketing youtube video
export const MARKETING_YOUTUBE_VIDEO_ID = IsHeroOnEarth() ? 'KKTU6zDYrBw' : 'LN2FvLCsqDM'
// Metamask Download Link
export const METAMASK_DOWNLOAD_LINK =
  'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'

export const STRIPE_API_URL = 'https://api.stripe.com/v1'
export const STRIPE_HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY}`,
}

// Auction status
export const AUCTION_STATUS_ACTIVE = 'active'
export const AUCTION_STATUS_CLOSED = 'closed'

export const AUCTION_TYPE_CASH = 'CASH'
export const AUCTION_TYPE_CREATOR_CREDIT = 'CREATOR_CREDIT'

export const CASH = 'CASH'
export const CREATOR_CREDIT = 'CREATOR_CREDIT'

export const HIDE_PRIVATE_ROUTES = process.env.NEXT_PUBLIC_HIDE_PRIVATE_ROUTES

// Captcha site key
export const CAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY

// user type
export const USER_TYPE_CREATOR = 'creator'
export const USER_TYPE_VISITOR = 'visitor'
export const USER_TYPE_PLAYER = 'player'

// role type
export const ROLE_TYPE_ADMIN = 'ADMIN'
export const ROLE_TYPE_SUPER_ADMIN = 'SUPER_ADMIN'
export const ROLE_TYPE_USER = 'USER'
export const ROLE_TYPE_CREATOR = 'CREATOR'

// vote type
export const VOTE_TYPE_DOWN = 'DOWN_VOTE'
export const VOTE_TYPE_UP = 'UP_VOTE'

export const UNREAL_DOWNLOAD_LINK = process.env.NEXT_PUBLIC_GAME_BASE_LINK

export const UNREAL_WINDOWS_INSTALLER_DOWNLOAD_LINK = `${process.env.NEXT_PUBLIC_S3_BASE_URI}/${process.env.NEXT_PUBLIC_UNREAL_INSTALLER_WINDOWS_DOWNLOAD_LINK}`

export const APP_WINDOWS_DOWNLOAD_LINK = `${process.env.NEXT_PUBLIC_S3_BASE_URI}/${process.env.NEXT_PUBLIC_WINDOWS_DOWNLOAD_LINK}`
export const APP_MACOS_DOWNLOAD_LINK = `${process.env.NEXT_PUBLIC_S3_BASE_URI}/${process.env.NEXT_PUBLIC_MACOS_DOWNLOAD_LINK}`
export const APP_MACOS_DOWNLOAD_STORE = `${process.env.NEXT_PUBLIC_MACOS_DOWNLOAD_STORE}`
export const APP_BASE_LINK = process.env.NEXT_PUBLIC_GAME_BASE_LINK
export const APP_IOS_DOWNLOAD_LINK = process.env.NEXT_PUBLIC_IOS_DOWNLOAD_LINK
export const APP_IOS_DOWNLOAD_STORE = process.env.NEXT_PUBLIC_IOS_DOWNLOAD_STORE
export const APP_ANDROID_DOWNLOAD_LINK = process.env.NEXT_PUBLIC_ANDROID_DOWNLOAD_LINK
export const APP_STEAM_DOWNLOAD_LINK = process.env.NEXT_PUBLIC_STEAM_DOWNLOAD_LINK
export const APP_WINDOWS_STORE_DOWNLOAD_LINK = process.env.NEXT_PUBLIC_WINDOWS_STORE_DOWNLOAD_LINK

export const S3_BASE_URI = process.env.NEXT_PUBLIC_CLOUD_FRONT_URI

// OS Types of user
export const OS_WINDOWS = 'Windows'
export const OS_MAC = 'MacOS'
export const OS_LINUX = 'Linux'
export const OS_UNIX = 'UNIX'

export const VALID_IMAGE_EXTENSIONS = ['image/png', 'image/jpeg', 'image/jpg']
// Creator submission sttus
export const UNDER_REVIEW = {
  title: 'UNDER_REVIEW',
  value: 'Pending',
}

export const APPROVED = {
  title: 'APPROVED',
  value: 'Approved',
}

export const REJECTED = {
  title: 'REJECTED',
  value: 'Rejected',
}

export const TEMP_VIDEO = 'https://www.youtube.com/watch?v=AeobJI75yVs'

export const PLACE_HOLDER_IMAGE = '/images/quest-placeholder.png'

export const MEDIA_QUERIES = {
  small: '@media screen and (max-width: 767px)',
  medium: '@media screen and (min-width: 768px)',
  large: '@media screen and (min-width: 1024px)',
  xlarge: '@media screen and (min-width: 1200px)',
  ultra: '@media screen and (min-width: 3840px)',
}

export const DEFAULT_RANCH = {
  lat: 38.022819997123,
  lng: -107.671410826198,
}

export const MAX_GEM_SLOT = 5

export const SURFING_TIMER = +process.env.NEXT_PUBLIC_SURFING_TIMER

export enum WS_EVENT {
  PLAYER_GAME_INFO = 'player_game_info',
  PLAYER_ACTIVE_GEM_SLOTS = 'player_active_gem_slots',
  SURFING_CONTENT_CHANGE = 'surfing-content-change',
  TRICORDER_CONTENT_CHANGE = 'tricoder-content-change',
  TRICORDER_PAGE_STATUS = 'tricoder-page-status',
  SURFING_TIME_EXPIRED = 'surfing_time_expired',
  PLAYER_SUBSCRIPTION = 'new_subscription',
  ASSEMBLY_MISSION_PROGRESS = 'assembly_run_progress',
  PHILOSOPHY_PACK_CONTENT_CHANGE = 'philosophy_pack_content_change',
  PHILOSOPHY_PACK_PAGE_STATUS = 'philosophy_pack_page_status',
  GAME_OPENED = 'game_opened',
  NEW_PHILOSOPHIC_PACK_QUEST_ADDED = 'new_philosophic_pack_quest_added',
  NEW_PHILOSOPHIC_TIKTOK_AND_YOUTUBE_VIDEOS_ADDED = 'new_philosophic_tiktok_and_youtube_video_added',
  NEW_PHILOSOPHIC_DREAM_RUN_ADDED = 'new_philosophic_dream_run_added',
}

export const REQUIREMENT_GAME_MAP = {
  buildingLevels: {
    message: 'Please come back later after solving $TEMP$ quests.',
    accessor: 'completedBuildingLevels',
  },
  buildings: {
    message: 'Please come back later after construction $TEMP$ buildings.',
    accessor: 'liveConstructions',
  },
  gameLevel: {
    message: 'Please come back later once you reach level $TEMP$.',
    accessor: 'gameLevel',
  },
  valueEngineCount: {
    message: 'Please come back later after building $TEMP$ value engines.',
    accessor: 'valueEngineCount',
  },
  valuesFactory: {
    message: 'Please come back later after activating values factory.',
    accessor: 'valuesFactoryAt',
  },
  statues: {
    message: 'Please come back later after having $TEMP$ statue.',
    accessor: 'unlockedStatues',
  },
  greenGem: {
    message: 'Please come back later with $TEMP$ green gem.',
    accessor: 'gems.level1',
  },
  yellowGem: {
    message: 'Please come back later with $TEMP$ yellow gem.',
    accessor: 'gems.level2',
  },
  blueGem: {
    message: 'Please come back later with $TEMP$ blue gem.',
    accessor: 'gems.level3',
  },
}

export enum USER_DELETED_STATUS {
  USER_NAME = 'deleteduser',
  EMAIL = 'deleteduser@example.com',
}

export enum RoleType {
  SUPER_ADMIN = 'SUPER_ADMIN',
  USER = 'USER',
  CREATOR = 'CREATOR',
}

export enum Quest_contentType {
  QUEST_CONTECTTYPE = '7f9ac7e0-3aff-458d-aadf-6e608409afaf',
}

export const Quest_Name_Mapping = {
  Healthcare: {
    Title: 'Healthcare',
    Value: 'Medical',
  },
  Finance: {
    Title: 'Finance',
    Value: 'Finance',
  },
  Macroeconomics: {
    Title: 'Macro Economics',
    Value: 'MacroEconomics',
  },
  EconomicCrisis: {
    Title: 'Economic Crisis',
    Value: 'Economic Crisis',
  },
  Taxes: {
    Title: 'Taxes',
    Value: 'Tax',
  },
}
export const GITHUB_GENERIC_PHASES_DIRECTORY =
  process.env.NEXT_PUBLIC_GITHUB_GENERIC_PHASES_DIRECTORY

export const ActionPosition = [
  'Bottom',
  'Top',
  'left',
  'Right',
  'Center',
  'BelowDialogue',
  'AboveDialogue',
]

export const ActionOptions = ['MultiSelect', 'YesNo', 'SingleSelect', 'CheckBox']

export const ActionControlledEntity = ['Scene', 'DialogueSession']

export const ContentTypeEntityTypes = [
  { value: 'Quest', label: 'Quest' },
  { value: 'Area', label: 'Area' },
  { value: 'DreamRun', label: 'DreamRun' },
  { value: 'Character', label: 'Character' },
  { value: 'MarketingVideos', label: 'MarketingVideos' },
  { value: 'InteractableCharacter', label: 'InteractableCharacter' },
  { value: 'SurfingSections', label: 'SurfingSections' },
  { value: 'MapLocations', label: 'MapLocations' },
  { value: 'LandLockPuzzles', label: 'LandLockPuzzles' },
  { value: 'Scene', label: 'Scene' },
]

export const MAX_UPLOAD_IMAGE_SIZE = 8000000 // 8MB
export const MAX_UPLOAD_VIDEO_SIZE = 8000000 // 8MB

export enum ActionPositionType {
  CenterTwoColumnsWithNoCharacter = 'CenterTwoColumnsWithNoCharacter',
  CenterTwoColumnsWithCharacter = 'CenterTwoColumnsWithCharacter',
  BottomCornerTwoColumnPersistent = 'BottomCornerTwoColumnPersistent',
  BottomOneRowWithNoCharacter = 'BottomOneRowWithNoCharacter',
  BottomOneRowWithCharacter = 'BottomOneRowWithCharacter',
  YesORNoUnderDialogue = 'YesORNoUnderDialogue',
  YesORNoMiddleDialogue = 'YesORNoMiddleDialogue',
}

export enum PAYMENT_PLANS {
  legendary = 'legendary',
  hero = 'hero',
}

export const USER_SESSION_KEY = 'user-session'
export const GAME_DOWNLOAD_KEY = 'game-download'

export const ASSEMBLY_PASS_TYPE = [{ value: 'BATTLE_CHALLENGE', label: 'DREAM_RUN' }]
export const ASSEMBLY_OUTPUT_TYPE = ['RawText', 'YAML', 'CSV']
export enum ASSEMBLY_OUTPUT {
  PROMPT = 'PROMPT',
  GAME_YAML = 'GAME_YAML',
  INTRO_YAML = 'INTRO_YAML',
  LEVEL_YAML = 'LEVEL_YAML',
  OUTRO_YAML = 'OUTRO_YAML',
  SOLUTION_YAML = 'SOLUTION_YAML',
}
export const AICHAPTER_TYPE = ['AIGENERATED', 'MANUAL']
export const MISSION_TYPE = ['DIALOG', 'QUEST', 'DREAM_RUN', 'SURFING']

export const BATTLE_CHALLENGE_TYPE = ['Manual', 'AIGenerated']

export const DEFAULT_PAGINATION_LIMIT = 500
export const DEFAULT_DROPDOWN_LIMIT = 100

export const AdminSubscriptionPlaceholder = 'admin-subscribed'

export enum TemplateTypes {
  YES_NO_SKIP = 'YES_NO_SKIP',
  MULTISELECT = 'MULTISELECT',
}

export enum TriggerAction {
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  SOFTREMOVE = 'SOFTREMOVE',
}

export const NotAvailable = 'N/A'
