import { earth9Config } from './earth9'
import { heroOnEarthConfig } from './heroonearth'
import { riggedEconomyConfig } from './riggedEconomy'
import { medicalPriceDepartmentConfig } from './riggedMedical'
import { workerDefenseDepartmentConfig } from './workerdefensedepartment'
import { endtheRigConfig } from './endtherig'
import { tricorderConfig } from './tricorder'
import { congressMemberConfig } from './congressMember'

// TODO: WE NEED TO GRADUALLY REMOVE THE USE OF THIS FUNCTION
export const IsHeroOnEarth = () => process.env.NEXT_PUBLIC_PROJECT === 'HoE'

export enum Project {
  HERO_ON_EARTH = 'heroonearth',
  END_THE_RIG = 'endtherig',
  TRICORDER = 'tricorder',
  TESTING_TRICORDER = 'testingtricorder',
  EARTH_9 = 'earth9',
  WORKERS_DEFENSE_DEPARTMENT = 'workersdefensedepartment',
  RIGGED_HQ = 'riggedhq',
  MEDICAL_PRICES_HQ = 'medicalpriceshq',
  CONGRESS_MEMBERS = 'congressMemberConfig',
}

// All the keys for this object should be our projects main domain
const PROJECT_MAP = {
  heroonearth: heroOnEarthConfig,
  endtherig: endtheRigConfig,
  tricorder: tricorderConfig,
  earth9: earth9Config,
  workersdefensedepartment: workerDefenseDepartmentConfig,
  riggedhq: riggedEconomyConfig,
  medicalpriceshq: medicalPriceDepartmentConfig,
  congresshq: congressMemberConfig,
}

// Development Note: The value of NEXT_PUBLIC_DEV_CURRENT_DOMAIN is used only for local development purposes.
// It represents the main domain of the project being developed, ensuring that logos, colors, and other project
// configurations are loaded correctly in the local environment. For instance, for https://heronearth.com, the value
// would be 'heronearth', and for https://workersdefensedepartment.com, the value would be 'workersdefensedepartment'.

// This function determines the current domain based on the environment and browser context.
// If running in a browser window, it extracts the main domain from the window's location.
// If the main domain is 'localhost', it returns a development domain from environment variables.
// Otherwise, it returns the extracted main domain.
// If not running in a browser context, it returns 'default'.

// extract main and subdomain from hostname
export const extractDomains = (hostname) => {
  // Remove 'https://' from the beginning of the hostname
  hostname = hostname.replace('https://', '')

  const parts = hostname.split('.') // Split the hostname into parts using dot as the separator
  const subdomain = parts.length >= 3 ? parts[0] : '' // If there are at least three parts, extract the first part as the subdomain
  const mainDomain = parts.length >= 3 ? parts[1] : parts[0] // If there are at least three parts, extract the second part as the main domain
  return { subdomain, mainDomain }
}

export const getCurrentDomain = (): string => {
  if (process.env.NEXT_PUBLIC_MODE === 'development') {
    return process.env.NEXT_PUBLIC_DEV_CURRENT_DOMAIN
  }
  if (typeof window !== 'undefined') {
    const { subdomain, mainDomain } = extractDomains(window.location.hostname)
    // Check for specific conditions
    if (
      mainDomain === Project.END_THE_RIG &&
      (subdomain === Project.TRICORDER || subdomain === Project.TESTING_TRICORDER)
    ) {
      return Project.TRICORDER
    }
    return mainDomain
  }
  return Project.END_THE_RIG
}

// eslint-disable-next-line import/no-mutable-exports
export let PROJECT = PROJECT_MAP[getCurrentDomain()]

export const updateProject = () => {
  PROJECT = PROJECT_MAP[getCurrentDomain()]
}

export const APP_LINKS = [
  {
    src: 'https://aitaskassembly.s3.us-east-2.amazonaws.com/static_images/icons/ai-taskassembly.png',
    alt: 'AI TaskAssembly',
    url: process.env.NEXT_PUBLIC_AI_TASK_ASSEMBLY_URL,
  },
  {
    src: 'https://aitaskassembly.s3.us-east-2.amazonaws.com/static_images/icons/CongressHQ.png',
    alt: 'Congress HQ',
    url: process.env.NEXT_PUBLIC_CONGRESS_HQ_URL,
  },
  {
    src: 'https://aitaskassembly.s3.us-east-2.amazonaws.com/static_images/icons/etr.png',
    alt: 'End The Rig',
    url: process.env.NEXT_PUBLIC_END_THE_RIG_URL,
  },
  {
    src: 'https://aitaskassembly.s3.us-east-2.amazonaws.com/static_images/icons/LibelDefender.png',
    alt: 'Libel Defender',
    url: process.env.NEXT_PUBLIC_LIBEL_DEFENDER_URL,
  },
  {
    src: 'https://aitaskassembly.s3.us-east-2.amazonaws.com/static_images/icons/MedicalHQ.png',
    alt: 'Medical HQ',
    url: process.env.NEXT_PUBLIC_AI_AND_BRAIN_URL,
  },
  {
    src: 'https://aitaskassembly.s3.us-east-2.amazonaws.com/static_images/icons/RiggedHQ.png',
    alt: 'Rigged HQ',
    url: process.env.NEXT_PUBLIC_RIGGED_HQ_URL,
  },
  {
    src: 'https://aitaskassembly.s3.us-east-2.amazonaws.com/static_images/icons/showrunner.png',
    alt: 'Show Runner Hero',
    url: process.env.NEXT_PUBLIC_SHOW_RUNNER_HERO_URL,
  },
].filter((item) => !item.url?.includes(getCurrentDomain()))
