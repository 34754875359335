import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

export const BoxMainNetworkStatusWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  userSelect: 'none',
}))

export const TypographyStatusMessage = styled(Typography)(() => ({
  fontWeight: 600,
}))
