import React, { ErrorInfo } from 'react'
import { Box } from '@mui/material'
import * as Sentry from '@sentry/browser'
import { useRouter } from 'next/router'

import ServerIcon from '@asset/game/server.svg'
import {
  DisplayIcon,
  IconWrapper,
  Title,
  Container,
  ActionButton,
  Description,
} from './index.style'

type ErrorBoundaryProps = {
  children: React.ReactNode
  title?: string
  description?: string
  displayIcon?: string
  showRetryActionButton?: boolean
  showGoToHomeActionButton?: boolean
}

type ErrorBoundaryState = {
  hasError: boolean
}

const ENABLE_SENTRY_LOG = process.env.NEXT_PUBLIC_SENTRY_LOG === 'YES'

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (ENABLE_SENTRY_LOG) {
      Sentry.captureException({
        error,
        errorInfo,
      })
    }
  }

  render() {
    const {
      children,
      title,
      description,
      displayIcon,
      showRetryActionButton,
      showGoToHomeActionButton,
    } = this.props

    const { hasError } = this.state
    if (hasError) {
      return (
        <ErrorBoundaryUI
          title={title}
          description={description}
          displayIcon={displayIcon}
          showRetryActionButton={showRetryActionButton}
          showGoToHomeActionButton={showGoToHomeActionButton}
          purgeError={() => {
            this.setState({ hasError: false })
          }}
        />
      )
    }

    return children
  }
}

type ErrorBoundaryUIProps = Omit<ErrorBoundaryProps, 'children'> & {
  purgeError?: () => void
}

export function ErrorBoundaryUI({
  title = 'Oops! Something went wrong...',
  description = 'Oops! something went wrong. Try to refresh this page or feel free to contact us if the problem persists.',
  displayIcon = ServerIcon?.src,
  showRetryActionButton = true,
  showGoToHomeActionButton = true,
  purgeError,
}: ErrorBoundaryUIProps) {
  const { pathname, push } = useRouter()

  const exceptionPath = React.useRef(pathname)

  React.useEffect(() => {
    if (exceptionPath.current !== pathname) {
      purgeError()
    }
  }, [pathname, purgeError])

  return (
    <Container>
      <IconWrapper>
        <DisplayIcon src={displayIcon} alt="error" />
      </IconWrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
          margin: '2rem 0',
        }}
      >
        {showRetryActionButton ? (
          <ActionButton variant="outlined" type="button" onClick={() => window.location.reload()}>
            Retry
          </ActionButton>
        ) : null}
        {showGoToHomeActionButton ? (
          <ActionButton
            variant="contained"
            type="button"
            onClick={() => {
              push('/')
            }}
          >
            Go to home
          </ActionButton>
        ) : null}
      </Box>
    </Container>
  )
}

export default ErrorBoundary
